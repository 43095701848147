import { StyleSheet, Dimensions } from "react-native";

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width <= 1440;
const isLargeDesktop = width > 1440;

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const styles = StyleSheet.create({
	body: {
		flex: 1,
		overflow: "hidden",
		maxHeight: "100vh",
		touchAction: "none", // Disable scrolling
	},
	section: {
		flex: 1, // Each section takes up an equal portion of the screen's height
		minHeight: "100vh",
		zIndex: 1,
		overflow: "hidden", // Ensure that this is not scrollable beyond the view height
	},
	headerBar: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-between",
		backgroundColor: "transparent",
		paddingHorizontal: isMobile ? 0 : isTablet ? 50 : isSmallDesktop ? 50 : 70,
		paddingTop: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 25 : 20,
		zIndex: 1000
	},
	logoArea: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-between",
		backgroundColor: "transparent",
		maxHeight: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
	},
	loginArea: {
		marginRight: isMobile ? 225 : isTablet ? 250 : isSmallDesktop ? 50 : 0,
		flex: 0.5,
		flexDirection: "row",
		backgroundColor: "transparent",
		maxHeight: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
	},
	registerButton: {
		width: isMobile ? 125 : isTablet ? 200 : isSmallDesktop ? 250 : 300,
		height: isMobile ? 40 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor: "#4c956c",
		shadowOffset: { width: 7, height: 3 },
		shadowRadius: 6,
		shadowColor: "rgba(0, 0, 0, 0.04)",
		alignItems: "center",
		justifyContent: "center",
		marginRight: isMobile ? 10 : 0,
		padding: isMobile ? 10 : 0,
		transition: "transform 0.4s ease-in-out", // Add transition for smooth effect
	},
	registerButtonHover: {
		transform: [{ scale: 1.025 }], // Scale up on hover
	},
	registerButtonText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "bold",
		fontSize:  isMobile ? 20 : isTablet ? 20 : isSmallDesktop ? 25 : 25,
		lineHeight: isMobile ? 20 : isTablet ? 25 : isSmallDesktop ? 30 : 35,
		textAlign: "center",
		color: "#e4e4e4",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	loginButton: {
		width: isMobile ? 125 : isTablet ? 200 : isSmallDesktop ? 250 : 300,
		height: isMobile ? 40 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor:  isMobile ? "#4c956c" : "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
		transition: "transform 0.4s ease-in-out",
	},
	loginButtonHover: {
		transform: [{ scale: 1.025 }], 
	},
	loginButtonText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "bold",
		fontSize:  isMobile ? 20 : isTablet ? 20 : isSmallDesktop ? 25 : 25,
		lineHeight: 30,
		textAlign: "center",
		color: "#e4e4e4",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor:  "rgba(0, 0, 0, 0.09)",
	},

	logoText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize:  isMobile ? 0 : isTablet ? 0 : isSmallDesktop ? 50 : 50,
		textAlign: "left",
		color: "#fefde2",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	contentArea: {
		flex: 1,
		flexDirection: "column",
		justifyContent: "center",
		backgroundColor: "transparent",
		marginTop: isMobile ? -700 : isTablet ? -400 : isSmallDesktop ? -400 : -400,
		padding: isMobile ? 20 : isTablet ? 30 : isSmallDesktop ? 50 : 70,
	},
	content: {
		flex: 1,
		flexDirection: "column",
		justifyContent: "space-between",
		backgroundColor: "transparent",
		padding: isMobile ? 0 : isTablet ? 30 : isSmallDesktop ? 50 : 70,
	},
	footer: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-between",
		backgroundColor: "transparent",
		padding: isMobile ? 0 : isTablet ? 30 : isSmallDesktop ? 50 : 70,
	},
	footerText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "bold",
		fontSize:  isMobile ? 8 : isTablet ? 16 : isSmallDesktop ? 25 : 25,
		lineHeight: isMobile ? 20 : isTablet ? 25 : isSmallDesktop ? 30 : 35,
		textAlign: "center",
		color: "#e4e4e4",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	footerButton: {
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		height: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	footerButton2: {
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		height: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	footerButton3: {
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		height: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	footerButton4: {
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		height: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	footerButton5: {
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		height: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	footerButton6: {
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		height: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	footerButton7: {
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		height: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
		cursor: 'pointer',
	},
	footerButton8: {
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		height: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	footerButton9: {
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		height: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	footerButton10: {
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		height: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	footerButton11: {
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		height: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	footerButton12: {
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		height: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	footerButton13: {
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		height: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	footerButton14: {
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		height: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	footerButton15: {
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		height: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	footerButton16: {
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		height: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	footerButton17: {
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		height: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	footerButton18: {
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		height: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	footerButton19: {
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		height: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	footerButton20: {
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		height: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	footerButton21: {
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		height: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	footerButton22: {
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		height: isMobile ? 30 : isTablet ? 40 : isSmallDesktop ? 50 : 60,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		maxHeight: 500,
		marginHorizontal: isMobile ? 20 : isTablet ? 50 : isSmallDesktop ? 90 : 140,
		zIndex: 3,
	},

	headerText: {
		width: isMobile ? 350 : isTablet ? 900 : isSmallDesktop ? 1000 : 1000,
		height: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 225 : 225,
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize: isMobile ? 65 : isTablet ? 70 : isSmallDesktop ? 90 : 100,
		textAlign: "left",
		color: "#fefde2",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 5,
		textShadowColor: "rgba(0, 0, 0, 0.06)",
		zIndex: 99,
		marginBottom: isMobile ? 75 : 0
	},

	descriptionText: {
		width: isMobile ? 300 : isTablet ? 500 : isSmallDesktop ? 900 : 900,
		height: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 250 : 250,
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: isMobile ? 35 : isTablet ? 40 : isSmallDesktop ? 50 : 55,
		lineHeight: isMobile ? 35 : isTablet ? 40 : isSmallDesktop ? 55 : 60,
		textAlign: "left",
		color: "#fefde2",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		marginVertical: isMobile ? 75 : isTablet ? 40 : isSmallDesktop ? 20 : 50,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
		zIndex: 99,
		paddingBottom: isMobile ? 125 : 0
	},

		button: {
		width: isMobile ? 200 : isTablet ? 300 : isSmallDesktop ? 550 : 550,
		height: isMobile ? 30 : isTablet ? 50 : isSmallDesktop ? 60 : 65,
		backgroundColor: "#4c956c",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 50,
		marginTop: isMobile ? -50 : isTablet ? 15 : isSmallDesktop ? -75 : -50,
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
		zIndex: 99,
	},
	getStartedButton: {
		width: isMobile ? 200 : isTablet ? 300 : isSmallDesktop ? 550 : 550,
		height: isMobile ? 40 : isTablet ? 50 : isSmallDesktop ? 60 : 65,
		backgroundColor: "#4c956c",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 50,
		marginTop: isMobile ? 50 : isTablet ? 15 : isSmallDesktop ? -75 : -50,
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
		zIndex: 99,
		transition: "transform 0.4s ease-in-out", // Add transition for smooth effect
	},
	getStartedButtonHover: {
		transform: [{ scale: 1.025 }], // Scale up on hover
	},
	buttonText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: isMobile ? 22 : isTablet ? 26 : isSmallDesktop ? 32 : 32,
		lineHeight: 30,
		textAlign: "center",
		color: "#fefde2",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
		zIndex: 99,
	},

	backgroundImage: {
		flex: 1,
		resizeMode: "cover",
	},
	blurred: {
		position: "absolute",
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		backdropFilter: "blur(10px)", // Apply blur effect
	},
	blurredContainer: {
		// Apply a blur effect
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backdropFilter: "blur(10px)", // Apply blur effect
	},
	popupContainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		position: "absolute",
		zIndex: 3,
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		top: isMobile ? 50 : isTablet ? 100 : isSmallDesktop ? 50 : 100, // Adjusted for smaller screens
		borderRadius: 50
	},
	popup: {
		borderRadius: 50,
		zIndex: 9999
	},
	overlayBlur: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backdropFilter: "blur(10px)", 
		zIndex: 50
	},
	uploadButton: {
		padding: 10,
		backgroundColor: "#FEFCE0",
		borderRadius: 5,
		marginTop: isMobile ? 10 : isTablet ? 20 : isSmallDesktop ? 30 : 40, // Adjust as needed
	},
	ribbonContainer: {
		position: 'absolute',
		bottom: isMobile ? 10 : isTablet ? 20 : isSmallDesktop ? 30 : 40,
		right: isMobile ? 10 : isTablet ? 20 : isSmallDesktop ? 30 : 40,
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 9999,
		backgroundColor: "#4c956c",
		padding: 10,
		borderRadius: 10,
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		alignItems: "center",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 4,
		},
		shadowOpacity: 0.5,
		shadowRadius: 5,
		elevation: 8,
	},
	ribbon1: {
		position: 'absolute',
		bottom: '-100%',
		transition: 'bottom 0.5s ease-out',
		backgroundColor: '#4c956c',
		padding: 10,
		borderRadius: 10,
		zIndex: 1001,
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250,
		alignItems: "center",
		// Add shadow
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 4,
		},
		shadowOpacity: 0.5,
		shadowRadius: 5,
		elevation: 8,
	},
	ribbon2: {
		position: 'absolute',
		bottom: isMobile ? '-200%' : isTablet ? '-250%' : isSmallDesktop ? '-300%' : '-350%', // Initially hidden
		transition: 'bottom 0.5s ease-out',
		backgroundColor: '#4c956c', // Yellow color for the ribbon
		padding: 10,
		borderRadius: 10,
		zIndex: 100, // Ensure it's above other elements
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250, // Adjusted for smaller screens
		alignItems: "center",
	},
	ribbon3: {
		position: 'absolute',
		bottom: isMobile ? '-300%' : isTablet ? '-350%' : isSmallDesktop ? '-400%' : '-450%', // Initially hidden
		transition: 'bottom 0.5s ease-out',
		backgroundColor: '#4c956c', // Yellow color for the ribbon
		padding: 10,
		borderRadius: 10,
		zIndex: 100, // Ensure it's above other elements
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250, // Adjusted for smaller screens
		alignItems: "center",
	},
	ribbon4: {
		position: 'absolute',
		bottom: isMobile ? '-400%' : isTablet ? '-450%' : isSmallDesktop ? '-500%' : '-550%', // Initially hidden
		transition: 'bottom 0.5s ease-out',
		backgroundColor: '#4c956c', // Yellow color for the ribbon
		padding: 10,
		borderRadius: 10,
		zIndex: 100, // Ensure it's above other elements
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250, // Adjusted for smaller screens
		alignItems: "center",
	},
	ribbon5: {
		position: 'absolute',
		bottom: isMobile ? '-500%' : isTablet ? '-550%' : isSmallDesktop ? '-600%' : '-650%', // Initially hidden
		transition: 'bottom 0.5s ease-out',
		backgroundColor: '#4c956c', // Yellow color for the ribbon
		padding: 10,
		borderRadius: 10,
		zIndex: 100, // Ensure it's above other elements
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250, // Adjusted for smaller screens
		alignItems: "center",
	},
	ribbon6: {
		position: 'absolute',
		bottom: isMobile ? '-600%' : isTablet ? '-650%' : isSmallDesktop ? '-700%' : '-750%', // Initially hidden
		transition: 'bottom 0.5s ease-out',
		backgroundColor: '#4c956c', // Yellow color for the ribbon
		padding: 10,
		borderRadius: 10,
		zIndex: 100, // Ensure it's above other elements
		width: isMobile ? 100 : isTablet ? 150 : isSmallDesktop ? 200 : 250, // Adjusted for smaller screens
		alignItems: "center",
	},
	ribbonText: {
		color: '#FFFFFF', // White text
		fontWeight: 'bold',
		textAlign: 'center'
	},
	privateMessageContainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		position: "absolute",
		zIndex: 3,
		top: 0, // These changes ensure the popup
		bottom: 0, // covers the whole screen
		left: 0, // and is centered.
		right: 0,
		backgroundColor: "#4c956c",
	},
});

export default styles;