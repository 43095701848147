import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ImageBackground, TextInput } from 'react-native';

const fontFamilies = {
    Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const Countdown = ({ targetDate, onCompleteMessage, backgroundImage, applyBlur, title, onTitleChange, onDateChange, onMessageChange }) => {
    const [timeLeft, setTimeLeft] = useState(null);
    const [editableTitle, setEditableTitle] = useState(title || "Countdown");
    const [editableDate, setEditableDate] = useState(targetDate);
    const [editableMessage, setEditableMessage] = useState(onCompleteMessage || "Event has started!");

    useEffect(() => {
        setEditableTitle(title || "Countdown");
        setEditableDate(targetDate);
        setEditableMessage(onCompleteMessage || "Event has started!");
    }, [title, targetDate, onCompleteMessage]);

    useEffect(() => {
        const calculateTimeLeft = () => {
            const targetTime = new Date(targetDate).getTime();
            const now = new Date().getTime();

            // Check if target date is valid and in the future
            if (isNaN(targetTime) || targetTime <= now) {
                setTimeLeft(null);
                return;
            }

            const difference = targetTime - now;

            if (difference > 0) {
                setTimeLeft({
                    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                    minutes: Math.floor((difference / 1000 / 60) % 60),
                    seconds: Math.floor((difference / 1000) % 60)
                });
            } else {
                setTimeLeft(null);
            }
        };

        calculateTimeLeft();
        const timer = setInterval(calculateTimeLeft, 1000);

        return () => clearInterval(timer);
    }, [targetDate]);

    const handleTitleChange = (newTitle) => {
        setEditableTitle(newTitle);
        if (onTitleChange) onTitleChange(newTitle);
    };

    const handleDateChange = (newDate) => {
        setEditableDate(newDate);
        if (onDateChange) onDateChange(newDate);
    };

    const handleMessageChange = (newMessage) => {
        setEditableMessage(newMessage);
        if (onMessageChange) onMessageChange(newMessage);
    };

    return (
        <View style={styles.container}>
            <ImageBackground
                source={backgroundImage || require("../../assets/Hub Background.png")}
                style={styles.backgroundImage}
                blurRadius={applyBlur ? 10 : 0}
            >
                <View style={styles.contentContainer}>
                    <TextInput
                        style={styles.title}
                        value={editableTitle}
                        onChangeText={handleTitleChange}
                        placeholder="Enter countdown title"
                        placeholderTextColor="#fefde2"
                        disabled={true}
                    />

                    {/* <TextInput
                        style={styles.messageInput}
                        value={editableMessage}
                        onChangeText={handleMessageChange}
                        placeholder="Enter completion message"
                        placeholderTextColor="#fefde2"
                    /> */}
                    {timeLeft ? (
                        <View style={styles.timeContainer}>
                            <View style={styles.timeBlock}>
                                <Text style={styles.number}>{timeLeft.days}</Text>
                                <Text style={styles.label}>Days</Text>
                            </View>
                            <View style={styles.timeBlock}>
                                <Text style={styles.number}>{timeLeft.hours}</Text>
                                <Text style={styles.label}>Hours</Text>
                            </View>
                            <View style={styles.timeBlock}>
                                <Text style={styles.number}>{timeLeft.minutes}</Text>
                                <Text style={styles.label}>Minutes</Text>
                            </View>
                            <View style={styles.timeBlock}>
                                <Text style={styles.number}>{timeLeft.seconds}</Text>
                                <Text style={styles.label}>Seconds</Text>
                            </View>
                        </View>
                    ) : (
                        <Text style={styles.completeMessage}>{editableMessage}</Text>
                    )}
                </View>
            </ImageBackground>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: '100%',
        minHeight: 200,
        borderRadius: 15,
        overflow: 'hidden',
        marginVertical: 20,
    },
    backgroundImage: {
        width: '100%',
        height: '100%',
    },
    contentContainer: {
        padding: 20,
        backgroundColor: 'rgba(76, 149, 108, 0.85)', // Green with opacity
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontFamily: fontFamilies.Bodoni72Smallcaps,
        fontSize: 32,
        color: '#fefde2',
        marginBottom: 20,
        textAlign: 'center',
        minWidth: 200,
        padding: 10,
        textShadowOffset: { width: 2, height: 2 },
        textShadowRadius: 4,
        textShadowColor: 'rgba(0, 0, 0, 0.3)',
    },
    timeContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 20,
    },
    timeBlock: {
        backgroundColor: 'rgba(254, 253, 226, 0.9)', // Light cream color
        borderRadius: 10,
        padding: 15,
        minWidth: 100,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    number: {
        fontFamily: fontFamilies.Bodoni72Smallcaps,
        fontSize: 36,
        color: '#4c956c',
        fontWeight: 'bold',
    },
    label: {
        fontFamily: fontFamilies.Bodoni72Smallcaps,
        fontSize: 16,
        color: '#4c956c',
        marginTop: 5,
    },
    completeMessage: {
        fontFamily: fontFamilies.Bodoni72Smallcaps,
        fontSize: 24,
        color: '#fefde2',
        textAlign: 'center',
        textShadowOffset: { width: 2, height: 2 },
        textShadowRadius: 4,
        textShadowColor: 'rgba(0, 0, 0, 0.3)',
    },
    dateInput: {
        fontFamily: fontFamilies.Bodoni72Smallcaps,
        fontSize: 18,
        color: '#fefde2',
        marginBottom: 10,
        textAlign: 'center',
        minWidth: 200,
        padding: 10,
        textShadowOffset: { width: 1, height: 1 },
        textShadowRadius: 2,
        textShadowColor: 'rgba(0, 0, 0, 0.3)',
    },
    messageInput: {
        fontFamily: fontFamilies.Bodoni72Smallcaps,
        fontSize: 18,
        color: '#fefde2',
        marginBottom: 20,
        textAlign: 'center',
        minWidth: 200,
        padding: 10,
        textShadowOffset: { width: 1, height: 1 },
        textShadowRadius: 2,
        textShadowColor: 'rgba(0, 0, 0, 0.3)',
    },
});

export default Countdown;
