import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, Pressable, Picker, TextInput, Image, Linking, Dimensions, Platform } from "react-native";
import { uploadBytes, getDownloadURL, ref, getStorage } from "firebase/storage";
import { getFirestore, doc, getDoc, setDoc, updateDoc, query, where, getDocs, collection } from "firebase/firestore";
import ImageGallery from "../Screens/EventsWidgets/ImageGallery"; // Adjust the path as necessary
import Countdown from "../Screens/EventsWidgets/Countdown";
import DateTimePicker from '@react-native-community/datetimepicker'; // Import a date-time picker library
import { REACT_APP_AWS_SERVER_URL } from '@env';	
import PromptSignUp from './PromptSignUp'; // Import the PromptSignUp component
const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width < 1440;
const isLargeDesktop = width >= 1440;

let LOCAL_SERVER_URL = "http://localhost:3300";
let AWS_SERVER_URL = REACT_APP_AWS_SERVER_URL;

const isDevelopment = process.env.NODE_ENV === "development"; // Adjust this condition based on your setup
const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;


// Add this function at the top of your file
const fetchMetadata = async (url) => {
  const formattedUrl = url.startsWith("http") ? url : `https://${url}`;
  try {
    const response = await fetch(`${SERVER_URL}/fetch-metadata`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ url: formattedUrl }),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch metadata: ${response.statusText}`);
    }

    const metadata = await response.json();
    const firstImage = metadata.image || null;
    return { ...metadata, image: firstImage };
  } catch (error) {
    console.error("Error in fetch-metadata:", error);
    throw error;
  }
};

const fontFamilies = {
  Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const EventCard = ({ onToggleCatalogue, card, onRemove, onSave, onAdd, isOwner, isLastCard, isVisitorView, widgetIndex, renderFetchMetadataButton, currentUserData, pageOwner, setCards }) => {
  const [text, setText] = useState(card.content || "");
  const [images, setImages] = useState(card.images || []);
  const [url, setUrl] = useState(card.url || "");
  const [customImageUrl, setCustomImageUrl] = useState(card.customImageUrl || "");
  const [hasChanges, setHasChanges] = useState(false);
  const [selectedWidgetType, setSelectedWidgetType] = useState(card.type || ""); // Default to empty string
  const [rsvp, setRSVP] = useState("");
  const [isLoading, setIsLoading] = useState(false); // New loading state
  const [loadingMessage, setLoadingMessage] = useState(""); // State for loading message
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  const [textInputHeight, setTextInputHeight] = useState(0); // New state for dynamic height
  const [targetDate, setTargetDate] = useState(new Date(card.targetDate || Date.now()));
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [onCompleteMessage, setOnCompleteMessage] = useState(card.onCompleteMessage || "The event has started!");
  const [showSignUpPrompt, setShowSignUpPrompt] = useState(false);

  const handleTextChange = (newText) => {
    setText(newText);
    setHasChanges(true);
  };

  const handleWidgetTypeChange = (type) => {
    setSelectedWidgetType(type);
    setHasChanges(true);
  };

  const handleUrlChange = (newUrl) => {
    setUrl(newUrl);
    setHasChanges(true);
  };

  const handleFetchMetadata = async () => {
    setIsLoading(true);
    setLoadingMessage("Fetching metadata...");
    try {
      const metadata = await fetchMetadata(url);
      card.metadata = metadata;
      setCustomImageUrl(metadata.image || "https://via.placeholder.com/600");
      setHasChanges(true);
      setLoadingMessage("");
      setSuccessMessage("Metadata fetched successfully!");
      alert("URL retrieved successfully!");
      setTimeout(() => setSuccessMessage(""), 2000);
    } catch (error) {
      console.error("Failed to fetch metadata:", error);
      setLoadingMessage("");
      setErrorMessage("Failed to fetch metadata. Please try again.");
      alert("Failed to retrieve URL. Please try again.");
      setTimeout(() => setErrorMessage(""), 2000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setIsLoading(true);
      setLoadingMessage("Uploading image...");
      const storagePath = `widgetsImages/${file.name}-${Date.now()}`;
      const storageRef = ref(getStorage(), storagePath);

      try {
        const uploadResult = await uploadBytes(storageRef, file);
        const publicUrl = await getDownloadURL(uploadResult.ref);

        setCustomImageUrl(publicUrl); // Display the selected cover photo immediately
        setImages((currentImages) => [...currentImages, publicUrl]);
        setHasChanges(true); // Mark that there are unsaved changes
        setLoadingMessage(""); // Clear loading message
        setSuccessMessage("Image uploaded successfully!");
        setTimeout(() => setSuccessMessage(""), 2000); // Clear success message after 2 seconds
      } catch (error) {
        console.error("Failed to upload image:", error);
        setLoadingMessage(""); // Clear loading message
        setErrorMessage("Failed to upload image. Please try again.");
        setTimeout(() => setErrorMessage(""), 2000); // Clear error message after 2 seconds
      } finally {
        setIsLoading(false);
      }
    } else {
      console.error("No file selected or file is not an image");
    }
  };

  const handleRemoveImage = (index) => {
    setImages((currentImages) => {
      const updatedImages = [...currentImages];
      updatedImages.splice(index, 1);
      return updatedImages;
    });
    setHasChanges(true);
  };

  const handleRSVP = async (selection) => {
    if (!currentUserData) {
      setShowSignUpPrompt(true);
      return;
    }

    const userEmail = currentUserData?.email;
    const db = getFirestore();
    const cardRef = doc(db, "users", pageOwner.email);

    // Get the current card data
    const cardSnap = await getDoc(cardRef);
    if (!cardSnap.exists()) return;

    const cardData = cardSnap.data();
    const widgets = cardData.pages?.eventsPageConfig?.cards || [];
    const currentWidget = widgets[widgetIndex];

    // Remove user from all RSVP lists
    const updatedGoing = currentWidget.going?.filter(email => email !== userEmail) || [];
    const updatedMaybe = currentWidget.maybe?.filter(email => email !== userEmail) || [];
    const updatedDecline = currentWidget.decline?.filter(email => email !== userEmail) || [];

    // Add user to the selected RSVP list
    if (selection === "Going") updatedGoing.push(userEmail);
    if (selection === "Maybe") updatedMaybe.push(userEmail);
    if (selection === "Decline") updatedDecline.push(userEmail);

    // Update the current widget
    widgets[widgetIndex] = {
      ...currentWidget,
      going: updatedGoing,
      maybe: updatedMaybe,
      decline: updatedDecline,
      rsvp: selection,
    };

    // Update Firestore document
    await updateDoc(cardRef, {
      "pages.eventsPageConfig.cards": widgets,
    });

    // Update local state
    setRSVP(selection);
    card.going = updatedGoing;
    card.maybe = updatedMaybe;
    card.decline = updatedDecline;
    setHasChanges(true);
  };

  useEffect(() => {
    const userEmail = currentUserData?.email;
    if (card.going?.includes(userEmail)) {
      setRSVP("Going");
    } else if (card.maybe?.includes(userEmail)) {
      setRSVP("Maybe");
    } else if (card.decline?.includes(userEmail)) {
      setRSVP("Decline");
    }
  }, [card, currentUserData, rsvp]);

  const handleDateChange = (event, selectedDate) => {
    const currentDate = selectedDate || targetDate;
    setTargetDate(currentDate);
    setHasChanges(true);
  };

  const handleCompleteMessageChange = (text) => {
    setOnCompleteMessage(text);
    setHasChanges(true);
  };

  const saveCard = async () => {
    const db = getFirestore();
    const cardRef = doc(db, "users", pageOwner.email);

    try {
      const cardSnap = await getDoc(cardRef);
      if (!cardSnap.exists()) return;

      const cardData = cardSnap.data();
      const widgets = cardData.pages?.eventsPageConfig?.cards || [];
      widgets[widgetIndex] = {
        ...card,
        type: selectedWidgetType, // Ensure the widget type is saved
        content: text, // Save the text content
        images,
        url,
        customImageUrl,
        rsvp,
        targetDate: targetDate.toISOString(), // Save the target date
        onCompleteMessage, // Save the completion message
      };

      await updateDoc(cardRef, {
        "pages.eventsPageConfig.cards": widgets,
      });

      setHasChanges(false); // Reset the changes flag after saving
      setSuccessMessage("Changes saved successfully!");
      setTimeout(() => setSuccessMessage(""), 2000); // Clear success message after 2 seconds
    } catch (error) {
      console.error("Failed to save changes:", error);
      setErrorMessage("Failed to save changes. Please try again.");
      setTimeout(() => setErrorMessage(""), 2000); // Clear error message after 2 seconds
    }
  };

  const handleContentSizeChange = (contentWidth, contentHeight) => {
    setTextInputHeight(contentHeight);
  };

  const renderWidget = () => {
    const effectiveIsOwner = isOwner && !isVisitorView; // Adjust ownership based on viewing mode
    switch (selectedWidgetType) {
      case "TextBox":
        return (
          <TextInput
            value={text}
            onChangeText={effectiveIsOwner ? handleTextChange : null}
            style={[
              effectiveIsOwner ? styles.textInputOwner : styles.textInputViewer,
              styles.widgetShadow,
              { height: Math.max(35, textInputHeight) }
            ]}
            multiline
            editable={effectiveIsOwner}
            placeholder="Type your message here..."
            placeholderTextColor="#ccc"
            onContentSizeChange={(e) => handleContentSizeChange(e.nativeEvent.contentSize.width, e.nativeEvent.contentSize.height)}
          />
        );
      case "ImageGallery":
        return (
          <View style={styles.widgetShadow}>
            <ImageGallery
              isEditMode={effectiveIsOwner}
              initialImages={images}
              onImagesUpdated={(updatedImages) => {
                setImages(updatedImages);
                setHasChanges(true);
              }}
            />
            {effectiveIsOwner && (
              <>
                <input
                  id={`fileInput-${widgetIndex}`}
                  type="file"
                  onChange={handleImageUpload}
                  style={styles.fileInput}
                />
                {loadingMessage && <Text>{loadingMessage}</Text>}
                {successMessage && <Text>{successMessage}</Text>}
                {errorMessage && <Text>{errorMessage}</Text>}
              </>
            )}
          </View>
        );
      case "PictureFrame":
        return (
          <View style={[styles.pictureFrameContainer]}>
            {effectiveIsOwner && images.length < 4 && (
              <Pressable style={styles.fileInputButton} onPress={() => document.getElementById(`fileInput-${widgetIndex}`).click()}>
                <Text style={styles.fileInputButtonText}>Add Photos</Text>
              </Pressable>
            )}
            <input
              id={`fileInput-${widgetIndex}`}
              type="file"
              onChange={handleImageUpload}
              style={styles.fileInput}
            />
            <View style={styles.pictureFrameImages}>
              {images.slice(0, 4).map((img, index) => (
                <View key={index} style={styles.polaroid(index)}>
                  <Image source={{ uri: img }} style={[styles.tiltedImage, styles.widgetShadow]} />
                  {effectiveIsOwner && (
                    <Pressable onPress={() => handleRemoveImage(index)} style={styles.removeImageButton}>
                      <Text style={styles.removeButtonText}>Remove Image</Text>
                    </Pressable>
                  )}
                </View>
              ))}
            </View>
          </View>
        );
      case "RSVP":
        return (
          <View style={styles.rsvpContainer}>
            <View style={styles.rsvpButtonContainer}>
              <Pressable onPress={() => handleRSVP("Going")} style={[rsvp === "Going" ? styles.selectedButtonGreen : styles.RSVPbutton, styles.widgetShadow]}>
                <Text style={styles.RSVPButtonText}>Going</Text>
                <Text style={styles.rsvpCount}>{card.going?.length || 0}</Text>
              </Pressable>
              <Pressable onPress={() => handleRSVP("Maybe")} style={[rsvp === "Maybe" ? styles.selectedButtonBeige : styles.RSVPbutton, styles.widgetShadow]}>
                <Text style={styles.RSVPButtonText}>Maybe</Text>
                <Text style={styles.rsvpCount}>{card.maybe?.length || 0}</Text>
              </Pressable>
              <Pressable onPress={() => handleRSVP("Decline")} style={[rsvp === "Decline" ? styles.selectedButtonRed : styles.RSVPbutton, styles.widgetShadow]}>
                <Text style={styles.RSVPButtonText}>Not Going</Text>
                <Text style={styles.rsvpCount}>{card.decline?.length || 0}</Text>
              </Pressable>
            </View>
          </View>
        );
      case "URLPreview":
        if (!card.metadata && !effectiveIsOwner) {
          return null;
        }
        return (
          <View>
            {card.metadata ? (
              <Pressable onPress={() => Linking.openURL(card.url.startsWith("http") ? card.url : `https://${card.url}`)} style={styles.metadataContainer}>
                <Image source={{ uri: customImageUrl || card.metadata.image || "https://via.placeholder.com/600" }} style={styles.metadataImage} />
                <Text style={styles.metadataTitle}>{card.metadata.title}</Text>
              </Pressable>
            ) : (
              <Text style={styles.metadataTitle}>No metadata available</Text>
            )}
            
            {effectiveIsOwner && (
              <>
                <TextInput
                  value={url}
                  onChangeText={(text) => handleUrlChange(text)}
                  style={[styles.textInput, styles.widgetShadow]}
                  placeholder="Enter URL..."
                />

                <Pressable onPress={handleFetchMetadata} style={styles.fileInputButton1}>
                  <Text style={styles.fileInputButtonText}>Get URL</Text>
                </Pressable>

                <Pressable
                  style={[styles.fileInputButton1, !card.metadata && styles.disabledButton]}
                  onPress={() => card.metadata && document.getElementById(`fileInput-${widgetIndex}`).click()}
                  disabled={!card.metadata}
                >
                  <Text style={styles.fileInputButtonText}>Add a Cover Photo</Text>
                </Pressable>

                <input
                  id={`fileInput-${widgetIndex}`}
                  type="file"
                  onChange={handleImageUpload}
                  style={styles.fileInput}
                />

                {isLoading && <Text>Loading...</Text>} 

                {customImageUrl && (
                  <Pressable onPress={handleRemoveImage} style={styles.removeImageButton}>
                    <Text style={styles.removeButtonText}>Remove Image</Text>
                  </Pressable>
                )}
              </>
            )}
          </View>
        );
      case "Countdown":
        return (
          <View>
            <Countdown
              targetDate={targetDate.toISOString()}
              onCompleteMessage={onCompleteMessage}
            />
            {effectiveIsOwner && (
              <View>
                {Platform.OS === 'web' ? (
                  <input
                    type="datetime-local"
                    value={targetDate.toISOString().slice(0, 16)}
                    onChange={(e) => handleDateChange(null, new Date(e.target.value))}
                    style={styles.webDateTimeInput}
                  />
                ) : (
                  <Pressable onPress={() => setShowDatePicker(true)} style={styles.datePickerButton}>
                    <Text style={styles.datePickerButtonText}>Set Date & Time</Text>
                  </Pressable>
                )}
                {Platform.OS !== 'web' && showDatePicker && (
                  <DateTimePicker
                    value={targetDate}
                    mode="datetime"
                    display="default"
                    onChange={handleDateChange}
                  />
                )}
                <TextInput
                  value={onCompleteMessage}
                  onChangeText={handleCompleteMessageChange}
                  style={styles.textInput}
                  placeholder="Enter completion message..."
                />
              </View>
            )}
          </View>
        );
      default:
        return <Text style={styles.selectWidgetText}>Select a widget</Text>;
    }
  };

  const handleShowCatalogue = () => {
    // Logic to show the catalogue
    onToggleCatalogue(); // Toggle the catalogue visibility
  };

  return (
    <View style={styles.card}>
      {isOwner && (
        <Picker
          selectedValue={selectedWidgetType}
          onValueChange={(itemValue) => handleWidgetTypeChange(itemValue)}
          style={styles.picker}
          itemStyle={styles.pickerItem}
        >
          <Picker.Item label="Select a widget type..." value="" style={styles.pickerItem} />
          <Picker.Item label="Textbox" value="TextBox" style={styles.pickerItem} />
          <Picker.Item label="RSVP" value="RSVP" style={styles.pickerItem} />
          <Picker.Item label="Image Slideshow" value="ImageGallery" style={styles.pickerItem} />
          <Picker.Item label="Event URL" value="URLPreview" style={styles.pickerItem} />
          <Picker.Item label="Polaroids" value="PictureFrame" style={styles.pickerItem} />
          <Picker.Item label="Countdown" value="Countdown" style={styles.pickerItem} />
        </Picker>
      )}
      {renderWidget()}
      {isOwner && (
        <>
          {hasChanges && (
            <Pressable style={styles.saveButton} onPress={saveCard}>
              <Text style={styles.saveButtonText}>Save Changes</Text>
            </Pressable>
          )}
          <Pressable style={styles.removeButton} onPress={onRemove}>
            <Text style={styles.buttonText}>Remove Widget</Text>
          </Pressable>
          {isLastCard && (
            <Pressable style={styles.addButton} onPress={() => onAdd("")}>
              <Text style={styles.addButtonText}> + </Text>
            </Pressable>
          )}
        </>
      )}
      {successMessage && (
        <View style={styles.successMessageContainer}>
          <Text style={styles.successMessageText}>{successMessage}</Text>
        </View>
      )}
      <PromptSignUp
        isVisible={showSignUpPrompt}
        onClose={() => setShowSignUpPrompt(false)}
        originalPage="Events"
        redirectPage="SignUp"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    zIndex: 1001, // Ensure this is higher than the HeaderBar
    width: isMobile ? "90vw" : "105%",
    marginVertical: 20,
    padding: 20,
    backgroundColor: "rgba(254, 253, 226, 0)",
    borderRadius: 15,
    justifyContent: "center",
    alignItems: 'center',
  },
  textInput: {
    padding: 10,
    borderColor: "rgba(0, 0, 0, 0.25)",
    borderWidth: 1,
    borderRadius: 5,
    marginVertical: 10,
    textAlign: 'center',
    backgroundColor: "rgba(254, 253, 226, 1)",
    fontFamily: fontFamilies.Bodoni72Smallcaps,
  },
  textInputOwner: {
    padding: 10,
    borderColor: "#4c956c",
    borderWidth: 1,
    borderRadius: 5,
    marginVertical: 10,
    textAlign: 'center',
    backgroundColor: '#fefde2',
    color: '#4c956c',
    width: "50%",
    fontSize: isMobile ? 16 : 22,
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Orpheus Pro",
    verticalAlign: "middle",
  },
  textInputViewer: {
    padding: 10,
    borderRadius: 5,
    marginVertical: 10,
    textAlign: 'center',
    backgroundColor: '#fefde2',
    color: '#4c956c',
    width: "50%",
    fontSize: isMobile ? 16 : 22,
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Orpheus Pro",
    verticalAlign: "middle",
  },
  image: {
    width: 100,
    height: 100,
    marginVertical: 10,
    borderRadius: 0,
    borderWidth: 2,
    borderColor: "rgba(0, 0, 0, 1)",
  },
  button: {
    padding: 10,
    backgroundColor: "#ccc",
    borderRadius: 5,
    marginVertical: 5,
  },
  selectedButton: {
    padding: 10,
    backgroundColor: "#4c956c",
    borderRadius: 5,
    marginVertical: 5,
  },
  removeButton: {
    backgroundColor: "#ff4c4c",
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
    marginVertical: 10,
  },
  addButton: {
    backgroundColor: "#4c956c",
    padding: 10,
    borderRadius: 100,
    alignItems: "center",
    marginTop: 25,
  },
  addButtonText: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: 18
  },
  buttonText: {
    color: "#fff",
    fontWeight: "bold",
  },
  saveButton: {
    backgroundColor: "#4c956c",
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
    marginVertical: 10,
  },
  saveButtonText: {
    color: "#fff",
    fontWeight: "bold",
  },
  removeImageButton: {
    backgroundColor: "#ff0000",
    padding: 5,
    borderRadius: 5,
    alignItems: "center",
    marginVertical: 5,
  },
  removeButtonText: {
    color: "#fff",
    fontSize: 14,
  },
  fileInputButton: {
    backgroundColor: '#4c956c',
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 60,
    width: "50%",
    shadowColor: "rgba(0, 0, 0, 0.5)",
    shadowOffset: { width: 0, height: 6 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  fileInputButton1: {
    backgroundColor: '#4c956c',
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: "center",
    marginTop: 10,
    marginBottom: 60,
    flex: 1,
    width: "100%",
    shadowColor: "rgba(0, 0, 0, 0.5)",
    shadowOffset: { width: 0, height: 6 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  fileInputButtonText: {
    color: '#fff',
    fontSize: 14,
    fontWeight: "bold",
    alignItems: 'center',
  },
  fileInput: {
    display: 'none',
  },
  tiltedImage: {
    width: isMobile ? 250 : 400,
    height: isMobile ? 250 : 400,
    borderRadius: 10,
  },
  pictureFrameContainer: {
    width: "90vw",
    padding: 10,
    backgroundColor: "rgba(254, 253, 226, 0)",
    marginBottom: 20,
    paddingTop: 50,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  pictureFrameImages: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    
  },
  polaroid: (index) => ({
    position: "relative",
    padding: 10,
    backgroundColor: "#fff",
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 2,
    elevation: 5,
    transform: [{ rotate: `${index % 2 === 0 ? -10 : 10}deg` }],
  }),
  removeImageButton: {
    position: "absolute",
    top: 5,
    right: 5,
    backgroundColor: "#ff0000",
    padding: 5,
    borderRadius: 5,
    alignItems: "center",
  },
  removeButtonText: {
    color: "#fff",
    fontSize: 12,
  },
  picker: {
    width: '65%',
    height: 50,
    backgroundColor: '#4c956c',
    color: '#000',
    borderRadius: 5,
    borderColor: '#4c956c',
    marginVertical: 10,
    justifyContent: 'center',
    fontFamily: fontFamilies.Bodoni72Smallcaps,
    fontSize: isMobile ? 14 : 20,
    shadowColor: "rgba(0, 0, 0, 0.5)",
    shadowOffset: { width: 0, height: 6 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    backgroundColor: "#fefde2",
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
    width: "50%"
  },  
  pickerItem: {
    width: "50%",
    fontSize: isMobile ? 16 : 20,
    fontFamily: fontFamilies.Bodoni72Smallcaps,
  },
  widgetShadow: {
    shadowColor: "rgba(0, 0, 0, 0.5)",
    shadowOffset: { width: 0, height: 6 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  metadataContainer: {
    alignItems: "center",
    marginBottom: 10,
  },
  metadataImage: {
    width: isMobile ? "90vw" : 1200,
    height: isMobile ? "50vh" : 700,
    borderRadius: 15,
  },
  metadataTitle: {
    marginTop: 10,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    color: "#fefde2", // Beige color
    fontFamily: "Orpheus Pro",
  },
  rsvpContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  rsvpButtonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  RSVPbutton: {
    paddingHorizontal: isMobile ? 10 : 10,
    paddingVertical: isMobile ? 10 : 10,
    backgroundColor: "#ccc",
    borderRadius: 5,
    marginVertical: isMobile ? 5 : 5,
    width: "50%",
    height: isMobile ? 30 : 50,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginRight: isMobile ? 5 : 20,
  },
  RSVPButtonText: {
    color: "#000",
    fontSize: isMobile ? 18 : 20,
    fontFamily: fontFamilies.Bodoni72Smallcaps,
  },
  selectedButtonGreen: {
    backgroundColor: "#4c956c",
    paddingHorizontal: isMobile ? "auto" : 10,
    paddingVertical: isMobile ? "auto" : 10,
    borderRadius: 5,
    marginVertical: isMobile ? 5 : 5,
    width: "40%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginRight: isMobile ? 5 : 20,
  },
  selectedButtonBeige: {
    backgroundColor: "#fefde2",
    paddingHorizontal: isMobile ? "auto" : 10,
    paddingVertical: isMobile ? "auto" : 10,
    borderRadius: 5,
    marginVertical: isMobile ? 5 : 5,
    width: "40%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginRight: isMobile ? 5 : 20,
  },
  selectedButtonRed: {
    backgroundColor: "#ff4c4c",
    paddingHorizontal: isMobile ? "auto" : 10,
    paddingVertical: isMobile ? "auto" : 10,
    borderRadius: 5,
    marginVertical: isMobile ? 5 : 5,
    width: "40%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginRight: isMobile ? 5 : 20,
  },
  rsvpCount: {
    position: "absolute",
    top: isMobile ? 2.5 : 5,
    right: isMobile ? 2.5 : 5, 
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: isMobile ? 2.5 : 5,
    fontSize: isMobile ? 12 : 16,
    fontWeight: "bold",
  },
  successMessageContainer: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(76, 149, 108, 0.75)',
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  successMessageText: {
    color: 'white',
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: "#ccc",
  },
  selectWidgetText: {
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    color: "#fefde2", // Beige color
    fontFamily: "Orpheus Pro",
  },
  datePickerButton: {
    backgroundColor: '#4c956c',
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
    marginVertical: 10,
  },
  datePickerButtonText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  webDateTimeInput: {
    padding: 10,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 5,
    marginVertical: 10,
    width: '100%',
  },
});

export default EventCard;