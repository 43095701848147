import React, { useState, useEffect } from "react";
import { View, Text, ScrollView, StyleSheet, Platform, Button, Modal } from "react-native";
import { REACT_APP_AWS_SERVER_URL } from '@env';	
// Import Firebase
import { getAuth } from "firebase/auth";
import { getFirestore, doc, updateDoc, getDoc, setDoc } from "firebase/firestore"; 
import SMSPopup from "./SMSPopup";
import { useUser } from "../../Widgets/UserContext";

const Privacy = () => {
	// URL to your PDF file - you might need to adjust this
	// For local files, ensure the file is accessible from the public folder in your web project
	const { currentUser, currentUserData, setCurrentUser, setCurrentUserData } = useUser();

	let LOCAL_SERVER_URL = "http://localhost:3300";
	let AWS_SERVER_URL = REACT_APP_AWS_SERVER_URL;

	const isDevelopment = process.env.NODE_ENV === "development";
	const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;

 const pdfFileUrl = `${SERVER_URL}/pdfs/Privacy Agreement.pdf`;

	// console.log(pdfFileUrl)

	const [modalVisible, setModalVisible] = useState(false);
	const [modalMessage, setModalMessage] = useState("");
	const [isNoticeVisible, setIsNoticeVisible] = useState(true);

	const handleOptInOut = async () => {
		if (currentUserData) {
			const db = getFirestore();
			const userRef = doc(db, "users", currentUserData.email);
			const userDoc = await getDoc(userRef);

			if (userDoc.exists()) {
				await updateDoc(userRef, {
					smsOptIn: !userDoc.data().smsOptIn,
					smsOptInTimestamp: new Date().toISOString(),
					smsOptInMethod: 'privacy_page',
					smsOptInVersion: '1.0',
					smsOptInLocation: window.location.href
				});
				setModalMessage("Your SMS preferences have been updated.");
			} else {
				await setDoc(userRef, { 
					smsOptIn: true,
					smsOptInTimestamp: new Date().toISOString(),
					smsOptInMethod: 'privacy_page',
					smsOptInVersion: '1.0',
					smsOptInLocation: window.location.href
				});
				setModalMessage("Your SMS preferences have been set.");
			}
			// Update the user data to trigger a re-render
			setCurrentUserData({ 
				...currentUserData, 
				smsOptIn: !currentUserData.smsOptIn,
				smsOptInTimestamp: new Date().toISOString()
			});
		} else {
			setModalMessage("You must log in first to opt in to SMS.");
		}
		setModalVisible(true);
	};

	useEffect(() => {
		console.log("Opt In/Out Updated", "Opt-in currently set to", currentUserData?.smsOptIn );
	}, [currentUserData, modalVisible]);


	return (
		<View style={styles.body}>
			{isNoticeVisible && (
				<View style={styles.smsNotice}>
					<Text style={styles.smsNoticeText}>
						SMS and Text Message Communications Notice{"\n\n"}
						By using MyTassel's sharing features, you expressly consent to receive SMS messages from us. These may include sharing announcements and graduation information. Message and data rates may apply. You can opt out at any time by replying STOP to any message or contacting info@mytassel.com.
					</Text>
					<Text style={styles.smsNoticeText}>
						Consent Collection: Consent is collected via our website's contact form, where users can opt-in to receive messages by checking a consent box.
					</Text>
					<Text style={styles.smsNoticeText}>
						Sample Message: "Hello [Name], thank you for using MyTassel. You can view your announcement here: [Link]. Reply STOP to opt-out."
					</Text>
					<Button title={currentUserData?.smsOptIn ? "Opt Out of SMS" : "Opt In to SMS"} onPress={handleOptInOut} />
					<Button title="Minimize" onPress={() => setIsNoticeVisible(false)} />
				</View>
			)}
			
			{!isNoticeVisible && (
				<Button title="Show SMS Notice" onPress={() => setIsNoticeVisible(true)} />
			)}
			
			<ScrollView style={styles.pdfContainer}>
				<View style={styles.pdfWrapper}>
					{Platform.OS === "web" ? (
						<iframe 
							src={pdfFileUrl} 
							style={styles.pdf} 
							frameBorder="0"
						>
							Your browser does not support iframes.
						</iframe>
					) : (
						<Text style={styles.text}>PDF display is not supported on this platform.</Text>
					)}
				</View>
			</ScrollView>
			<SMSPopup
				visible={modalVisible}
				message={modalMessage}
				onClose={() => setModalVisible(false)}
			/>
		</View>
	);
};

// Styles
const styles = StyleSheet.create({
	body: {
		flex: 1,
		alignItems: "center",
		backgroundColor: "#4c956c",
		height: "100vh"
	},
	container: {
		flex: 1,
		padding: 50,
		backgroundColor: "#fefde2",
		width: "95vw",
		borderRadius: 75,
	},
	headerText: {
		fontFamily: "Bodoni72Smallcaps",
		fontWeight: "500",
		fontSize: 40,
		lineHeight: 30,
		textAlign: "center",
		color: "#4c956c",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
		marginBottom: 50,
	},
	pdfContainer: {
		flex: 1,
		width: "90vw",
		borderWidth: 1,
		borderColor: "#fff",
		borderRadius: 8,
		overflow: "hidden",
	},
	pdfWrapper: {
		paddingTop: 20,
	},
	pdf: {
		width: "100%",
		height: "100vh",
		minHeight: 500,
	},
	text: {
		fontFamily: "Bodoni72Smallcaps",
		textAlign: "center",
		margin: 20,
	},
	smsNotice: {
		backgroundColor: "#4c956c",
		padding: 10,
		borderBottomWidth: 1,
		borderBottomColor: "#fff",
		marginBottom: 10,
	},
	smsNoticeText: {
		fontFamily: "Bodoni72Smallcaps",
		fontSize: 16,
		color: "#fff",
		textAlign: "center",
		marginBottom: 10,
	},
	modalView: {
		margin: 20,
		backgroundColor: "white",
		borderRadius: 20,
		padding: 35,
		alignItems: "center",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
	},
	modalText: {
		marginBottom: 15,
		textAlign: "center",
	},
});

export default Privacy;
