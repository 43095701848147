import { StyleSheet, Platform, Dimensions } from "react-native";

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width <= 1500;
const isLargeDesktop = width > 1500;

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const maxViewHeight = "500vh";

const styles = StyleSheet.create({
	body: {
		flex: 1,
		height: "auto",
	},
	container: {
		flex: 1,
		width: "100vw",
		height: maxViewHeight,
		position: "relative", // This allows absolute positioning of its children
		alignItems: "center"
	},
	section: {
		flex: 1, // Each section takes up an equal portion of the screen's height
		minHeight: "100vh",
		zIndex: 1,
		left: 0,
		right: 0,
		justifyContent: "center",
		alignContent: "center",
		paddingBottom: 125,
		top: 0,
	},
	headerBar: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-between",
		backgroundColor: "transparent",
		paddingHorizontal: 100,
		paddingTop: 50,
		position: "relative",
		minWidth: "100vw",
		zIndex: 98,
	},
	logoArea: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-between",
		backgroundColor: "transparent",
		height: 62,
		marginTop: 10,
	},
	logoText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontWeight: "normal",
		fontSize: 50,
		textAlign: "left",
		color: "#fefde2",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	loginArea: {
		flex: 1,
		flexDirection: "row",
		backgroundColor: "transparent",
		maxHeight: 50,
	},
	loginButton: {
		width: 208,
		height: 62,
		backgroundColor: "transparent",
		alignItems: "center",
		justifyContent: "center",
		boxShadowOffset: { width: 3, height: 3 },
		boxShadowRadius: 6,
		boxShadowColor: "rgba(0, 0, 0, 0.16)",
		zIndex: 99,
		marginLeft: 600,
	},
	backButton: {
		position: "absolute",
		right: 200,
		width: "auto",
		height: 62,
		backgroundColor: "transparent",
		shadowOffset: { width: 7, height: 3 },
		shadowRadius: 6,
		shadowColor: "rgba(0, 0, 0, 0.04)",
		alignItems: "center",
		justifyContent: "center",
		zIndex: 99,
	},
	backButtonLeft: {
		position: "absolute",
		left: 200,
		width: "auto",
		height: 62,
		backgroundColor: "transparent",
		// shadowOffset: { width: 7, height: 3 },
		// shadowRadius: 6,
		// shadowColor: "rgba(0, 0, 0, 0.04)",
		alignItems: "left",
		justifyContent: "center",
		zIndex: 99,
	},
	backButtonToRegistry: {
		position: "absolute",
		top: 20,
		left: isMobile ? -85 : isTablet ? -350 : isSmallDesktop ? -435 : -475,
		width: 40, // Set a fixed width for a rounded button
		height: 40, // Height remains the same to maintain a circular shape
		backgroundColor: "red", // Changed to red background
		borderRadius: 31, // Half of width/height to make it perfectly round
		alignItems: "center", // Center align the text inside the button
		justifyContent: "center",
		zIndex: 99,
	},
	backButtonText: {
		// fontFamily: "Orpheus Pro",
		fontSize: isMobile ? 18 : 25,
		lineHeight: 30,
		textAlign: "center",
		color: "white", // Changed text color to white
	},
	loginButtonText: {
		fontFamily: "Orpheus Pro",
		fontSize: 25,
		lineHeight: 30,
		textAlign: "center",
		color: "#e4e4e4",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	cancelButtonText: {
		fontFamily: "Orpheus Pro",
		fontSize: 25,
		textAlign: "center",
		color: "#e4e4e4",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
		marginHorizontal: 25,
		paddingRight: 90,
	},
	signOutButtonText: {
		fontFamily: "Orpheus Pro",
		fontSize: 25,
		textAlign: "center",
		color: "#4c956c",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	buttonText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: 25,
		lineHeight: 30,
		textAlign: "center",
		color: "#fefde2",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	backgroundImage: {
		position: "absolute",
		width: "100%",
		resizeMode: "cover",
	},
	welcomeText: {
		width: 1000,
		height: 127,
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: 90,
		textAlign: "center",
		color: "#fefde2",
		textShadowOffset: { width: 30, height: 3 },
		textShadowRadius: 4,
		textShadowColor: "rgba(0, 0, 0, 0.06)",
		textAlign: "center",
		alignSelf: "center", 
	},
	descriptionText: {
		width: 731,
		height: 199,
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: 45,
		lineHeight: 60,
		textAlign: "center",
		color: "#fefde2",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		textAlign: "center", // Change this for horizontal centering
		alignSelf: "center", // Add this for vertical centering
	},
	pageHeader: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-evenly",
		zIndex: 10,
		paddingVertical: 50
	},
	header: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		color: "#e4e4e4",
		fontSize: 45,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.4)",
		paddingBottom: 25,
		marginTop: 100,
	},
	yearbookContainer: {
		paddingTop: 65,
		flex: 1, // takes up 1/3 of the space
		alignItems: "center",
		justifyContent: "center",
		minWidth: "30vw",
		height: isMobile ? 0 : isTablet ? 0 : isSmallDesktop ? 1000 : 1000,
		paddingLeft: isMobile ? 50 : isTablet ? 50 : isSmallDesktop ? 50 : 25,
		marginTop: isMobile ? 0 : isTablet ? 0 : isSmallDesktop ? -95 : -25,
	},
	shareContainer: {
		top: 45,
		zIndex: 1000,
		height: 75,
		width: "100%",
		justifyContent: "center",
		alignItems: "center",
	},
	shareButton: {
		paddingLeft: isMobile ? 50 : isTablet ? 50 : isSmallDesktop ? 50 : 50,
		height: 75
	},
	goalTrackerContainer: {
		flex: 1, // takes up 1/3 of the space
		alignItems: "center",
		justifyContent: "center",
		minWidth: "30vw",
		height: isMobile ? 0 : isTablet ? 0 : isSmallDesktop ? 1040 : 1000,
		paddingRight: isMobile ? 50 : isTablet ? 50 : isSmallDesktop ? 50 : 20,
		marginTop: isMobile ? 50 : isTablet ? 50 : isSmallDesktop ? -110 : -25
	},
	eventsButton: {
		width: isMobile ? 284 : isTablet ? 284 : isSmallDesktop ? 300 : 400,
		height: isMobile ? 55 : isTablet ? 55 : isSmallDesktop ? 60 : 75,
		backgroundColor: "#fefde2",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 40,
		transition: 'all 1s ease',
		marginTop: 25,
	},
	eventsButtonHover: {
		backgroundColor: "#e6e5cb",
		transform: [{ scale: 1.025 }],
	},
	eventsButtonText: {
		fontFamily: "Orpheus Pro",
		fontWeight: "500",
		fontSize: 20,
		lineHeight: 30,
		textAlign: "center",
		color: "#4c956c",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
	},
	profileContainer: {
		flex: 1, // takes up 1/3 of the space
		alignItems: "center",
		justifyContent: "center",
		position: "relative",
		top: 0,
		right: 0,
		left: 0,
		marginTop: 50,
		marginBottom: 100,
		borderRadius: 250,
		zIndex: 10,
		paddingHorizontal: isMobile ? 50 : 100,
	},
	profileImage: {
		width: isMobile ? 200 : 250,
		height: isMobile ? 200 : 250,
		borderRadius: 250,
		marginBottom: 10,
	},
	sealImage: {
		width: isMobile ? 50 : 75,
		height: isMobile ? 50 : 75,
		borderRadius: 60,
		marginTop: isMobile ? 50 : isTablet ? 25 : isSmallDesktop ? 25 : 25,
		marginRight: isMobile ? 100 : 175,
		position: "absolute",
	},
	welcomeText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		color: "#e4e4e4",
		fontSize: 90,
		paddingBottom: 25,
		paddingTop: 50,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.4)",
	},
	profileName: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		color: "#e4e4e4",
		fontSize: isMobile ? 40 : isTablet ? 75 : isSmallDesktop ? 60 : 75,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.4)",
		textAlign: "center",
	},
	profileDetails: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		color: "#e4e4e4",
		fontSize: isMobile ? 20 : isTablet ? 25 : isSmallDesktop ? 20 : 25,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.4)",
		textAlign: "center",
	},
	profileDetailsSmall: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		paddingTop: 2,
		color: "#e4e4e4",
		fontSize: 20,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.4)",
		textAlign: "center",
	},
	buttonsContainer: {
		flexDirection: "row",
		justifyContent: "center",
		maxWidth: 1500,
		alignSelf: "center", // Add this for vertical centering
		marginTop: 50,
	},
	buttonArea: {
		marginHorizontal: 50,
		flexDirection: "column",
		justifyContent: "center",
		alignContent: "center",
		alignSelf: "center", // Add this for vertical centering
	},
	button: {
		width: 284,
		height: 55,
		backgroundColor: "#4c956c",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 40,
		transition: 'transform 0.5s ease, background-color 0.5s ease',
	},
	buttonHover: {
		backgroundColor: "#3e7a58",
		transform: 'scale(1.025)',
	},
	buttonDescription: {
		width: 300,
		height: 96,
		paddingTop: 15,
		fontFamily: "Orpheus Pro",
		fontWeight: "bold",
		fontSize: 18,
		lineHeight: 30,
		textAlign: "center",
		color: "#fefde1",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		alignSelf: "center", // Add this for vertical centering
	},
	editButton: {
		alignSelf: "flex-end",
		marginRight: "5%",
		marginTop: 20,
		paddingVertical: 10,
		paddingHorizontal: 10,
		borderRadius: 20,
		backgroundColor: "#4c956c",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		width: "20%",
		top: 100,
		position: Platform.OS === "web" ? "fixed" : "absolute",
		zIndex: 1000,
	},
	backgroundButton: {
		alignSelf: "flex-end", // Aligns the button to the right
		marginRight: "5%", // Adds spacing from the right edge of the screen
		marginTop: 20, // Adds spacing from the top
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderRadius: 20,
		backgroundColor: "#4c956c", // A green background color
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		width: "20%",
		top: 160,
		position: Platform.OS === "web" ? "fixed" : "absolute",
		zIndex: 1000, // Ensure it stays above other elements
	},
	editButtonText: {
		fontFamily: "Orpheus Pro",
		fontSize: isMobile ? 14 : isTablet ? 20 : isSmallDesktop ? 22 : 25,
		textAlign: "center",
		color: "#e4e4e4",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
		marginHorizontal: 25,
	},
	editControls: {
		position: "absolute", // Position absolutely to place over other content
		bottom: 0, // Align to the bottom of the parent
		left: 0, // Align to the left of the parent
		right: 0, // Align to the right of the parent
		height: 40,
		backgroundColor: "rgba(0,0,0,0.5)", // Semi-transparent background
		padding: 20, // Add some padding around the buttons
		flexDirection: "row", // Layout buttons in a row
		justifyContent: "center", // Center buttons horizontally
		alignItems: "center", // Center items vertically within the container
		justifyContent: "space-around",
		// @ts-ignore
		position: Platform.OS === "web" ? "fixed" : undefined,
	},
	previewStyle: {
		width: 100, // adjust size accordingly
		height: 50, // adjust size accordingly
	},
	prevText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: 18,
		textAlign: "center",
		color: "#fefde2",
		textShadowOffset: { width: 30, height: 3 },
		textShadowRadius: 4,
		textShadowColor: "rgba(0, 0, 0, 0.06)",
		textAlign: "center", // Change this for horizontal centering
		alignSelf: "center", // Add this for vertical centering
	},
	addGridButton: {
		marginTop: 0, // Space above the button
		width: "100%", // Full width to center the button text
		alignItems: "center", // Center content horizontally in the container
	},
	addButton: {
		color: "white",
		fontSize: 30,
		textAlign: "center", // Center the '+' text horizontally
		bottom: -750,
		zIndex: 99,
	},

	savePopup: {
		position: "absolute",
		bottom: 20, // You can adjust this
		left: 0,
		right: 0,
		alignItems: "center",
	},
	savePopupText: {
		backgroundColor: "#4c956c", // Or any color you prefer
		color: "white",
		padding: 10,
		borderRadius: 5,
	},
	errorPopup: {
		position: "absolute",
		bottom: 20, // You can adjust this
		left: 0,
		right: 0,
		alignItems: "center",
		backgroundColor: "red", // Or any color you prefer
	},
	errorPopupText: {
		color: "white",
		padding: 10,
		borderRadius: 5,
	},
	// Styles for the widget placeholders within the EditablePage component
	widgetPlaceholder: {
		borderWidth: 2,
		borderColor: "#cccccc", // A light grey border color
		borderRadius: 10,
		padding: 20,
		marginBottom: 20, // Adds spacing between each widget placeholder
		alignItems: "center",
		justifyContent: "center",
	},
	// Text style for empty widget placeholders
	widgetPlaceholderText: {
		color: "#cccccc", // Light grey text color
		textAlign: "center",
	},
	// Style for the actual widgets when in non-edit mode
	widgetContainer: {
		// Style similar to widgetPlaceholder but without the border
		padding: 20,
		marginBottom: 20,
	},

	gridItem: {
		width: 400,
		height: 400,
		margin: 75,
		borderColor: "#cccccc",
		borderWidth: 2,
		borderRadius: 10,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	gridItemOccupied: {
		backgroundColor: "#f0f0f0",
		borderWidth: 1,
		borderColor: "#ccc",
	},
	deleteButton: {
		position: "absolute",
		top: 0,
		right: 0,
		backgroundColor: "red",
		padding: 5,
		borderRadius: 5,
		color: "white",
		fontWeight: "bold",
	},

	modalContainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
		top: 0,
	},

	modalContent: {
		width: "80%", // Adjust width as needed
		height: "100%",
		alignSelf: "center", // Center horizontally
		backgroundColor: "#fefde2",
		borderBottomLeftRadius: 25, // Adjust the radius as needed
		borderBottomRightRadius: 25, // Adjust the radius as needed
		padding: 20,
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		justifyContent: "center",
		alignItems: "center",

	},
	catalogueModalContent: {
		width: "90%", // Adjust width as needed
		height: "100%",
		borderRadius: 40,
		alignSelf: "center", // Center horizontally
		backgroundColor: "#fefde2",
		paddingHorizontal: 20,
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		top: 0,
		zIndex: 2000, // Ensure it stays above other elements
	},
	catelogueContainer: {
		flex: 1,
		alignItems: "flex-start",
		width: "100%",
		top: 0,
	},
	modalSubText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: 25,
		textAlign: "center",
		color: "#4C956C", // Or any other color that fits your theme
		marginBottom: 15,
	},
	overlayBlur: {
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: "rgba(0,0,0,0.7)", // translucent black
		backdropFilter: "blur(25px)", // Adjust the px value to control the amount of blur
		zIndex: 1,
		height: maxViewHeight,
	},
	modalToggleButton: {
		alignSelf: "flex-end",
		marginRight: "5%",
		marginTop: 10, // Spacing between buttons
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderRadius: 20,
		backgroundColor: "#4c956c",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		top: isMobile ? 10 : 60,
		width: isMobile ? "50%" : "20%",
		zIndex: 1000,
		// Adjust other styling as needed
	},
	input: {
		borderWidth: 1,
		borderColor: "#ddd",
		padding: 10,
		fontSize: 18,
		borderRadius: 6,
		marginVertical: 10,
	},
	itemText: {
		fontFamily: "Orpheus Pro",
		fontSize: 16,
		color: "#333",
		textAlign: "center",
		marginVertical: 5,
	},
	imagePreview: {
		width: 100,
		height: 100,
		borderRadius: 50,
		marginVertical: 10,
	},
	input: {
		borderWidth: 1,
		borderColor: "#ddd",
		padding: 10,
		fontSize: 18,
		borderRadius: 6,
		marginVertical: 10,
	},
	gridContainer: {
		flex: 1,
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "space-around", // Center items horizontally
		alignItems: "flex-start", // Align items vertically
		paddingBottom: 250, // Space for widget menu
		top: 0,
		position: "relative", // Ensure this is set
		// Define explicit dimensions if necessary
		width: "100%",
	},

	itemCard: {
		backgroundColor: "#fefde2",
		borderRadius: 10, // Rounded corners
		marginVertical: isMobile ? 5 : 10, // Vertical spacing between cards
		marginHorizontal: isMobile ? 5 : 20, // Horizontal spacing from screen edges
		shadowColor: "#171717", // Shadow color
		shadowOffset: { width: 0, height: 4 }, // Shadow offset
		shadowOpacity: 0.2, // Shadow opacity
		shadowRadius: 3, // Shadow blur radius
		elevation: 5, // Elevation for Android
		alignItems: "center", // Center items horizontally in the card
		maxWidth: "30%",
		height: isMobile ? "50vw" : 550,
		maxHeight: isMobile ? "50vw" : 550,
		padding: isMobile ? 5 : 10,
	},

	itemImage: {
		width: isMobile ? "100%" : "100%", // Width of the image
		height: isMobile ? "30vw" : 350, // Height of the image
		borderRadius: 10, // Rounded corners for the image
		marginBottom: isMobile ? 5 : 10, // Spacing between image and text
	},

	itemName: {
		fontFamily: "Orpheus Pro", // Font family
		fontSize: isMobile ? 12 : 24, // Font size
		fontWeight: "bold", // Font weight
		color: "#333", // Text color
		textAlign: "center", // Center text
		marginBottom: isMobile ? 5 : 15, // Spacing between name and description
	},

	itemDescription: {
		fontFamily: "Orpheus Pro", // Font family
		fontSize: isMobile ? 14 : 18, // Font size
		color: "#333", // Text color
		textAlign: "center", // Center text
		marginBottom: isMobile ? 2 : 10, // Spacing between description and price
	},

	itemPrice: {
		fontFamily: "Orpheus Pro", // Font family
		fontSize: isMobile ? 14 : 18, // Font size
		fontWeight: "bold", // Font weight
		color: "#4c956c", // Price text color, matching the theme
		textAlign: "center", // Center text
	},

	addButton: {
		backgroundColor: "blue",
		padding: 10,
		margin: 20,
		borderRadius: 5,
		alignSelf: "center",
	},

	itemSelected: {
		backgroundColor: "#4c956c", // Change as needed
	},
	modalContainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
	},
	blurred: {
		position: "absolute",
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		backdropFilter: "blur(10px)", // Apply blur effect
		// backgroundColor: "rgba(0, 0, 0, 0.5)",
	},
	blurredContainer: {
		// Apply a blur effect
		position: "absolute",
		backdropFilter: "blur(10px)", // Apply blur effect
		zIndex: 9999,
		paddingTop: 50,
		paddingBottom: 500,
		width: "100%",
		overflow: "hidden",
	},
	modalText: {
		fontSize: 18,
	},
	confirmButton: {
		backgroundColor: '#4c956c',
		padding: 15,
		borderRadius: 5,
		marginVertical: 10,
		width: '80%',
		alignItems: 'center',
	},
	denyButton: {
		backgroundColor: 'red', // Red background
		padding: 15,
		borderRadius: 5,
		marginVertical: 10,
		width: '80%',
		alignItems: 'center',
	},
	buttonText: {
		color: 'white',
		fontSize: 16,
		fontWeight: 'bold',
	},
	purchasedItem: {
		// Additional style for purchased items (e.g., greyed-out look)
		backgroundColor: '#cccccc',
		color: '#666666', // Assuming this affects the text color
		textDecorationLine: 'line-through', // Optionally strike-through the text
		borderRadius: 10, // Rounded corners
		padding: isMobile ? 5 : 15, // Inner spacing
		marginVertical: isMobile ? 5 : 10, // Vertical spacing between cards
		marginHorizontal: isMobile ? 5 : 20, // Horizontal spacing from screen edges
		shadowColor: "#171717", // Shadow color
		shadowOffset: { width: 0, height: 4 }, // Shadow offset
		shadowOpacity: 0.2, // Shadow opacity
		shadowRadius: 3, // Shadow blur radius
		elevation: 5, // Elevation for Android
		alignItems: "center", // Center items horizontally in the card
		maxWidth: isMobile ? "30%" : "30%",
		maxHeight: isMobile ? "30%" : "30%"
	},
	purchasedLabel: {
		// Style for the "Purchased" label
		fontWeight: 'bold',
		color: 'red',
		fontSize: isMobile ? 16 : 24,
		fontFamily: "Orpheus Pro",
		paddingBottom: 10
	},
	modalContainer: {
		backgroundColor: '#fefde2',
		borderRadius: 10,
		padding: 20,
		width: '80%',
		maxWidth: 400,
		alignItems: 'center',
	},
	modalContent: {
		alignItems: 'center',
	},
	modalTitle: {
		fontSize: 24,
		fontWeight: 'bold',
		marginBottom: 10,
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		color: "#4c956c",
	},
	catalogueModalTitle: {
		fontSize: isMobile ? 18 : 24,
		fontWeight: 'bold',
		marginTop: 25,
		marginBottom: 10,
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		color: "#4c956c",
		flex: 1,
		textAlign: 'center',
	},
	shippingAddressText: {
		fontSize: 16,
		marginBottom: 20,
	},
	fixedContainer: {
		position: 'fixed',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		zIndex: 1000,
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		width: '100%',
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	successMessage: {
		position: 'absolute',
		top: 10,
		left: '50%',
		transform: 'translateX(-50%)',
		backgroundColor: '#4c956c',
		color: 'white',
		padding: 10,
		borderRadius: 5,
		zIndex: 1001, // Ensure it appears above other elements
	},
	catalogueItem: {
		transition: 'transform 0.3s ease',
	},
	catalogueItemHover: {
		transform: 'scale(1.05)',
	},
	categoryContainer: {
		transition: 'transform 0.3s ease',
	},
	categoryContainerHover: {
		transform: 'scale(1.05)',
	},
	closeButton: {
		backgroundColor: 'red',
		padding: 15,
		borderRadius: 5,
		marginVertical: 10,
		width: '80%',
		alignItems: 'center',
	},
	continueButton: {
		backgroundColor: '#4c956c',
		padding: 15,
		borderRadius: 5,
		marginVertical: 10,
		width: '80%',
		alignItems: 'center',
	},
	buttonText: {
		color: 'white',
		fontSize: 16,
		fontWeight: 'bold',
	},
	catalogueModalHeader: {
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%',
		position: 'relative',
		paddingVertical: 10,
	},

	catalogueCloseButton: {
		position: 'absolute',
		left: 20,
		top: 10,
		zIndex: 2,
		padding: 10,
		backgroundColor: 'red',
		borderRadius: 200,
		width: 50,
		height: 50,
	},
});

export default styles;
