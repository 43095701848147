import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, Pressable, Modal } from "react-native";
import axios from "axios";
import { useUser } from "../../Widgets/UserContext";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore"; // Ensure you import these
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth"; // Ensure correct imports
import { app, auth } from "../../firebase"; // Assuming you have these firebase configurations
import { REACT_APP_AWS_SERVER_URL } from '@env';	

let LOCAL_SERVER_URL = "http://localhost:3300";
let AWS_SERVER_URL = REACT_APP_AWS_SERVER_URL;

const isDevelopment = process.env.NODE_ENV === "development"; // Adjust this condition based on your setup
const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;


function ConnectedAccount(pageOwner) {
    const db = getFirestore(app);
    const email = pageOwner.email;
    const [accountCreated, setAccountCreated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { currentUser, currentUserData, setCurrentUser, setCurrentUserData } = useUser();

    const handleCreateAccount = async () => {
        setIsLoading(true);
        try {
            const returnUrl = pageOwner.pages.registry || pageOwner.pages.events;
            alert(`Attempting to use return URL: ${returnUrl}`); // Alert the URL being used

            if (!returnUrl) {
                throw new Error("No valid return URL found.");
            }

            const response = await axios.post(`${SERVER_URL}/create-connected-account`, {
                email: email,
                refresh_url: returnUrl,
                return_url: returnUrl,
                pageOwner: pageOwner,
                capabilities: {
                    transfers: { requested: true },
                    crypto_transfers: { requested: true },
                    legacy_payments: { requested: true }
                }
            });

            // console.log(response.data);
            // Redirect to Stripe's onboarding form
            if (response.data.accountLink && response.data.accountLink.url) {
                window.location = response.data.accountLink.url;
            } else {
                // Adjusted if response.data directly contains the URL
                window.location = response.data.url;
            }
        } catch (error) {
            console.error("Failed to create connected account:", error);
            alert(`Failed to create connected account: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };
};

const ConnectedAccountModal = ({ visible, onClose, pageOwner }) => {
    const [email, setEmail] = useState(pageOwner.email);
    const [isLoading, setIsLoading] = useState(false);
    const [hasStripeAccount, setHasStripeAccount] = useState(false);
    const [showUnlinkConfirmation, setShowUnlinkConfirmation] = useState(false);
    const [showFinishSetupButton, setShowFinishSetupButton] = useState(false);
    const [statusMessage, setStatusMessage] = useState({ type: '', message: '' });
    const db = getFirestore(app);

    // Check for cached stripeAccountId and Firestore status
    useEffect(() => {
        const cachedStripeId = localStorage.getItem(`stripeAccountId_${email}`);
        const hasFirestoreStripeId = pageOwner?.stripeAccountId;

        console.log('ConnectedAccountModal Local Storage Debug:', {
            email,
            cachedStripeId,
            hasFirestoreStripeId,
            localStorageKey: `stripeAccountId_${email}`,
        });

        if (cachedStripeId && !hasFirestoreStripeId) {
            setShowFinishSetupButton(true);
            setStatusMessage({
                type: 'info',
                message: 'Complete your Stripe account setup or restart the process'
            });
        }
        setHasStripeAccount(hasFirestoreStripeId ? true : false);
    }, [email, pageOwner]);

    const handleCreateAccount = async () => {
        setIsLoading(true);
        setStatusMessage({ type: '', message: '' });
        try {
            const returnUrl = pageOwner.pages.registry || pageOwner.pages.events;
            console.log("Creating account with return URL:", returnUrl);

            if (!returnUrl) {
                throw new Error("No valid return URL found.");
            }

            const response = await axios.post(`${SERVER_URL}/create-connected-account`, {
                email: email,
                refresh_url: returnUrl,
                return_url: returnUrl,
                pageOwner: pageOwner,
                capabilities: {
                    transfers: { requested: true },
                    crypto_transfers: { requested: true },
                    legacy_payments: { requested: true }
                }
            });

            console.log('Create account response:', response.data);

            if (response.data.stripeAccountId) {
                console.log('Saving to localStorage:', {
                    key: `stripeAccountId_${email}`,
                    value: response.data.stripeAccountId
                });
                localStorage.setItem(`stripeAccountId_${email}`, response.data.stripeAccountId);
            }

            if (response.data.url) {
                window.location = response.data.url;
            } else {
                setStatusMessage({
                    type: 'error',
                    message: 'Failed to get the account link URL. Please try again.'
                });
            }
        } catch (error) {
            console.error("Failed to create connected account:", error);
            setStatusMessage({
                type: 'error',
                message: `Failed to create connected account: ${error.message}`
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleRestartProcess = () => {
        localStorage.removeItem(`stripeAccountId_${email}`);
        setShowFinishSetupButton(false);
        setStatusMessage({
            type: 'info',
            message: 'Setup process restarted. You can begin again.'
        });
        setTimeout(() => handleCreateAccount(), 1000);
    };

    const handleUnlinkStripeConfirm = async () => {
        setIsLoading(true);
        setStatusMessage({ type: '', message: '' });
        try {
            const userRef = doc(db, "users", email);
            await updateDoc(userRef, {
                stripeAccountId: null,
                stripeSetupComplete: false
            });
            localStorage.removeItem(`stripeAccountId_${email}`);
            setHasStripeAccount(false);
            setShowFinishSetupButton(false);
            setShowUnlinkConfirmation(false);
            setStatusMessage({
                type: 'success',
                message: 'Stripe account successfully unlinked'
            });
            setTimeout(() => window.location.reload(), 2000);
        } catch (error) {
            console.error("Error unlinking Stripe account:", error);
            setStatusMessage({
                type: 'error',
                message: 'Failed to unlink Stripe account. Please try again.'
            });
        } finally {
            setIsLoading(false);
        }
    };

    if (!visible) return null;

    return (
        <Modal visible={visible} transparent={true} onRequestClose={onClose}>
            <View style={styles.blurredContainer}>
                <View style={styles.modalContent}>
                    <Text style={styles.title}>Stripe Connected Account</Text>
                    
                    {/* Process explanation section */}
                    <View style={styles.infoSection}>
                        <Text style={styles.infoTitle}>How it works:</Text>
                        <View style={styles.stepContainer}>
                            <Text style={styles.stepNumber}>1</Text>
                            <Text style={styles.stepText}>Create a Stripe account to receive contributions</Text>
                        </View>
                        <View style={styles.stepContainer}>
                            <Text style={styles.stepNumber}>2</Text>
                            <Text style={styles.stepText}>Complete Stripe's verification process</Text>
                        </View>
                        <View style={styles.stepContainer}>
                            <Text style={styles.stepNumber}>3</Text>
                            <Text style={styles.stepText}>Return to MyTassel to start receiving contributions</Text>
                        </View>
                    </View>

                    {statusMessage.message && (
                        <Text style={[
                            styles.statusMessage,
                            statusMessage.type === 'error' && styles.errorMessage,
                            statusMessage.type === 'success' && styles.successMessage,
                            statusMessage.type === 'info' && styles.infoMessage,
                        ]}>
                            {statusMessage.message}
                        </Text>
                    )}

                    {!hasStripeAccount && !showFinishSetupButton && (
                        <View style={styles.actionContainer}>
                            <Text style={styles.actionText}>
                                Ready to start accepting contributions? Create your Stripe account now.
                            </Text>
                            <Pressable 
                                onPress={handleCreateAccount} 
                                disabled={isLoading} 
                                style={[styles.button, isLoading && styles.buttonDisabled]}
                            >
                                <Text style={styles.buttonText}>
                                    {isLoading ? 'Creating Account...' : 'Start Accepting Contributions'}
                                </Text>
                            </Pressable>
                        </View>
                    )}

                    {showFinishSetupButton && (
                        <View style={styles.actionContainer}>
                            <Text style={styles.actionText}>
                                If you've completed the Stripe verification process, click "Finish Stripe Setup". If you need to start over, click "Restart Setup Process".
                            </Text>
                            <Pressable 
                                onPress={() => {
                                    const userRef = doc(db, "users", email);
                                    const cachedStripeId = localStorage.getItem(`stripeAccountId_${email}`);
                                    if (cachedStripeId) {
                                        updateDoc(userRef, {
                                            stripeAccountId: cachedStripeId,
                                            stripeSetupComplete: true
                                        }).then(() => {
                                            setHasStripeAccount(true);
                                            setShowFinishSetupButton(false);
                                            setStatusMessage({
                                                type: 'success',
                                                message: 'Stripe account setup completed successfully!'
                                            });
                                            setTimeout(() => window.location.reload(), 2000);
                                        }).catch(error => {
                                            console.error("Error updating Stripe account:", error);
                                            setStatusMessage({
                                                type: 'error',
                                                message: 'Failed to complete setup. Please try again.'
                                            });
                                        });
                                    }
                                }}
                                style={styles.button}
                            >
                                <Text style={styles.buttonText}>Finish Stripe Setup</Text>
                            </Pressable>
                            <Pressable 
                                onPress={handleRestartProcess}
                                style={[styles.button, { backgroundColor: '#666', marginTop: 10 }]}
                            >
                                <Text style={styles.buttonText}>Restart Setup Process</Text>
                            </Pressable>
                        </View>
                    )}

                    {hasStripeAccount && !showUnlinkConfirmation && (
                        <View style={styles.actionContainer}>
                            <Text style={styles.successText}>
                                Your Stripe connected account is active and ready to receive contributions!
                            </Text>
                            <Pressable 
                                onPress={() => setShowUnlinkConfirmation(true)} 
                                style={styles.unlinkButton}
                            >
                                <Text style={styles.buttonText}>Unlink Stripe Account</Text>
                            </Pressable>
                        </View>
                    )}

                    {/* Unlink Confirmation Modal */}
                    {showUnlinkConfirmation && (
                        <View style={styles.confirmationContainer}>
                            <Text style={styles.confirmationTitle}>
                                Are you sure you want to unlink your Stripe account?
                            </Text>
                            <Text style={styles.confirmationText}>
                                This will prevent you from receiving any future contributions, until you re-link your account.
                                This action cannot be undone.
                            </Text>
                            <View style={styles.confirmationButtons}>
                                <Pressable 
                                    onPress={handleUnlinkStripeConfirm}
                                    style={[styles.confirmButton, isLoading && styles.buttonDisabled]}
                                    disabled={isLoading}
                                >
                                    <Text style={styles.buttonText}>
                                        {isLoading ? 'Unlinking...' : 'Yes, Unlink Account'}
                                    </Text>
                                </Pressable>
                                <Pressable 
                                    onPress={() => setShowUnlinkConfirmation(false)}
                                    style={styles.cancelButton}
                                    disabled={isLoading}
                                >
                                    <Text style={styles.buttonText}>Cancel</Text>
                                </Pressable>
                            </View>
                        </View>
                    )}

                    {!showUnlinkConfirmation && (
                        <Pressable onPress={onClose} style={styles.closeButton}>
                            <Text style={styles.buttonText}>Close</Text>
                        </Pressable>
                    )}
                </View>
            </View>
        </Modal>
    );
};


const styles = StyleSheet.create({
    blurredContainer: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backdropFilter: "blur(10px)", // Apply blur effect
        justifyContent: "center",
        alignItems: "center",
    },
    modalContent: {
        padding: 30,
        backgroundColor: "#fefde2",
        borderRadius: 15,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        margin: 20,
        alignItems: "center",
        maxWidth: 500,
        width: "90%",
    },
    title: {
        fontSize: 24,
        fontWeight: "bold",
        marginBottom: 20,
    },
    button: {
        backgroundColor: "#4c956c",
        paddingVertical: 12,
        paddingHorizontal: 24,
        borderRadius: 25,
        width: "100%",
        maxWidth: 300,
        alignItems: "center",
        marginTop: 10,
    },
    buttonText: {
        color: "#fff",
        fontSize: 16,
        fontWeight: "600",
    },
    closeButton: {
        backgroundColor: "red",
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 20,
        marginTop: 10,
        width: 200, // Adjusts button width to match modal's width
        alignItems: "center", // Ensures text is centered within the button
    },
    unlinkButton: {
        backgroundColor: "#d32f2f",
        paddingVertical: 12,
        paddingHorizontal: 24,
        borderRadius: 25,
        width: "100%",
        maxWidth: 300,
        alignItems: "center",
        marginTop: 10,
    },
    statusMessage: {
        padding: 15,
        borderRadius: 8,
        marginBottom: 20,
        textAlign: "center",
        width: "100%",
        fontSize: 14,
    },
    errorMessage: {
        backgroundColor: '#ffebee',
        color: '#c62828',
    },
    successMessage: {
        backgroundColor: '#e8f5e9',
        color: '#2e7d32',
    },
    infoMessage: {
        backgroundColor: '#e3f2fd',
        color: '#1565c0',
    },
    buttonDisabled: {
        opacity: 0.7,
    },
    confirmationContainer: {
        width: "100%",
        alignItems: "center",
        backgroundColor: "rgba(211, 47, 47, 0.1)",
        padding: 20,
        borderRadius: 10,
    },
    confirmationTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
        textAlign: 'center',
        color: '#d32f2f',
    },
    confirmationText: {
        marginBottom: 20,
        textAlign: 'center',
        color: '#666',
    },
    confirmationButtons: {
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
    },
    confirmButton: {
        backgroundColor: '#d32f2f',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 20,
        marginBottom: 10,
        width: 200,
        alignItems: 'center',
    },
    cancelButton: {
        backgroundColor: '#666',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 20,
        width: 200,
        alignItems: 'center',
    },
    successText: {
        color: '#2e7d32',
        marginBottom: 15,
        textAlign: 'center',
    },
    infoSection: {
        width: "100%",
        marginBottom: 25,
        backgroundColor: "rgba(76, 149, 108, 0.1)",
        padding: 20,
        borderRadius: 10,
    },
    infoTitle: {
        fontSize: 18,
        fontWeight: "bold",
        marginBottom: 15,
        color: "#4c956c",
    },
    stepContainer: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 10,
    },
    stepNumber: {
        width: 24,
        height: 24,
        borderRadius: 12,
        backgroundColor: "#4c956c",
        color: "#fff",
        textAlign: "center",
        lineHeight: 24,
        marginRight: 10,
        fontSize: 14,
        fontWeight: "bold",
    },
    stepText: {
        flex: 1,
        fontSize: 14,
        color: "#333",
    },
    actionContainer: {
        width: "100%",
        alignItems: "center",
        marginBottom: 20,
    },
    actionText: {
        fontSize: 16,
        color: "#666",
        marginBottom: 15,
        textAlign: "center",
        lineHeight: 22,
    },
});

export { ConnectedAccount, ConnectedAccountModal };