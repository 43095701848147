import React, { useState, useEffect } from "react";
import { View, Text, Button, StyleSheet, Dimensions, TextInput } from "react-native";
import { getFirestore, collection, getDocs, updateDoc, query, where, setDoc, addDoc, doc, deleteDoc, onSnapshot, getDoc } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { app } from "../firebase";
import AWS from "aws-sdk";
import { useUser } from "../Widgets/UserContext";
import Popup from "../Widgets/Popup";
import { sendPasswordResetEmail } from "firebase/auth";
import axios from 'axios';

const auth = getAuth(app);

const isDevelopment = process.env.NODE_ENV === "development";

const s3 = new AWS.S3();
const db = getFirestore();

AWS.config.update({
	accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
	secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
	region: process.env.REACT_APP_AWS_REGION,
});

// Add after imports
const { width } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width < 1440;
const isLargeDesktop = width >= 1440;

const Admin = () => {
	const [organizations, setOrganizations] = useState([]);
	const [approvedOrgs, setApprovedOrgs] = useState([]);
	const [deniedOrgs, setDeniedOrgs] = useState([]);
	const [loading, setLoading] = useState(true);
	const [catalogueItems, setCatalogueItems] = useState([]); // New state for catalogue items

	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 25;
	const [searchQuery, setSearchQuery] = useState("");

	const [affiliateUrl, setAffiliateUrl] = useState("");
	const [itemPreview, setItemPreview] = useState(null);

	const [loadingStep, setLoadingStep] = useState("");
	const [isFetching, setIsFetching] = useState(false);
	const [showStillWorking, setShowStillWorking] = useState(false);

	const [category, setCategory] = useState(""); // For selecting a category
	const categories = ["Bedroom", "Bathroom", "Decor", "Health & Personal Care", "Kitchen", "Laundry & Cleaning", "Electronics & Office Supplies", "Office Supplies", "Storage & Organization"];

	const [categoryError, setCategoryError] = useState(false);

	const [filter, setFilter] = useState("all"); // 'all', or any of the categories
	const [sort, setSort] = useState("none"); // 'none', 'priceLowHigh', 'priceHighLow'

	const [itemName, setItemName] = useState("");
	const [itemDescription, setItemDescription] = useState("");
	const [itemPrice, setItemPrice] = useState("");
	const [itemImage, setItemImage] = useState("");

	const [selectedPrice, setSelectedPrice] = useState('');
	const [priceUpdates, setPriceUpdates] = useState({}); // For individual edits
	const [selectedCategory, setSelectedCategory] = useState('');
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [bulkPrice, setBulkPrice] = useState('');
	const [bulkDescription, setBulkDescription] = useState('');

	const [activeTab, setActiveTab] = useState("organizations"); // Default tab

	const tabs = [
		{ id: "organizations", label: "Organizations" },
		{ id: "catalogue", label: "Catalogue" },
		{ id: "blog", label: "Blog" },
		{ id: "maintenance" }
	];

	let LOCAL_SERVER_URL = "http://localhost:3300";
	let AWS_SERVER_URL = "https://api.mytassel.com";

	const isDevelopment = process.env.NODE_ENV === "development"; // Adjust this condition based on your setup
	const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;

	const { currentUserData } = useUser();
	const [showLoginPopup, setShowLoginPopup] = useState(false);
	const [showAdmin, setShowAdmin] = useState(false); // New state for showing admin content

	const [testEmail, setTestEmail] = useState("");
	const [emailType, setEmailType] = useState("genericWelcome");
	const [emailParams, setEmailParams] = useState({});
	const [emailTestResult, setEmailTestResult] = useState("");
	const [recipientEmail, setRecipientEmail] = useState("");
	const [testPhone, setTestPhone] = useState("");
	const [smsType, setSmsType] = useState("welcome");
	const [smsTestResult, setSmsTestResult] = useState("");

	const [isMaintenance, setIsMaintenance] = useState(false);
	const [mismatchedUsers, setMismatchedUsers] = useState([]);
	const [maintenanceMode, setMaintenanceMode] = useState(false);
	const [disableRegistration, setDisableRegistration] = useState(false);

	const [blogTitle, setBlogTitle] = useState("");
	const [blogContent, setBlogContent] = useState("");
	const [blogCategory, setBlogCategory] = useState("");
	const [blogImage, setBlogImage] = useState("");
	const [blogTags, setBlogTags] = useState([]);
	const [blogPosts, setBlogPosts] = useState([]);
	const [editingBlogId, setEditingBlogId] = useState(null);
	const [publishDate, setPublishDate] = useState("");
	const [lastUpdatedDate, setLastUpdatedDate] = useState("");

	const blogCategories = [
		"College Life",
		"Graduation Tips",
		"Dorm Living",
		"Study Tips",
		"Career Advice",
		"Financial Planning",
		"Student Life",
		"Moving Tips"
	];

	// Add preset blog tags
	const presetBlogTags = [
		"Freshman Tips",
		"Senior Year",
		"Dorm Essentials",
		"Study Hacks",
		"Career Planning",
		"Money Management",
		"Campus Life",
		"Organization Tips",
		"Time Management",
		"College Success",
		"Graduation",
		"Student Resources",
		"Moving Guide",
		"College Prep",
		"Student Life Hacks"
	];

	// Add state for subcategories
	const [subCategories, setSubCategories] = useState({
		"Bedroom": [
			"Sheets", "Comforter", "Bed In a Bag", "Pillow", "Pillow Protector", "Body Pillow",
			"Mattress Topper", "Mattress Protector", "Throw Blanket", "Study Pillow",
			"Space Heaters", // Added new subcategory
			"Room Security", // Changed from "Room Alarm"
			"Misc"
		],
		"Bathroom": [
			"Towel Set", "Waterproof Speakers", // Changed from "Bluetooth Speaker"
			"Shower Caddy", "Shower Shoes", "Bathrobe", "Shower Wrap", "Hair Wrap",
			"Bathroom Rug", "Shower Curtain", "Tension Rod", "Cosmetic Organizer", // Moved from Bedroom
			"Misc"
		],
		"Laundry & Cleaning": [
			"Cleaning Bundle", "Laundry Basket", "Hamper", "Laundry Detergent",
			"Odor Eliminator", "Steamer", "Iron", // Added "Iron"
			"Ironing Board", "Vacuums", "Laundry Bag", "Broom & Dust Pan",
			"Garbage Can", "Misc"
		],
		"Electronics & Office Supplies": [
			"Television", "Laptop", "Chargers", "Flash Drive", "Hard Drive",
			"Extension Cords", "Headphones", "Printer", "Alarm Clock",
			"Bluetooth Speaker", "Personal Alarm",
			"Firestick", "Alexa/Amazon Speakers",
			"Day Planner", "Backpack", "Pens", "Pencils", "Highlighters",
			"Notebooks", "Stapler", "Scissors", "Printer Paper", "Sticky Notes",
			"Dry Erase Markers", "Desk Set", "Computer Mouse",
			"Misc"
		],
		"Decor": ["Throw Rug", "Throw Pillows", "Clip Lamps", "Desk Lamp", "Floor Lamp", "Lounge Seating", "Mirror", "Picture Frames", "String Lights", "Tension Rod", "Vanity Mirror", "Window Curtains", "Wall Safe Adhesive", "Dry Erase/Bulletin Board", "Standing Fan", "Bed Fan/Clip Fan", "Doorstopper", "Lap Desk", "Room Decor", "Faux Plants", "Floor Pillows", "Misc"],
		"Health & Personal Care": ["Hair Dryer", "Styling Tools", "Hair Removal", "First Aid Kit", "Pain Reliever", "Sewing Kit", "Exercise Equipment", "Humidifier", "Diffuser", "Diffuser Oils", "Air Purifier", "Noise Machine", "Tool Set", "Misc"],
		"Kitchen": ["Microwave", "Refrigerator", "Personal Refrigerator", "Coffee Maker", "Rice Cooker", "Cooking Tools", "Smoothie Maker", "Air Fryer", "Dishes", "Silverware", "Water Bottles", "Travel Mug", "Can Opener", "Water Filtering", "Mugs", "Snack Bundles", "Food Storage", "Pots/Pans", "Cooking Utensils", "Misc"],
		"Storage & Organization": ["Bedside Shelf/Caddy", "Closet Organization", "Shoe Storage/Racks", "Hangers", "Trunk/Storage", "Underbed Storage", "Drawers", "Over Door Organization", "Rolling Cart", "Desk Organizer", "Wall Key Organizer", "Moving Bags", "Luggage", "Misc"]
	});

	const [selectedSubCategory, setSelectedSubCategory] = useState("");

	// Add these state variables near the other state declarations
	const [users, setUsers] = useState([]);
	const [authUsers, setAuthUsers] = useState([]);
	const [userSearchQuery, setUserSearchQuery] = useState('');
	const [selectedUser, setSelectedUser] = useState(null);
	const [isUserModalOpen, setIsUserModalOpen] = useState(false);
	const [userActionLoading, setUserActionLoading] = useState(false);

	// Add these state variables
	const [userFilter, setUserFilter] = useState('all'); // 'all', 'verified', 'unverified', 'admin', 'student'
	const [userSort, setUserSort] = useState('newest'); // 'newest', 'oldest', 'alphabetical'

	// Add a new state for search input
	const [searchInput, setSearchInput] = useState('');

	// Add new state variables
	const [adminPassword, setAdminPassword] = useState("");
	const [showStripeResetConfirm, setShowStripeResetConfirm] = useState(false);
	const [userToResetStripe, setUserToResetStripe] = useState(null);

	// Add search button handler
	const handleSearch = () => {
		if (searchInput.trim().length < 3) {
			alert('Please enter at least 3 characters to search');
			return;
		}
		setSearchQuery(searchInput);
	};

	// Add these functions for user management
	const fetchAllUsers = async () => {
		try {
			// Fetch database users
			const usersSnapshot = await getDocs(collection(db, "users"));
			const dbUsers = usersSnapshot.docs.map(doc => ({
				...doc.data(),
				id: doc.id,
				source: 'database'
			}));
			setUsers(dbUsers);

			// Fetch authentication users
			const authUsersResponse = await fetch(`${SERVER_URL}/api/listUsers`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				}
			});
			const authUsersData = await authUsersResponse.json();
			setAuthUsers(authUsersData.users || []);
		} catch (error) {
			console.error("Error fetching users:", error);
		}
	};

	const handleResetUserPassword = async (email) => {
		if (window.confirm(`Are you sure you want to send a password reset email to ${email}?`)) {
			try {
				setUserActionLoading(true);
				await sendPasswordResetEmail(auth, email);
				alert(`Password reset email sent to ${email}`);
			} catch (error) {
				alert(`Error: ${error.message}`);
			} finally {
				setUserActionLoading(false);
			}
		}
	};

	const handleUpdateUserEmail = async (userId, currentEmail) => {
		const newEmail = prompt("Enter new email address:", currentEmail);
		if (newEmail && newEmail !== currentEmail) {
			try {
				setUserActionLoading(true);
				await fetch(`${SERVER_URL}/api/updateUserEmail`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						uid: userId,
						newEmail: newEmail
					})
				});
				
				// Update in Firestore
				const userRef = doc(db, "users", userId);
				await updateDoc(userRef, { email: newEmail });
				
				alert("Email updated successfully");
				fetchAllUsers();
			} catch (error) {
				alert(`Error: ${error.message}`);
			} finally {
				setUserActionLoading(false);
			}
		}
	};

	const handleDeleteUser = async (userId, email) => {
		if (window.confirm(`Are you sure you want to delete user ${email}? This action cannot be undone.`)) {
			try {
				setUserActionLoading(true);
				// Delete from Authentication
				await fetch(`${SERVER_URL}/api/deleteUser`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ uid: userId })
				});
				
				// Delete from Firestore
				await deleteDoc(doc(db, "users", userId));
				
				alert("User deleted successfully");
				fetchAllUsers();
			} catch (error) {
				alert(`Error: ${error.message}`);
			} finally {
				setUserActionLoading(false);
			}
		}
	};

	// Function to handle subcategory change
	const handleSubCategoryChange = async (itemId, newSubCategory) => {
		try {
			const itemRef = doc(db, "catalogueItems", itemId);
			await updateDoc(itemRef, { subCategory: newSubCategory });

			setCatalogueItems(
				catalogueItems.map((item) => {
					if (item.id === itemId) {
						return { ...item, subCategory: newSubCategory };
					}
					return item;
				})
			);

			// Automatically select the product
			setSelectedProducts((prevSelectedProducts) => {
				if (!prevSelectedProducts.includes(itemId)) {
					return [...prevSelectedProducts, itemId];
				}
				return prevSelectedProducts;
			});
		} catch (error) {
			console.error("Error updating subcategory:", error);
		}
	};

	useEffect(() => {
		if (isDevelopment) {
			setShowAdmin(true); // Bypass login and authorization checks in development
		} else {
			if (!currentUserData || currentUserData?.accountType !== "admin") {
				setShowLoginPopup(true);
				setShowAdmin(false);
				setTestEmail(currentUserData?.email);
			} else {
				setShowAdmin(true);
			}
		}
	}, [currentUserData]);

	useEffect(() => {
		fetchOrganizations();
		fetchCatalogueItems();
		console.warn(SERVER_URL);
	}, []);

	useEffect(() => {
		fetchCatalogueItems();
	}, [filter, sort]);

	useEffect(() => {
		const statusDocRef = doc(db, "maintenance", "status"); // Ensure this path is correct
		const unsubscribe = onSnapshot(statusDocRef, (doc) => {
			if (doc.exists()) {
				setIsMaintenance(doc.data().isMaintenanceActive); // Corrected field name
				console.log("Maintenance status set!");
			} else {
				console.log("No such document!");
			}
		});
		return () => unsubscribe(); // Cleanup subscription on unmount
	}, []); // No need to add db as a dependency since it's imported

	useEffect(() => {
		scanForUidMismatches();
	}, []);

	useEffect(() => {
		if (activeTab === 'users') {
			fetchAllUsers();
		}
	}, [activeTab]);

	const toggleMaintenance = async () => {
		console.log("Toggle Maintenance button pressed"); // Debugging line
		const password = prompt("Please retype the site password to confirm:");
		if (password === "Newtjhawk27") { // Check if the password matches
			const newMaintenanceStatus = !isMaintenance; // Toggle the maintenance status
			await updateDoc(doc(db, "maintenance", "status"), {
				isMaintenanceActive: newMaintenanceStatus,
				startTime: newMaintenanceStatus ? new Date().toLocaleString() : null, // Changed to standard language format
			});
		} else {
			alert("Incorrect password. Maintenance status not changed.");
		}
	};

	const fetchOrganizations = async () => {
		const querySnapshot = await getDocs(collection(db, "new organizations"));
		const orgs = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
		setOrganizations(orgs);
		setLoading(false);
	};

	// Adjusted fetchCatalogueItems to optionally filter and sort items
	const fetchCatalogueItems = async () => {
		let querySnapshot = await getDocs(collection(db, "catalogueItems"));
		let items = querySnapshot.docs.map((doc) => {
			const data = doc.data();
			return {
				id: doc.id,
				...data,
				subCategory: data.subCategory || "Default Subcategory" // Use default or existing subcategory
			};
		});

		// Filter items if a category is selected
		if (filter !== "all") {
			items = items.filter((item) => item.category === filter);
		}

		// Sort items based on the selected sorting method
		if (sort === "priceLowHigh") {
			items.sort((a, b) => parseFloat(a.price.replace("$", "")) - parseFloat(b.price.replace("$", "")));
		} else if (sort === "priceHighLow") {
			items.sort((a, b) => parseFloat(b.price.replace("$", "")) - parseFloat(a.price.replace("$", "")));
		}

		setCatalogueItems(items);
	};

	// Function to handle search input change
	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
		setCurrentPage(1); // Reset to first page to start filtering from the beginning
	};

	// Filter and paginate items
	const filteredItems = catalogueItems.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));

	// Calculate total pages
	const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

	// Function to change page
	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	// Calculate the items to display
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

	const handleApprove = (org) => {
		const updatedOrg = { ...org, approved: true, code: generateInstitutionCode(org.organizationType) };
		setApprovedOrgs([...approvedOrgs, updatedOrg]);
		setOrganizations(organizations.filter((o) => o.id !== org.id));
	};

	const handleDeny = (org) => {
		const updatedOrg = { ...org, approved: false };
		setDeniedOrgs([...deniedOrgs, updatedOrg]);
		setOrganizations(organizations.filter((o) => o.id !== org.id));
	};

	const uploadApproved = async () => {
		if (window.confirm("Are you sure you want to upload these entries?")) {
			try {
				// Upload each approved organization to Firestore
				for (const org of approvedOrgs) {
					await addDoc(collection(db, "approvedOrganizations"), formatOrgForUpload(org));
				}

				// Clear approved organizations
				setApprovedOrgs([]);

				// Update Firestore for each organization
				approvedOrgs.forEach((org) => {
					deleteDoc(doc(db, "new organizations", org.id));
				});
			} catch (error) {
				console.error("Error during upload", error);
			}
		}
	};

	const formatOrgForUpload = (org) => {
		return {
			name: org.name,
			domains: [org.website],
			web_pages: [org.website],
			country: org.location || "",
			alpha_two_code: org.location || "",
			"state-province": org.location || null,
			organizationCode: org.code,
			members: {},
		};
	};

	const purgeDenied = async () => {
		if (window.confirm("Are you sure you want to permanently delete these entries?")) {
			for (const org of deniedOrgs) {
				await deleteDoc(doc(db, "new organizations", org.name));
			}
			setDeniedOrgs([]);
		}
	};

	const handleEdit = (id, field, value) => {
		setOrganizations(
			organizations.map((org) => {
				if (org.id === id) {
					return { ...org, [field]: value };
				}
				return org;
			})
		);
	};

	const handleReevaluate = (org, listSetter, fromList) => {
		listSetter(fromList.filter((o) => o.id !== org.id));
		setOrganizations([...organizations, org]);
	};

	const fetchItemPreview = async () => {
		const cleanedAffiliateUrl = sanitizeAffiliateUrl(affiliateUrl);
		setAffiliateUrl(cleanedAffiliateUrl); // Update the state with the sanitized URL

		setLoadingStep("Checking for duplicates...");
		const isDuplicate = await checkForDuplicateLink(cleanedAffiliateUrl);

		if (isDuplicate) {
			setLoadingStep("This affiliate link is already in the database.");
			// Optionally, reset affiliateUrl or handle as needed
			setAffiliateUrl("");
			return; // Stop execution to prevent adding a duplicate
		}

		setLoadingStep("Fetching preview, please wait...");
		setIsFetching(true);
		setShowStillWorking(false); // Reset the "still working" message state

		// Set a timeout to show the "still working" message after 7 seconds
		const stillWorkingTimeout = setTimeout(() => {
			setShowStillWorking(true);
		}, 7000); // 7000 milliseconds = 7 seconds

		try {
			const response = await fetch(`${SERVER_URL}/fetch-preview`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ url: affiliateUrl }),
			});
			clearTimeout(stillWorkingTimeout); // Clear the timeout upon fetch completion
			if (response.ok) {
				setLoadingStep("Processing data...");
				const previewData = await response.json();
				setItemPreview(previewData);
				setLoadingStep("Preview fetched successfully.");
			} else {
				console.error("Error:", response);
				throw new Error("Failed to fetch item preview");
			}
		} catch (error) {
			setLoadingStep(`Error: ${error.message}`);
			console.error("Failed to fetch item preview:", error);
		} finally {
			setIsFetching(false);
			setShowStillWorking(false); // Ensure "still working" message is hidden after fetch
		}
	};

	const checkForDuplicateLink = async (affiliateUrl) => {
		// Assuming you're using Firestore as indicated in previous snippets
		const querySnapshot = await getDocs(query(collection(db, "catalogueItems"), where("url", "==", affiliateUrl)));
		return !querySnapshot.empty; // Returns true if a duplicate exists, false otherwise
	};

	const sanitizeAffiliateUrl = (url) => {
		// Trim leading and trailing spaces
		let cleanedUrl = url.trim();

		// Further cleaning can go here, e.g., removing or replacing specific characters
		// For example, to remove all spaces (not just leading/trailing), you could use:
		// cleanedUrl = cleanedUrl.replace(/\s+/g, '');

		return cleanedUrl;
	};

	const submitItemToDatabase = async () => {
		if (!category) {
			alert("Please select a category.");
			setCategoryError(true);
			return;
		}

		if (!selectedSubCategory) {
			alert("Please select a subcategory.");
			setCategoryError(true);
			return;
		}

		// Determine if we're using fetched data or manual entry
		const itemData = manualEntry
			? {
				name: itemName,
				description: itemDescription,
				price: itemPrice,
				image: itemImage,
				url: affiliateUrl,
				category: category,
				subCategory: selectedSubCategory,
			}
			: {
				name: itemPreview.title,
				description: itemPreview.description,
				price: itemPreview.price,
				image: itemPreview.imageUrl,
				url: affiliateUrl,
				category: category,
				subCategory: selectedSubCategory,
			};

		try {
			await addDoc(collection(db, "catalogueItems"), itemData);

			setCatalogueItems([...catalogueItems, itemData]);
			setLoadingStep("Item added successfully to the catalogue.");

			// Reset the form fields
			setAffiliateUrl("");
			setItemPreview(null);
			setCategory("");
			setSelectedSubCategory("");
			setCategoryError(false);

			fetchCatalogueItems();
		} catch (error) {
			console.error("Error adding item to catalogue:", error);
			setLoadingStep("Failed to add item to catalogue.");
		}
	};

	const [manualEntry, setManualEntry] = useState(false); // New state to toggle manual entry

	// Toggle function
	const toggleManualEntry = () => {
		setManualEntry(!manualEntry);
		// Reset item preview and manual entry fields
		setItemPreview(null);
		setItemName("");
		setItemDescription("");
		setItemPrice("");
		setItemImage("");
	};

	// Function to delete an item
	const deleteItem = async (itemId) => {
		await deleteDoc(doc(db, "catalogueItems", itemId));
		fetchCatalogueItems(); // Refetch items after deletion
	};

	const handlePriceChange = (event) => {
		setSelectedPrice(event.target.value);
	};

	const handleIndividualPriceChange = (productId, newPrice) => {
		setPriceUpdates(prev => ({ ...prev, [productId]: newPrice }));
	};

	// Function to handle product selection
	const handleSelectProduct = (productId) => {
		setSelectedProducts(prevSelectedProducts =>
			prevSelectedProducts.includes(productId)
				? prevSelectedProducts.filter(id => id !== productId)
				: [...prevSelectedProducts, productId]
		);
	};

	const [showUpdatePopup, setShowUpdatePopup] = useState(false);
	const [estimatedTime, setEstimatedTime] = useState(0);

	const updateProducts = async () => {
		if (selectedProducts.length === 0) {
			alert('Please select at least one product.');
			return;
		}

		const updates = {};
		if (selectedCategory) updates.category = selectedCategory;
		if (bulkPrice) updates.price = bulkPrice;
		if (bulkDescription) updates.description = bulkDescription;

		// Estimate time based on the number of selected products
		const estimatedTimeInSeconds = selectedProducts.length * 2; // Example: 2 seconds per product
		setEstimatedTime(estimatedTimeInSeconds);
		setShowUpdatePopup(true);

		try {
			for (const productId of selectedProducts) {
				const productRef = doc(db, "catalogueItems", productId);
				await updateDoc(productRef, updates);
			}

			alert('Products updated successfully!');
			fetchCatalogueItems(); // Refetch products to get updated data
			setSelectedProducts([]); // Clear selections
			setSelectedCategory('');
			setBulkPrice('');
			setBulkDescription('');
		} catch (error) {
			console.error('Error updating products:', error);
			alert('Error updating products');
		} finally {
			setShowUpdatePopup(false);
		}
	};

	// Add this function inside your component
	const toggleSelectAllProducts = () => {
		if (selectedProducts.length === catalogueItems.filter(item => item.category === filter).length) {
			setSelectedProducts([]);
		} else {
			setSelectedProducts(catalogueItems.filter(item => item.category === filter).map(item => item.id));
		}
	};

	// To make category dropdown red if there's an error
	const categorySelectStyle = categoryError ? { ...styles.input, borderColor: "red" } : styles.input;
	//     https://amzn.to/3UCGBby

	const handleEmailParamChange = (param, value) => {
		setEmailParams((prevParams) => ({
			...prevParams,
			[param]: value,
		}));
	};

	const handleTestEmailSubmit = async (event) => {
		event.preventDefault();
		try {
			const response = await fetch(`${SERVER_URL}/test-email`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ email: recipientEmail, emailType, ...emailParams }),
			});

			if (!response.ok) {
				throw new Error("Failed to send test email");
			}

			const result = await response.text();
			setEmailTestResult(result);
		} catch (error) {
			setEmailTestResult("Failed to send test email");
			alert("Failed to send test email! Please try again.");
		}
	};

	const scanForUidMismatches = async () => {
		const usersRef = collection(db, "users");
		const querySnapshot = await getDocs(usersRef);
		const mismatchedUsers = [];

		querySnapshot.forEach((doc) => {
			const userData = doc.data();
			const uid = userData.uid;
			const eventsUrl = userData.pages?.events;
			const registryUrl = userData.pages?.registry;

			// Only process UIDs that start with "G"
			if (uid.startsWith("G")) {
				if (
					(eventsUrl && !eventsUrl.includes(uid)) ||
					(registryUrl && !registryUrl.includes(uid))
				) {
					mismatchedUsers.push({
						firstName: userData.firstName,
						lastName: userData.lastName,
						email: userData.email,
						uid: uid,
						eventsUrl: eventsUrl || "N/A",
						registryUrl: registryUrl || "N/A",
					});
				}
			}
		});

		if (mismatchedUsers.length > 0) {
			await alertMismatchedUsers(mismatchedUsers);
			setMismatchedUsers(mismatchedUsers); // Update state to display on the admin page
		}
	};

	const alertMismatchedUsers = async (mismatchedUsers) => {
		try {
			const response = await fetch(`${SERVER_URL}/alert-mismatched-users`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ mismatchedUsers }),
			});

			if (!response.ok) {
				throw new Error("Failed to send alert email");
			}
		} catch (error) {
			console.error("Error sending alert email:", error);
		}
	};

	const toggleCatalogueVisibility = async (field) => {
		try {
			const docRef = doc(db, "maintenance", "catalogueVisibility");
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				const currentData = docSnap.data();
				const newValue = !currentData[field];
				await updateDoc(docRef, {
					[field]: newValue
				});
				setVisibilitySettings((prevSettings) => ({
					...prevSettings,
					[field]: newValue
				}));
			} else {
				// If the document doesn't exist, create it with default values
				await setDoc(docRef, {
					hidePrice: false,
					hideImages: false,
					hideAll: false
				});
				fetchVisibilitySettings(); // Fetch again to update state
			}
		} catch (error) {
			console.error("Error updating catalogue visibility:", error);
		}
	};

	const [visibilitySettings, setVisibilitySettings] = useState({
		hidePrice: false,
		hideImages: false,
		hideAll: false
	});

	const fetchVisibilitySettings = async () => {
		try {
			const docRef = doc(db, "maintenance", "catalogueVisibility");
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
				setVisibilitySettings(docSnap.data());
			}
		} catch (error) {
			console.error("Error fetching visibility settings:", error);
		}
	};

	useEffect(() => {
		fetchVisibilitySettings();
	}, []);

	// Button styles based on visibility settings
	const getButtonStyle = (field) => {
		return visibilitySettings[field] ? styles.buttonActive : styles.buttonInactive;
	};

	const handleBlogSubmit = async (e) => {
		if (e) e.preventDefault();
		try {
			const blogData = {
				title: blogTitle,
				content: blogContent,
				category: blogCategory.trim(), // Ensure category is trimmed
				imageUrl: blogImage,
				tags: blogTags,
				author: currentUserData?.email,
				createdAt: publishDate || new Date().toISOString(),
				updatedAt: lastUpdatedDate || new Date().toISOString()
			};

			console.log("Saving blog post with category:", blogData.category); // Debug log

			if (editingBlogId) {
				await updateDoc(doc(db, "blog", editingBlogId), blogData);
			} else {
				await addDoc(collection(db, "blog"), blogData);
			}

			// Reset form
			resetForm();
			// Refresh blog posts
			fetchBlogPosts();
		} catch (error) {
			console.error("Error saving blog post:", error);
			alert("Error saving blog post. Please try again.");
		}
	};

	const resetForm = () => {
		setBlogTitle("");
		setBlogContent("");
		setBlogCategory("");
		setBlogImage("");
		setBlogTags([]);
		setPublishDate("");
		setLastUpdatedDate("");
		setEditingBlogId(null);
	};

	const fetchBlogPosts = async () => {
		const querySnapshot = await getDocs(collection(db, "blog"));
		const posts = querySnapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}));
		setBlogPosts(posts);
	};

	useEffect(() => {
		fetchBlogPosts();
	}, []);

	const checkMismatchedUsers = async () => {
		const usersRef = collection(db, "users");
		const snapshot = await getDocs(usersRef);
		const mismatched = [];

		snapshot.forEach(doc => {
			const userData = doc.data();
			if (userData.email && userData.uid && userData.email.toLowerCase() !== doc.id.toLowerCase()) {
				mismatched.push({ ...userData, id: doc.id });
			}
		});

		setMismatchedUsers(mismatched);
	};

	const fixUserMismatch = async (docId) => {
		try {
			const userRef = doc(db, "users", docId);
			await updateDoc(userRef, {
				email: docId.toLowerCase()
			});
			await checkMismatchedUsers(); // Refresh the list
		} catch (error) {
			console.error("Error fixing user mismatch:", error);
		}
	};

	const sendTestEmail = async () => {
		try {
			// Implement your email sending logic here
			alert(`Test email would be sent to ${testEmail}`);
		} catch (error) {
			console.error("Error sending test email:", error);
		}
	};

	const toggleMaintenanceMode = async (enabled) => {
		try {
			const configRef = doc(db, "config", "maintenance");
			await setDoc(configRef, { enabled }, { merge: true });
			setMaintenanceMode(enabled);
		} catch (error) {
			console.error("Error toggling maintenance mode:", error);
		}
	};

	const toggleRegistration = async (disabled) => {
		try {
			const configRef = doc(db, "config", "registration");
			await setDoc(configRef, { disabled }, { merge: true });
			setDisableRegistration(disabled);
		} catch (error) {
			console.error("Error toggling registration:", error);
		}
	};

	// Add this state near the other blog states
	const [showPreview, setShowPreview] = useState(false);

	// Add this function to format the date
	const formatDate = (dateString) => {
		return new Date(dateString).toLocaleDateString();
	};

	// Add this function to handle tag input
	const handleTagInput = (e) => {
		if (e.key === 'Enter' && e.target.value) {
			e.preventDefault();
			const newTag = e.target.value.trim();
			if (!blogTags.includes(newTag)) {
				setBlogTags([...blogTags, newTag]);
			}
			e.target.value = '';
		} else if (e.key === 'Backspace' && e.target.value === '' && blogTags.length > 0) {
			setBlogTags(blogTags.slice(0, -1));
		}
	};

	const handlePresetTagClick = (tag) => {
		if (!blogTags.includes(tag)) {
			setBlogTags([...blogTags, tag]);
		}
	};

	// Add this function to get filtered and sorted users
	const getFilteredUsers = () => {
		return users
			.filter(user => {
				if (userSearchQuery) {
					const searchLower = userSearchQuery.toLowerCase();
					return (
						user.email?.toLowerCase().includes(searchLower) ||
						user.firstName?.toLowerCase().includes(searchLower) ||
						user.lastName?.toLowerCase().includes(searchLower)
					);
				}
				
				switch (userFilter) {
					case 'stripe_connected':
						return !!user.stripeAccountId;
					case 'stripe_pending':
						return !user.stripeAccountId;
					case 'admin':
						return user.accountType === 'admin';
					case 'student':
						return user.accountType === 'student';
					default:
						return true;
				}
			})
			.sort((a, b) => {
				switch (userSort) {
					case 'newest':
						return new Date(b.lastLogin || 0) - new Date(a.lastLogin || 0);
					case 'oldest':
						return new Date(a.lastLogin || 0) - new Date(b.lastLogin || 0);
					case 'alphabetical':
						return `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`);
					default:
						return 0;
				}
			});
	};

	// Add function to handle Stripe reset
	const handleStripeReset = async (user) => {
		setUserToResetStripe(user);
		setShowStripeResetConfirm(true);
	};

	const confirmStripeReset = async () => {
		if (adminPassword !== "Newtjhawk27") {
			alert("Invalid admin password");
			return;
		}

		try {
			setUserActionLoading(true);
			// Use email as the document ID instead of uid
			const userRef = doc(db, "users", userToResetStripe.email);
			
			// First verify the document exists
			const userDoc = await getDoc(userRef);
			if (!userDoc.exists()) {
				throw new Error("User document not found");
			}

			// Update the document with reset Stripe fields
			await updateDoc(userRef, {
				stripeAccountId: "", // This will remove the field
				stripeSetupComplete: false
			});

			// Also make API call to disconnect from Stripe if needed
			try {
				await axios.post(`${SERVER_URL}/disconnect-stripe-account`, {
					userId: userToResetStripe.uid,
					stripeAccountId: userToResetStripe.stripeAccountId
				});
			} catch (stripeError) {
				console.warn("Warning: Could not disconnect Stripe account:", stripeError);
				// Continue anyway as we've already reset the local data
			}

			alert("Stripe configuration reset successfully");
			
			// Refresh the users list
			fetchAllUsers();
			
		} catch (error) {
			console.error("Error resetting Stripe:", error);
			alert(`Error resetting Stripe configuration: ${error.message}`);
		} finally {
			setUserActionLoading(false);
			setShowStripeResetConfirm(false);
			setAdminPassword("");
			setUserToResetStripe(null);
		}
	};

	if (loading) {
		return <div style={styles.loading}>Loading...</div>;
	}

	return (
		<>

			{showLoginPopup && <View style={styles.popupContainer}> <Popup style={styles.popup} type="login" onClose={() => setShowLoginPopup(false)} isAdminPage={true} /> </View>}


			{/* Admin Dashboard */}
			{showAdmin && (
				<div style={styles.container}>
					<h1 style={styles.header}>Admin Dashboard</h1>

					<div style={styles.tabContainer}>
						{['users', 'organizations', 'catalogue', 'blog', 'maintenance'].map((tab) => (
							<button
								key={tab}
								onClick={() => setActiveTab(tab)}
								style={{
									...styles.tabButton,
									...(activeTab === tab ? styles.activeTab : {})
								}}
							>
								{tab.charAt(0).toUpperCase() + tab.slice(1)}
							</button>
						))}
					</div>

					<div style={styles.tabContent}>
						{activeTab === "organizations" && (
							<div style={styles.section}>


								{/* Pending Organizations Section */}
								<h2 style={styles.subHeader}>Pending Organizations</h2>
								<div style={styles.tableWrapper}>
									<table style={styles.table}>
										<thead>
											<tr>
												<th style={{
													...styles.tableHeader, 
													width: isMobile ? '150px' : '20%'
												}}>Name</th>
												<th style={{...styles.tableHeader, width: '20%'}}>Address</th>
												<th style={{...styles.tableHeader, width: '25%'}}>Website</th>
												<th style={{...styles.tableHeader, width: '15%'}}>Type</th>
												<th style={{...styles.tableHeader, width: '20%'}}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{organizations.map((org) => (
												<tr key={org.id} style={styles.tableRow}>
													<td>{org.name}</td>
													<td>{org.location}</td>
													<td>{org.website}</td>
													<td>{org.organizationType}</td>
													
													<td>
														<button onClick={() => handleApprove(org)} style={styles.buttonApprove}>
															Approve
														</button>
														<button onClick={() => handleDeny(org)} style={styles.buttonDeny}>
															Deny
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>

								{/* Approved Organizations Section */}
								<h2 style={styles.subHeader}>Approved Organizations</h2>
								<div style={styles.tableWrapper}>
									<table style={styles.table}>
										<thead>
											<tr>
												<th style={{
													...styles.tableHeader, 
													width: isMobile ? '150px' : '20%'
												}}>Name</th>
												<th style={{...styles.tableHeader, width: '15%'}}>Address</th>
												<th style={{...styles.tableHeader, width: '25%'}}>Website</th>
												<th style={{...styles.tableHeader, width: '15%'}}>Type</th>
												<th style={{...styles.tableHeader, width: '10%'}}>Code</th>
												<th style={{...styles.tableHeader, width: '15%'}}>Reevaluate</th>
											</tr>
										</thead>
										<tbody>
											{approvedOrgs.map((org) => (
												<tr key={org.id} style={styles.tableRow}>
													<td>{org.name}</td>
													<td>{org.location}</td>
													<td>{org.website}</td>
													<td>{org.organizationType}</td>
													<td>{org.code}</td>
													<td>
														<button onClick={() => handleReevaluate(org, setApprovedOrgs, approvedOrgs)} style={styles.buttonReevaluate}>
															Reevaluate
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
								<button onClick={uploadApproved} style={styles.buttonApprove}>
									Upload Approved
								</button>

								{/* Denied Organizations Section */}
								<h2 style={styles.subHeader}>Denied Organizations</h2>
								<div style={styles.tableWrapper}>
									<table style={styles.table}>
										<thead>
											<tr>
												<th style={{
													...styles.tableHeader, 
													width: isMobile ? '150px' : '25%'
												}}>Name</th>
												<th style={{...styles.tableHeader, width: '20%'}}>Address</th>
												<th style={{...styles.tableHeader, width: '25%'}}>Website</th>
												<th style={{...styles.tableHeader, width: '15%'}}>Type</th>
												<th style={{...styles.tableHeader, width: '15%'}}>Reevaluate</th>
											</tr>
										</thead>
										<tbody>
											{deniedOrgs.map((org) => (
												<tr key={org.id} style={styles.tableRow}>
													<td>{org.name}</td>
													<td>{org.location}</td>
													<td>{org.website}</td>
													<td>{org.organizationType}</td>
													<td>
														<button onClick={() => handleReevaluate(org, setDeniedOrgs, deniedOrgs)} style={styles.buttonReevaluate}>
															Reevaluate
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
								<button onClick={purgeDenied} style={styles.buttonDeny}>
									Purge Denied
								</button>
							</div>
						)}

						{activeTab === "catalogue" && (
							<div style={styles.section}>
								{/* Add Affiliates Section */}
								<h2 style={styles.subHeader}>Affiliate Link Details</h2>
								<div>
									<h4>Toggle Catalogue Visibility</h4>
									<div style={styles.visibilityButtonContainer}>
										<button 
											onClick={() => toggleCatalogueVisibility('hidePrice')} 
											style={{
												...styles.visibilityButton,
												...(visibilitySettings.hidePrice && styles.visibilityButtonActive)
											}}
										>
											{visibilitySettings.hidePrice ? "Show Price" : "Hide Price"}
										</button>
										<button 
											onClick={() => toggleCatalogueVisibility('hideImages')} 
											style={{
												...styles.visibilityButton,
												...(visibilitySettings.hideImages && styles.visibilityButtonActive)
											}}
										>
											{visibilitySettings.hideImages ? "Show Images" : "Hide Images"}
										</button>
										<button 
											onClick={() => toggleCatalogueVisibility('hideAll')} 
											style={{
												...styles.visibilityButton,
												...(visibilitySettings.hideAll && styles.visibilityButtonActive)
											}}
										>
											{visibilitySettings.hideAll ? "Show All" : "Hide All"}
										</button>
									</div>
								</div>









								{/* Add to Catalogue Section */}
								<div>
									<input type="text" placeholder="Affiliate URL" value={affiliateUrl} onChange={(e) => setAffiliateUrl(e.target.value)} style={styles.input} />
									<div>
										<select
											value={category}
											onChange={(e) => {
												setCategory(e.target.value);
												setSelectedSubCategory("");
											}}
											style={categorySelectStyle}
										>
											<option value="">Select a Category</option>
											{categories.map((cat) => (
												<option key={cat} value={cat}>
													{cat}
												</option>
											))}
										</select>

										<select
											value={selectedSubCategory}
											onChange={(e) => setSelectedSubCategory(e.target.value)}
											style={styles.input}
										>
											<option value="">Select a Subcategory</option>
											{subCategories[category]?.map((subCat) => (
												<option key={subCat} value={subCat}>
													{subCat}
												</option>
											))}
										</select>
									</div>

									{!manualEntry ? (
										<>
											<button onClick={fetchItemPreview} style={styles.buttonApprove}>
												Fetch Preview
											</button>
											<button onClick={toggleManualEntry} style={styles.buttonDeny}>
												Enter Details Manually
											</button>
										</>
									) : (
										<>
											<input type="text" placeholder="Item Name" value={itemName} onChange={(e) => setItemName(e.target.value)} style={styles.input} />
											<textarea placeholder="Item Description" value={itemDescription} onChange={(e) => setItemDescription(e.target.value)} style={styles.input} />
											<input type="text" placeholder="Item Price" value={itemPrice} onChange={(e) => setItemPrice(e.target.value)} style={styles.input} />
											<input type="text" placeholder="Item Image URL" value={itemImage} onChange={(e) => setItemImage(e.target.value)} style={styles.input} />
											<button onClick={toggleManualEntry} style={styles.buttonDeny}>
												Fetch Preview Instead
											</button>
											<button
												onClick={submitItemToDatabase}
												style={category && selectedSubCategory && itemName && itemDescription && itemPrice && itemImage ? styles.buttonApprove : styles.buttonDisabled}
												disabled={!category || !selectedSubCategory || !itemName || !itemDescription || !itemPrice || !itemImage}
											>
												Add to Catalogue
											</button>
										</>
									)}
									{!isFetching && itemPreview && (
										<button
											onClick={submitItemToDatabase}
											style={category && selectedSubCategory ? styles.buttonApprove : styles.buttonDisabled}
											disabled={!category || !selectedSubCategory}
										>
											Add to Catalogue
										</button>
									)}

									{loadingStep && <div style={{ padding: "10px", backgroundColor: "#f0f0f0", margin: "10px 0", borderRadius: "5px" }}>{loadingStep}</div>}
									{showStillWorking && <div style={{ padding: "10px", backgroundColor: "#f0f0f0", margin: "10px 0", borderRadius: "5px" }}>Still working...</div>}
								</div>

								{itemPreview && (
									<div>
										<h3>Item Preview:</h3>
										<img src={itemPreview.imageUrl} alt="Preview" style={{ width: "250px", height: "250px" }} />
										<p>Name: {itemPreview.title}</p>
										<p>Description: {itemPreview.description}</p>
										<p>Price: {itemPreview.price}</p>
										<button onClick={submitItemToDatabase} style={styles.buttonApprove}>
											Add to Catalogue
										</button>
									</div>
								)}

								{/* Catalogue Items Section with delete button */}
								<h2 style={styles.subHeader}>Catalogue Items</h2>

								{/* Search Bar for Catalogue Items */}


								{/* UI for selecting filters and sorting methods */}
								<select
									value={filter}
									onChange={(e) => {
										setFilter(e.target.value);
										setCurrentPage(1);
									}}>
									<option value="all">All Categories</option>
									{categories.map((category) => (
										<option key={category} value={category}>
											{category}
										</option>
									))}
								</select>

								<select value={sort} onChange={(e) => setSort(e.target.value)}>
									<option value="none">No Sorting</option>
									<option value="priceLowHigh">Price: Low to High</option>
									<option value="priceHighLow">Price: High to Low</option>
								</select>

								{/* Pagination Controls */}
								<div style={styles.paginationContainer}>
									{Array.from({ length: totalPages }, (_, index) => (
										<button
											key={index + 1}
											style={currentPage === index + 1 ? styles.activePage : styles.pageButton}
											onClick={() => handlePageChange(index + 1)}
										>
											{index + 1}
										</button>
									))}
								</div>

								{/* Bulk Edit Section */}
								<div style={styles.bulkEdit}>
									<select
										value={selectedCategory}
										onChange={(e) => setSelectedCategory(e.target.value)}
										style={styles.input}
									>
										<option value="">Change Category</option>
										{categories.map((category) => (
											<option key={category} value={category}>{category}</option>
										))}
									</select>

									<select
										value={selectedSubCategory}
										onChange={(e) => setSelectedSubCategory(e.target.value)}
										style={styles.input}
									>
										<option value="">Select a Subcategory</option>
										{subCategories[selectedCategory]?.map((subCat) => (
											<option key={subCat} value={subCat}>
												{subCat}
											</option>
										))}
									</select>

									<input
										type="text"
										placeholder="New Price"
										value={bulkPrice}
										onChange={(e) => setBulkPrice(e.target.value)}
										style={styles.input}
									/>
									<textarea
										placeholder="New Description"
										value={bulkDescription}
										onChange={(e) => setBulkDescription(e.target.value)}
										style={styles.input}
									/>
									<button onClick={toggleSelectAllProducts} style={styles.buttonApprove}>Select All in Category</button>
									<button onClick={updateProducts} style={styles.buttonApprove}>Apply Changes</button>
								</div>

								{/* Displaying Items */}
								<View style={{ flexDirection: 'row', alignItems: 'center', margin: 10 }}>
									<TextInput
										style={[styles.input, { flex: 1, marginRight: 10 }]}
										placeholder="Search items..."
										value={searchInput}
										onChangeText={setSearchInput}
									/>
									<Button 
										title="Search" 
										onPress={handleSearch}
										disabled={searchInput.trim().length < 3}
									/>
								</View>
								<table style={styles.table}>
									<thead style={styles.tableHeader}>
										<tr>
											<th>Select</th>
											<th>Image</th>
											<th>Item Name</th>
											<th>URL</th>
											<th>Description</th>
											<th>Price</th>
											<th>Category</th>
											<th>Subcategory</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{currentItems.map((item) => (
											<tr key={item.id} style={styles.tableRow}>
												<td>
													<input
														type="checkbox"
														checked={selectedProducts.includes(item.id)}
														onChange={() => handleSelectProduct(item.id)}
													/>
												</td>
												<td>
													<img src={item.image} alt={item.name} style={{ width: "100px", height: "100px" }} />
												</td>
												<td>{item.name}</td>
												<td>{item.url}</td>
												<td>{item.description.length > 50 ? `${item.description.substring(0, 50)}...` : item.description}</td>
												<td>{item.price.includes("$") ? item.price : `$${item.price}`}</td>
												<td>{item.category}</td>
												<td>
													<select
														value={item.subCategory || ""}
														onChange={(e) => handleSubCategoryChange(item.id, e.target.value)}
														style={styles.input}
													>
														<option value="">Select a Subcategory</option>
														{subCategories[item.category]?.map((subCat) => (
															<option key={subCat} value={subCat}>
																{subCat}
															</option>
														))}
													</select>
												</td>
												<td>
													<button
														onClick={() => deleteItem(item.id)}
														style={styles.buttonDeny}
													>
														Delete
													</button>
													<button
														onClick={() => window.open(item.url, '_blank')}
														style={styles.buttonApprove}
													>
														See Product
													</button>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						)}

						{activeTab === "blog" && (
							<div style={styles.section}>
								<div style={styles.blogSection}>
									{/* Blog Form */}
									<div style={styles.blogForm}>
										<h3>{editingBlogId ? 'Edit Blog Post' : 'Create Blog Post'}</h3>
										<form onSubmit={handleBlogSubmit}>
											<input
												type="text"
												placeholder="Blog Title"
												value={blogTitle}
												onChange={(e) => setBlogTitle(e.target.value)}
												style={styles.input}
												required
											/>
											
											<select 
												value={blogCategory}
												onChange={(e) => setBlogCategory(e.target.value)}
												style={styles.input}
												required
											>
												<option value="">Select Category</option>
												{blogCategories.map(cat => (
													<option key={cat} value={cat}>{cat}</option>
												))}
											</select>

											<input
												type="text"
												placeholder="Image URL"
												value={blogImage}
												onChange={(e) => setBlogImage(e.target.value)}
												style={styles.input}
												required
											/>

											<div style={styles.dateInputContainer}>
												<div style={styles.dateField}>
													<label style={styles.dateLabel}>Publish Date:</label>
													<input
														type="datetime-local"
														value={publishDate ? new Date(publishDate).toISOString().slice(0, 16) : ""}
														onChange={(e) => setPublishDate(new Date(e.target.value).toISOString())}
														style={styles.dateInput}
														required
													/>
												</div>
												<div style={styles.dateField}>
													<label style={styles.dateLabel}>Last Updated:</label>
													<input
														type="datetime-local"
														value={lastUpdatedDate ? new Date(lastUpdatedDate).toISOString().slice(0, 16) : ""}
														onChange={(e) => setLastUpdatedDate(new Date(e.target.value).toISOString())}
														style={styles.dateInput}
														required
													/>
												</div>
											</div>

											<textarea
												placeholder="Blog content will appear here..."
												value={blogContent}
												onChange={(e) => setBlogContent(e.target.value)}
												style={{...styles.input, minHeight: '300px'}}
												required
											/>

											{/* Update the tag input section in the blog form */}
											<div style={styles.tagSection}>
												<label style={styles.tagSectionLabel}>Tags</label>
												<div style={styles.presetTagsContainer}>
													{presetBlogTags.map((tag) => (
														<button
															key={tag}
															type="button"
															onClick={() => handlePresetTagClick(tag)}
															style={{
																...styles.presetTagButton,
																...(blogTags.includes(tag) ? styles.presetTagButtonSelected : {})
															}}
														>
															{tag}
														</button>
													))}
												</div>
												<div style={styles.tagList}>
													{blogTags.map((tag, index) => (
														<span key={index} style={styles.tagPill}>
															{tag}
															<button 
																type="button"
																onClick={() => setBlogTags(blogTags.filter((_, i) => i !== index))}
																style={styles.tagRemove}
															>
																×
															</button>
														</span>
													))}
												</div>
											</div>

											<div style={styles.buttonRow}>
												<button 
													type="submit"
													style={styles.primaryButton}
												>
													{editingBlogId ? 'Update Post' : 'Create Post'}
												</button>
												{editingBlogId && (
													<button 
														type="button"
														onClick={() => {
															setEditingBlogId(null);
															resetForm();
														}}
														style={{...styles.primaryButton, backgroundColor: '#f44336'}}
													>
														Cancel Edit
													</button>
												)}
											</div>
										</form>
									</div>

									{/* Live Preview */}
									<div style={styles.previewContainer}>
										<h2>Live Preview</h2>
										<div style={styles.blogPreview}>
											{blogImage && (
												<img src={blogImage} alt={blogTitle} style={styles.previewImage} />
											)}
											<h1 style={styles.previewTitle}>{blogTitle || 'Blog Title'}</h1>
											<div style={styles.previewMeta}>
												<span>{blogCategory || 'Category'}</span>
												<span>{new Date().toLocaleDateString()}</span>
											</div>
											<div style={styles.previewContent}>
												{blogContent || 'Blog content will appear here...'}
											</div>
											<div style={styles.previewTags}>
												{blogTags.map((tag, index) => (
													<span key={index} style={styles.tag}>{tag}</span>
												))}
											</div>
										</div>
									</div>
								</div>

								{/* Existing Posts Section */}
								<div style={styles.blogList}>
									<h3>Existing Posts</h3>
									{blogPosts.map(post => (
										<div key={post.id} style={styles.blogPost}>
											<h4>{post.title}</h4>
											<p>Category: {post.category}</p>
											<p>Published: {new Date(post.createdAt).toLocaleString()}</p>
											<p>Last Updated: {new Date(post.updatedAt).toLocaleString()}</p>
											<div style={styles.blogActions}>
												<button
													onClick={() => {
														setEditingBlogId(post.id);
														setBlogTitle(post.title);
														setBlogContent(post.content);
														setBlogCategory(post.category);
														setBlogImage(post.imageUrl);
														setBlogTags(post.tags || []);
														setPublishDate(post.createdAt);
														setLastUpdatedDate(post.updatedAt);
													}}
													style={styles.buttonEdit}
												>
													Edit
												</button>
												<button
													onClick={async () => {
														if (window.confirm("Are you sure you want to delete this post?")) {
															await deleteDoc(doc(db, "blog", post.id));
															fetchBlogPosts();
														}
													}}
													style={styles.buttonDeny}
												>
													Delete
												</button>
											</div>
										</div>
									))}
								</div>
							</div>
						)}

						{activeTab === "maintenance" && (
							<div style={styles.section}>
								<div style={styles.maintenanceTools}>
			



									{/* Maintenance Section */}
									<div style={styles.maintenanceSection}>
										<div>
											<h2 style={styles.subHeader}>Maintenance Status</h2>
											{isMaintenance && (
												<div>
													<p>Maintenance Started At: {new Date().toLocaleString()}</p>
												</div>
											)}
											<button style={isMaintenance ? styles.buttonDeny : styles.buttonApprove} onClick={toggleMaintenance}>{isMaintenance ? "End Maintenance" : "Start Maintenance"}</button>
										</div>
										
										<div style={styles.maintenanceToggles}>
											<div style={styles.toggleItem}>
												<label>
													<input
														type="checkbox"
														checked={maintenanceMode}
														onChange={(e) => toggleMaintenanceMode(e.target.checked)}
													/>
													Maintenance Mode
												</label>
											</div>
											<div style={styles.toggleItem}>
												<label>
													<input
														type="checkbox"
														checked={disableRegistration}
														onChange={(e) => toggleRegistration(e.target.checked)}
													/>
													Disable New Registrations
												</label>
											</div>
										</div>
									</div>





									<div style={styles.maintenanceSection}>
										{/* Email Test Form */}
										<div id="email-test-form">
											<h2>Test Emails</h2>
											<form onSubmit={handleTestEmailSubmit}>
												<label htmlFor="recipientEmail">Recipient Email:</label>
												<input
													type="email"
													id="recipientEmail"
													value={recipientEmail}
													placeholder="Enter recipient's email address"
													onChange={(e) => setRecipientEmail(e.target.value)}
													required
													style={styles.input}
												/>

												<select
													id="emailType"
													value={emailType}
													onChange={(e) => setEmailType(e.target.value)}
													required
													style={styles.input}
												>
													<option style={styles.option} value="genericWelcome">Generic Welcome Email</option>
													<option style={styles.option} value="graduateWelcome">Graduate Welcome Email</option>
													<option style={styles.option} value="contribution">Contribution Email</option>
													<option style={styles.option} value="purchaseConfirmation">Purchase Confirmation Email</option>
													<option style={styles.option} value="yearbookMessage">Yearbook Message Notification</option>
													<option style={styles.option} value="accessRequest">Access Request Email</option>
													<option style={styles.option} value="goalReached">Goal Reached Email</option>
													<option style={styles.option} value="ecard">E-Card Email</option>
													<option style={styles.option} value="invitation">Invitation Email</option>
													<option style={styles.option} value="mismatchedUsersAlert">Mismatched Users Alert</option>
													{/* Add other email types here as needed */}
												</select>

												{/* Conditional input fields based on email type */}
												{emailType === "genericWelcome" && (
													<>
														<input
															type="text"
															placeholder="First Name"
															onChange={(e) => handleEmailParamChange("firstName", e.target.value)}
															style={styles.input}
														/>
													</>
												)}

												{emailType === "graduateWelcome" && (
													<>
														<input
															type="text"
															placeholder="First Name"
															onChange={(e) => handleEmailParamChange("firstName", e.target.value)}
															style={styles.input}
														/>
													</>
												)}

												{emailType === "contribution" && (
													<>
														<input
															type="text"
															placeholder="Contribution Amount"
															onChange={(e) => handleEmailParamChange("contributionAmount", e.target.value)}
															style={styles.input}
														/>
														<input
															type="text"
															placeholder="Contributor Name"
															onChange={(e) => handleEmailParamChange("contributorName", e.target.value)}
															style={styles.input}
														/>
													</>
												)}

												{emailType === "purchaseConfirmation" && (
													<>
														<input
															type="text"
															placeholder="Order ID"
															onChange={(e) => handleEmailParamChange("orderId", e.target.value)}
															style={styles.input}
														/>
														<input
															type="text"
															placeholder="Purchase Details"
															onChange={(e) => handleEmailParamChange("purchaseDetails", e.target.value)}
															style={styles.input}
														/>
													</>
												)}

												{emailType === "yearbookMessage" && (
													<>
														<input
															type="text"
															placeholder="Page Owner"
															onChange={(e) => handleEmailParamChange("pageOwner", e.target.value)}
															style={styles.input}
														/>
														<input
															type="text"
															placeholder="Message Content"
															onChange={(e) => handleEmailParamChange("messageContent", e.target.value)}
															style={styles.input}
														/>
													</>
												)}

												{emailType === "accessRequest" && (
													<>
														<input
															type="text"
															placeholder="Page Owner"
															onChange={(e) => handleEmailParamChange("pageOwner", e.target.value)}
															style={styles.input}
														/>
														<input
															type="text"
															placeholder="Requester"
															onChange={(e) => handleEmailParamChange("requester", e.target.value)}
															style={styles.input}
														/>
													</>
												)}

												{emailType === "goalReached" && (
													<>
														<input
															type="text"
															placeholder="Page Owner Name"
															onChange={(e) => handleEmailParamChange("pageOwnerName", e.target.value)}
															style={styles.input}
														/>
														<input
															type="text"
															placeholder="Goal Amount"
															onChange={(e) => handleEmailParamChange("goalAmount", e.target.value)}
															style={styles.input}
														/>
													</>
												)}

												{emailType === "ecard" && (
													<>
														<input
															type="text"
															placeholder="Sender Name"
															onChange={(e) => handleEmailParamChange("senderName", e.target.value)}
															style={styles.input}
														/>
														<input
															type="text"
															placeholder="Message"
															onChange={(e) => handleEmailParamChange("message", e.target.value)}
															style={styles.input}
														/>
													</>
												)}

												{emailType === "invitation" && (
													<>
														<input
															type="text"
															placeholder="Event Name"
															onChange={(e) => handleEmailParamChange("eventName", e.target.value)}
															style={styles.input}
														/>
														<input
															type="text"
															placeholder="Invitation Details"
															onChange={(e) => handleEmailParamChange("invitationDetails", e.target.value)}
															style={styles.input}
														/>
													</>
												)}

												{emailType === "mismatchedUsersAlert" && (
													<>
														<input
															type="text"
															placeholder="Mismatched Users List"
															onChange={(e) => handleEmailParamChange("mismatchedUsersList", e.target.value)}
															style={styles.input}
														/>
													</>
												)}

												<button type="submit" style={styles.buttonApprove}>Send Test Email</button>
											</form>
											{emailTestResult && <div id="emailTestResult">{emailTestResult}</div>}
										</div>
									</div>


									<div style={styles.maintenanceSection}>
										{/* Mismatched Users Section */}
										<h2 style={styles.subHeader}>Mismatched Users</h2>
										<table style={styles.table}>
											<thead style={styles.tableHeader}>
												<tr>
													<th>First Name</th>
													<th>Last Name</th>
													<th>Email</th>
													<th>UID</th>
													<th>Events URL</th>
													<th>Registry URL</th>
												</tr>
											</thead>
											<tbody>
												{mismatchedUsers.map((user) => (
													<tr key={user.uid} style={styles.tableRow}>
														<td>{user.firstName}</td>
														<td>{user.lastName}</td>
														<td>{user.email}</td>
														<td>{user.uid}</td>
														<td>{user.eventsUrl}</td>
														<td>{user.registryUrl}</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>

									<div style={styles.section}>
										<h3>SMS Testing</h3>
										<input
											type="tel"
											placeholder="Enter test phone number"
											value={testPhone}
											onChange={(e) => setTestPhone(e.target.value)}
											style={styles.input}
										/>
										<select
											value={smsType}
											onChange={(e) => setSmsType(e.target.value)}
											style={styles.input}
										>
											<option value="welcome">Welcome Message</option>
											<option value="invitation">Invitation Message</option>
											<option value="yearbook">Yearbook Notification</option>
										</select>
										<button
											onClick={async () => {
												try {
													const response = await fetch(`${SERVER_URL}/test-sms`, {
														method: "POST",
														headers: {
															"Content-Type": "application/json",
														},
														body: JSON.stringify({
															phone: testPhone,
															messageType: smsType,
														}),
													});
													const result = await response.text();
													setSmsTestResult(result);
												} catch (error) {
													setSmsTestResult("Failed to send test SMS");
												}
											}}
											style={styles.button}
										>
											Send Test SMS
										</button>
										{smsTestResult && (
											<p style={styles.resultMessage}>{smsTestResult}</p>
										)}
									</div>
								</div>
							</div>
						)}

						{activeTab === "users" && (
							<div style={styles.section}>
								<div style={styles.sectionHeader}>
									<h2 style={styles.subHeader}>User Management</h2>
									<div style={styles.userStats}>
										<div style={styles.statCard}>
											<span style={styles.statNumber}>{users.length}</span>
											<span style={styles.statLabel}>Total Users</span>
										</div>
										<div style={styles.statCard}>
											<span style={styles.statNumber}>
												{users.filter(user => user.emailVerified).length}
											</span>
											<span style={styles.statLabel}>Verified Users</span>
										</div>
										<div style={styles.statCard}>
											<span style={styles.statNumber}>
												{users.filter(user => user.accountType === 'admin').length}
											</span>
											<span style={styles.statLabel}>Admins</span>
										</div>
									</div>
								</div>

								<div style={styles.controlsContainer}>
									<div style={styles.searchContainer}>
										<input
											type="text"
											placeholder="Search users..."
											value={userSearchQuery}
											onChange={(e) => setUserSearchQuery(e.target.value)}
											style={styles.searchInput}
										/>
									</div>

									<div style={styles.filterContainer}>
										<select 
											value={userFilter}
											onChange={(e) => setUserFilter(e.target.value)}
											style={styles.select}
										>
											<option value="all">All Users</option>
											<option value="stripe-setup">Stripe Setup</option>
											<option value="stripe-pending">Stripe Pending</option>
											<option value="admin">Admins Only</option>
											<option value="student">Students Only</option>
										</select>

										<select 
											value={userSort}
											onChange={(e) => setUserSort(e.target.value)}
											style={styles.select}
										>
											<option value="newest">Newest First</option>
											<option value="oldest">Oldest First</option>
											<option value="alphabetical">Alphabetical</option>
										</select>
									</div>
								</div>

								<div style={styles.tableContainer}>
									<table style={styles.table}>
										<thead>
											<tr>
												<th style={styles.tableHeader}>Email</th>
												<th style={styles.tableHeader}>Name</th>
												<th style={styles.tableHeader}>Account Type</th>
												<th style={styles.tableHeader}>Stripe Status</th>
												<th style={styles.tableHeader}>Privacy Status</th>
												<th style={styles.tableHeader}>Actions</th>
											</tr>
										</thead>
										<tbody>
											{getFilteredUsers().map(user => (
												<tr key={user.id} style={styles.tableRow}>
													<td style={styles.tableCell}>{user.email}</td>
													<td style={styles.tableCell}>{`${user.firstName || ''} ${user.lastName || ''}`}</td>
													<td style={styles.tableCell}>
														<span style={{
															color: user.accountType === 'admin' ? '#2c6e49' : '#64748b',
															fontWeight: 'bold'
														}}>
															{user.accountType || 'user'}
														</span>
													</td>
													<td style={styles.tableCell}>
														<span style={{
															color: user.stripeAccountId ? '#4ade80' : '#fbbf24',
															fontWeight: 'bold'
														}}>
															{user.stripeAccountId ? 'Setup Complete' : 'Pending Setup'}
														</span>
													</td>
													<td style={styles.tableCell}>
														<span style={{
															color: user.isFriendsListPrivate ? '#ef4444' : '#4ade80',
															fontWeight: 'bold'
														}}>
															{user.isFriendsListPrivate ? 'Private' : 'Public'}
														</span>
													</td>
													<td style={styles.tableCell}>
														<div style={styles.actionButtons}>
															<button
																onClick={() => handleResetUserPassword(user.email)}
																style={styles.actionButton}
																disabled={userActionLoading}
															>
																Reset Password
															</button>
															<button
																onClick={() => handleUpdateUserEmail(user.id, user.email)}
																style={styles.actionButton}
																disabled={userActionLoading}
															>
																Update Email
															</button>
															<button
																onClick={() => handleDeleteUser(user.id, user.email)}
																style={{...styles.actionButton, backgroundColor: '#fee2e2', color: '#ef4444'}}
																disabled={userActionLoading}
															>
																Delete
															</button>
															<button
																onClick={() => handleStripeReset(user)}
																style={{...styles.actionButton, color: '#ef4444'}}
																disabled={userActionLoading}
															>
																Reset Stripe
															</button>
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						)}
					</div>
				</div>
			)}

			{/* Login Screen */}
			{!showAdmin && (
				<>
					<div style={styles.loading}>
						You are not authorized to visit this page. Please contact the site manager or login <a href="mytassel.com">here</a>.
					</div>
				</>
			)}

			{/* Add Stripe Reset Confirmation Modal */}
			{showStripeResetConfirm && (
				<div style={styles.modal}>
					<div style={styles.modalContent}>
						<h3>Confirm Stripe Reset</h3>
						<p>Enter admin password to reset Stripe configuration for {userToResetStripe?.email}</p>
						<input
							type="password"
							value={adminPassword}
							onChange={(e) => setAdminPassword(e.target.value)}
							placeholder="Admin Password"
							style={styles.input}
						/>
						<div style={styles.modalButtons}>
							<button
								onClick={confirmStripeReset}
								style={styles.button}
								disabled={userActionLoading}
							>
								Confirm Reset
							</button>
							<button
								onClick={() => {
									setShowStripeResetConfirm(false);
									setAdminPassword("");
									setUserToResetStripe(null);
								}}
								style={{...styles.button, backgroundColor: '#ef4444'}}
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

const styles = StyleSheet.create({
	container: {
		padding: isMobile ? 15 : isTablet ? 25 : 40,
		backgroundColor: '#f8fafc',
		minHeight: '100vh',
		maxWidth: '100%',
		overflow: 'hidden',
	},
	header: {
		fontSize: isMobile ? 28 : isTablet ? 36 : 42,
		color: '#2c6e49',
		marginBottom: isMobile ? 20 : isTablet ? 30 : 40,
		fontFamily: 'Orpheus Pro',
		fontWeight: '600',
		textAlign: 'center',
	},
	tabContainer: {
		display: 'flex',
		flexDirection: isMobile ? 'column' : 'row',
		gap: isMobile ? 10 : isTablet ? 20 : 30,
		marginBottom: isMobile ? 25 : isTablet ? 35 : 50,
		backgroundColor: 'white',
		padding: isMobile ? 10 : 15,
		borderRadius: 12,
		boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		maxWidth: '100%',
		overflow: 'auto',
	},
	tabButton: {
		padding: '16px 32px',
		backgroundColor: 'transparent',
		border: 'none',
		cursor: 'pointer',
		fontSize: 20,
		color: '#64748b',
		borderRadius: 8,
		transition: 'all 0.2s ease',
		whiteSpace: 'nowrap',
		textAlign: 'center',
		flex: '1 1 auto',
		maxWidth: '200px',
	},
	activeTab: {
		color: '#2c6e49',
		backgroundColor: '#f0fdf4',
		fontWeight: '600',
	},
	tabContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
	},
	section: {
		backgroundColor: 'white',
		padding: isMobile ? 15 : isTablet ? 25 : 40,
		borderRadius: 16,
		marginBottom: isMobile ? 20 : isTablet ? 30 : 40,
		boxShadow: '0 4px 6px rgba(0,0,0,0.05)',
		width: '100%',
	},
	subHeader: {
		fontSize: 28,
		color: '#2c6e49',
		marginBottom: 30,
		fontFamily: 'Orpheus Pro',
		fontWeight: '600',
	},
	tableWrapper: {
		width: '100%',
		overflowX: 'auto',
		padding: isMobile ? 5 : '10px',
		marginBottom: '20px',
	},
	table: {
		width: '100%',
		borderCollapse: 'separate',
		borderSpacing: '0 8px',
		marginTop: 25,
		tableLayout: 'fixed',
		minWidth: isMobile ? 800 : 'auto', // Ensures table doesn't get too squeezed on mobile
	},
	tableHeader: {
		backgroundColor: '#f8fafc',
		padding: isMobile ? '10px' : '15px 20px',
		textAlign: 'left',
		fontSize: isMobile ? 14 : 16,
		color: '#475569',
		fontWeight: '600',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	tableRow: {
		backgroundColor: 'white',
		boxShadow: '0 2px 4px rgba(0,0,0,0.02)',
		transition: 'transform 0.2s ease',
	},
	tableCell: {
		padding: isMobile ? '10px' : '15px 20px',
		fontSize: isMobile ? 14 : 16,
		color: '#334155',
		whiteSpace: 'normal',
		wordBreak: 'break-word',
		verticalAlign: 'top',
	},
	input: {
		width: '95%', // Slightly reduced width to prevent overflow
		padding: '12px 16px',
		margin: '4px',
		border: '2px solid #e2e8f0',
		borderRadius: 8,
		fontSize: 14,
		color: '#334155',
		backgroundColor: '#f8fafc',
		transition: 'all 0.2s ease',
	},
	buttonApprove: {
		backgroundColor: '#2c6e49',
		color: 'white',
		padding: '20px 28px',
		border: 'none',
		borderRadius: 8,
		cursor: 'pointer',
		marginRight: 15,
		fontSize: 18,
		fontWeight: '500',
		transition: 'transform 0.2s ease, background-color 0.2s ease',
		minWidth: 120,
		textAlign: 'center',
		boxShadow: '0 2px 4px rgba(44, 110, 73, 0.2)',
		':hover': {
			backgroundColor: '#235c3d',
			transform: 'translateY(-2px)',
		},
	},
	buttonDeny: {
		backgroundColor: '#ef4444',
		color: 'white',
		padding: '20px 28px',
		border: 'none',
		borderRadius: 8,
		cursor: 'pointer',
		fontSize: 18,
		fontWeight: '500',
		transition: 'transform 0.2s ease, background-color 0.2s ease',
		minWidth: 120,
		textAlign: 'center',
		boxShadow: '0 2px 4px rgba(239, 68, 68, 0.2)',
		':hover': {
			backgroundColor: '#dc2626',
			transform: 'translateY(-2px)',
		},
	},
	buttonEdit: {
		backgroundColor: '#eab308',
		color: 'white',
		padding: '20px 28px',
		border: 'none',
		borderRadius: 8,
		cursor: 'pointer',
		marginRight: 15,
		fontSize: 18,
		fontWeight: '500',
		transition: 'transform 0.2s ease, background-color 0.2s ease',
		minWidth: 120,
		textAlign: 'center',
		boxShadow: '0 2px 4px rgba(234, 179, 8, 0.2)',
		':hover': {
			backgroundColor: '#ca8a04',
			transform: 'translateY(-2px)',
		},
	},
	maintenanceSection: {
		marginTop: 40,
		padding: 30,
		backgroundColor: 'white',
		borderRadius: 12,
		boxShadow: '0 4px 6px rgba(0,0,0,0.05)',
	},
	maintenanceToggles: {
		marginTop: 25,
		display: 'flex',
		flexDirection: 'column',
		gap: 20,
	},
	toggleItem: {
		flexDirection: 'row',
		alignItems: 'center',
		padding: '16px 20px',
		backgroundColor: '#f8fafc',
		borderRadius: 8,
		marginBottom: 15,
	},
	toggleLabel: {
		marginLeft: 15,
		fontSize: 16,
		color: '#334155',
	},
	blogSection: {
		display: 'grid',
		gridTemplateColumns: isMobile ? '1fr' : isTablet ? '1fr' : '1fr 1fr',
		gap: isMobile ? 20 : 40,
		padding: isMobile ? 12 : 24,
	},
	blogForm: {
		backgroundColor: 'white',
		padding: isMobile ? 15 : 30,
		borderRadius: 12,
		boxShadow: '0 4px 6px rgba(0,0,0,0.05)',
		height: 'fit-content',
		position: isMobile ? 'relative' : 'sticky',
		top: 24,
		width: '100%',
	},
	previewContainer: {
		backgroundColor: 'white',
		padding: isMobile ? 15 : 30,
		borderRadius: 12,
		boxShadow: '0 4px 6px rgba(0,0,0,0.05)',
		height: 'fit-content',
		position: isMobile ? 'relative' : 'sticky',
		top: 24,
		display: isMobile ? 'none' : 'block', // Hide preview on mobile
	},
	blogList: {
		marginTop: isMobile ? 20 : 40,
		width: '100%',
	},
	blogPost: {
		backgroundColor: 'white',
		padding: isMobile ? 15 : 30,
		borderRadius: 12,
		marginBottom: isMobile ? 15 : 20,
		borderLeft: '4px solid #2c6e49',
		boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
	},
	blogActions: {
		display: 'flex',
		flexDirection: isMobile ? 'column' : 'row',
		gap: isMobile ? 10 : 15,
		marginTop: isMobile ? 15 : 20,
	},
	buttonRow: {
		display: 'flex',
		flexDirection: isMobile ? 'column' : 'row',
		gap: isMobile ? 10 : 15,
		marginTop: 20,
	},
	primaryButton: {
		width: isMobile ? '100%' : 'auto',
		padding: isMobile ? '12px 20px' : '16px 32px',
	},
	tagInputContainer: {
		marginBottom: isMobile ? 15 : 20,
	},
	tagList: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: isMobile ? 6 : 8,
		marginBottom: isMobile ? 8 : 10,
	},
	tagPill: {
		padding: isMobile ? '4px 8px' : '6px 12px',
		fontSize: isMobile ? 12 : 14,
	},
	tagRemove: {
		background: 'none',
		border: 'none',
		color: '#2c6e49',
		fontSize: 18,
		cursor: 'pointer',
		padding: '0 2px',
		':hover': {
			color: '#ef4444',
		},
	},
	tagInput: {
		width: '100%',
		padding: '12px 16px',
		border: '2px solid #e2e8f0',
		borderRadius: 8,
		fontSize: 14,
		':focus': {
			outline: 'none',
			borderColor: '#2c6e49',
			boxShadow: '0 0 0 3px rgba(44, 110, 73, 0.1)',
		},
	},
	searchContainer: {
		marginBottom: 20,
	},
	
	searchInput: {
		width: '100%',
		padding: '12px 16px',
		border: '2px solid #e2e8f0',
		borderRadius: 8,
		fontSize: 16,
		':focus': {
			outline: 'none',
			borderColor: '#2c6e49',
			boxShadow: '0 0 0 3px rgba(44, 110, 73, 0.1)',
		},
	},

	actionButtons: {
		display: 'flex',
		gap: isMobile ? 5 : 8,
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
		flexDirection: isMobile ? 'column' : 'row',
		width: isMobile ? '100%' : 'auto',
	},

	statusBadge: {
		padding: '4px 8px',
		borderRadius: 12,
		fontSize: 12,
		fontWeight: '500',
		color: 'white',
		display: 'inline-block',
	},

	sectionHeader: {
		marginBottom: 30,
	},

	userStats: {
		display: 'flex',
		flexDirection: isMobile ? 'column' : 'row',
		gap: isMobile ? 10 : 20,
		marginTop: 20,
	},

	statCard: {
		backgroundColor: 'white',
		padding: isMobile ? 15 : 20,
		borderRadius: 12,
		boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
		flex: isMobile ? '0 0 auto' : 1,
		width: isMobile ? '100%' : 'auto',
	},

	statNumber: {
		fontSize: 32,
		fontWeight: '600',
		color: '#2c6e49',
		marginBottom: 8,
	},

	statLabel: {
		fontSize: 14,
		color: '#64748b',
	},

	controlsContainer: {
		display: 'flex',
		flexDirection: isMobile ? 'column' : 'row',
		gap: isMobile ? 15 : 20,
		marginBottom: 30,
		flexWrap: 'wrap',
	},

	filterContainer: {
		display: 'flex',
		flexDirection: isMobile ? 'column' : 'row',
		gap: 12,
		flex: isMobile ? 'none' : 1,
		width: isMobile ? '100%' : 'auto',
	},

	tableContainer: {
		backgroundColor: 'white',
		borderRadius: 12,
		boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
		overflow: 'auto',
	},

	actionButton: {
		backgroundColor: '#f8fafc',
		color: '#475569',
		padding: isMobile ? '6px 12px' : '8px 16px',
		borderRadius: 6,
		border: '1px solid #e2e8f0',
		fontSize: isMobile ? 12 : 14,
		fontWeight: '500',
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		width: isMobile ? '100%' : 'auto',
		textAlign: 'center',
	},

	// Add new style for the table container
	tableWrapper: {
		width: '100%',
		overflowX: 'auto',
		padding: '10px',
		marginBottom: '20px',
	},

	dateInputContainer: {
		display: 'flex',
		gap: 20,
		marginBottom: 20,
		flexDirection: isMobile ? 'column' : 'row',
	},
	dateField: {
		flex: 1,
	},
	dateLabel: {
		display: 'block',
		marginBottom: 8,
		color: '#475569',
		fontSize: 14,
		fontWeight: '500',
	},
	dateInput: {
		width: '100%',
		padding: '12px 16px',
		border: '2px solid #e2e8f0',
		borderRadius: 8,
		fontSize: 14,
		color: '#334155',
		backgroundColor: '#f8fafc',
		transition: 'all 0.2s ease',
	},
	loading: {
		textAlign: 'center',
		padding: 40,
		fontSize: 18,
		color: '#64748b',
	},
	// New styles for better text formatting
	blogTitle: {
		fontSize: 24,
		color: '#334155',
		marginBottom: 15,
		fontWeight: '600',
	},
	blogMeta: {
		fontSize: 16,
		color: '#64748b',
		marginBottom: 20,
	},
	previewTitle: {
		fontSize: 28,
		color: '#2c6e49',
		marginBottom: 20,
		fontFamily: 'Orpheus Pro',
		fontWeight: '600',
	},
	previewContent: {
		fontSize: 16,
		lineHeight: 1.6,
		color: '#334155',
		marginBottom: 20,
	},
	previewImage: {
		width: '100%',
		height: 'auto',
		maxHeight: 300,
		objectFit: 'cover',
		borderRadius: 8,
		marginBottom: 20,
	},
	previewMeta: {
		display: 'flex',
		gap: 15,
		marginBottom: 20,
		color: '#64748b',
		fontSize: 14,
	},
	previewTags: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 8,
		marginTop: 20,
	},
	// Enhanced dropdown styles
	select: {
		width: '100%',
		padding: '16px 20px',
		marginBottom: 20,
		border: '2px solid #e2e8f0',
		borderRadius: 8,
		fontSize: 16,
		color: '#334155',
		backgroundColor: '#f8fafc',
		transition: 'all 0.2s ease',
		cursor: 'pointer',
		appearance: 'none',
		backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23475569' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'right 16px center',
		backgroundSize: '20px',
		textAlign: 'center',
		whiteSpace: 'normal', // Allows text to wrap
		height: 'auto', // Allows height to adjust based on content
		minHeight: '56px', // Ensures minimum height for consistency
		lineHeight: '1.4', // Improves readability of wrapped text
		':hover': {
			borderColor: '#2c6e49',
			backgroundColor: '#fff',
		},
		':focus': {
			outline: 'none',
			borderColor: '#2c6e49',
			backgroundColor: '#fff',
			boxShadow: '0 0 0 3px rgba(44, 110, 73, 0.1)',
		},
	},
	// Enhanced toggle styles
	toggleContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: 12,
		marginBottom: 20,
	},
	toggle: {
		position: 'relative',
		width: 52,
		height: 28,
		borderRadius: 14,
		backgroundColor: '#e2e8f0',
		transition: 'background-color 0.2s ease',
		cursor: 'pointer',
	},
	toggleActive: {
		backgroundColor: '#2c6e49',
	},
	toggleHandle: {
		position: 'absolute',
		top: 2,
		left: 2,
		width: 24,
		height: 24,
		borderRadius: 12,
		backgroundColor: 'white',
		transition: 'transform 0.2s ease',
		boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
	},
	toggleHandleActive: {
		transform: 'translateX(24px)',
	},
	// Action button container for consistent spacing
	actionContainer: {
		display: 'flex',
		gap: 15,
		marginTop: 25,
		marginBottom: 25,
	},
	// Add this new style for select options
	option: {
		padding: '12px 20px',
		textAlign: 'center',
		whiteSpace: 'normal',
		lineHeight: '1.4',
	},
	// Primary action buttons (like Create Post, Submit, etc.)
	primaryButton: {
		backgroundColor: '#2c6e49',
		color: 'white',
		padding: '16px 32px',
		borderRadius: 8,
		border: 'none',
		fontSize: 16,
		fontWeight: '500',
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		minWidth: 140,
		textAlign: 'center',
		boxShadow: '0 2px 4px rgba(44, 110, 73, 0.2)',
		':hover': {
			backgroundColor: '#235c3d',
			transform: 'translateY(-2px)',
		},
	},
	// Secondary buttons (like Cancel, Back, etc.)
	secondaryButton: {
		backgroundColor: '#f8fafc',
		color: '#475569',
		padding: '16px 32px',
		borderRadius: 8,
		border: '2px solid #e2e8f0',
		fontSize: 16,
		fontWeight: '500',
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		minWidth: 140,
		textAlign: 'center',
		':hover': {
			backgroundColor: '#f1f5f9',
			borderColor: '#cbd5e1',
		},
	},
	// Pagination buttons
	paginationContainer: {
		display: 'flex',
		gap: 8,
		justifyContent: 'center',
		marginTop: 30,
		marginBottom: 30,
		flexWrap: 'wrap',
		maxWidth: '100%',
		padding: '0 20px',
	},
	pageButton: {
		backgroundColor: '#fff',
		color: '#475569',
		border: '2px solid #e2e8f0',
		borderRadius: 6,
		padding: '8px 16px',
		minWidth: 40,
		fontSize: 14,
		fontWeight: '500',
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		':hover': {
			backgroundColor: '#f8fafc',
			borderColor: '#cbd5e1',
		},
	},
	activePage: {
		backgroundColor: '#2c6e49',
		color: 'white',
		border: '2px solid #2c6e49',
		borderRadius: 6,
		padding: '8px 16px',
		minWidth: 40,
		fontSize: 14,
		fontWeight: '500',
		cursor: 'pointer',
	},
	// Filter/category buttons
	filterButton: {
		backgroundColor: '#fff',
		color: '#475569',
		border: '2px solid #e2e8f0',
		borderRadius: 20,
		padding: '8px 20px',
		fontSize: 14,
		fontWeight: '500',
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		':hover': {
			backgroundColor: '#f8fafc',
			borderColor: '#cbd5e1',
		},
	},
	activeFilter: {
		backgroundColor: '#2c6e49',
		color: 'white',
		border: '2px solid #2c6e49',
	},
	// Small action buttons (like view, edit, etc.)
	smallButton: {
		backgroundColor: '#f8fafc',
		color: '#475569',
		padding: '8px 16px',
		borderRadius: 6,
		border: '1px solid #e2e8f0',
		fontSize: 14,
		fontWeight: '500',
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		':hover': {
			backgroundColor: '#f1f5f9',
			borderColor: '#cbd5e1',
		},
	},
	// Icon buttons
	iconButton: {
		backgroundColor: 'transparent',
		color: '#475569',
		padding: '8px',
		borderRadius: '50%',
		border: 'none',
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		':hover': {
			backgroundColor: '#f1f5f9',
		},
	},
	// Button groups
	buttonGroup: {
		display: 'flex',
		gap: 12,
		alignItems: 'center',
	},
	// Disabled button state
	buttonDisabled: {
		opacity: 0.6,
		cursor: 'not-allowed',
		':hover': {
			transform: 'none',
		},
	},
	visibilityButtonContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 12,
		marginBottom: 30,
	},
	visibilityButton: {
		backgroundColor: '#fff',
		color: '#475569',
		border: '2px solid #e2e8f0',
		borderRadius: 8,
		padding: '12px 24px',
		fontSize: 16,
		fontWeight: '500',
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		minWidth: 140,
		textAlign: 'center',
		marginRight: 12,
		marginBottom: 12,
		':hover': {
			backgroundColor: '#f8fafc',
			borderColor: '#2c6e49',
		},
	},
	visibilityButtonActive: {
		backgroundColor: '#2c6e49',
		color: 'white',
		border: '2px solid #2c6e49',
		':hover': {
			backgroundColor: '#235c3d',
		},
	},
	// Add new styles for tags
	tagInputContainer: {
		marginBottom: 20,
	},
	tagList: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 8,
		marginBottom: 10,
	},
	tagPill: {
		backgroundColor: '#f0fdf4',
		color: '#2c6e49',
		padding: '6px 12px',
		borderRadius: 16,
		fontSize: 14,
		display: 'flex',
		alignItems: 'center',
		gap: 6,
	},
	tagRemove: {
		background: 'none',
		border: 'none',
		color: '#2c6e49',
		fontSize: 18,
		cursor: 'pointer',
		padding: '0 2px',
		':hover': {
			color: '#ef4444',
		},
	},
	tagInput: {
		width: '100%',
		padding: '12px 16px',
		border: '2px solid #e2e8f0',
		borderRadius: 8,
		fontSize: 14,
		':focus': {
			outline: 'none',
			borderColor: '#2c6e49',
			boxShadow: '0 0 0 3px rgba(44, 110, 73, 0.1)',
		},
	},
	searchContainer: {
		marginBottom: 20,
	},
	
	searchInput: {
		width: '100%',
		padding: '12px 16px',
		border: '2px solid #e2e8f0',
		borderRadius: 8,
		fontSize: 16,
		':focus': {
			outline: 'none',
			borderColor: '#2c6e49',
			boxShadow: '0 0 0 3px rgba(44, 110, 73, 0.1)',
		},
	},

	actionButtons: {
		display: 'flex',
		gap: isMobile ? 5 : 8,
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
		flexDirection: isMobile ? 'column' : 'row',
		width: isMobile ? '100%' : 'auto',
	},

	statusBadge: {
		padding: '4px 8px',
		borderRadius: 12,
		fontSize: 12,
		fontWeight: '500',
		color: 'white',
		display: 'inline-block',
	},

	sectionHeader: {
		marginBottom: 30,
	},

	userStats: {
		display: 'flex',
		flexDirection: isMobile ? 'column' : 'row',
		gap: isMobile ? 10 : 20,
		marginTop: 20,
	},

	statCard: {
		backgroundColor: 'white',
		padding: isMobile ? 15 : 20,
		borderRadius: 12,
		boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
		flex: isMobile ? '0 0 auto' : 1,
		width: isMobile ? '100%' : 'auto',
	},

	statNumber: {
		fontSize: 32,
		fontWeight: '600',
		color: '#2c6e49',
		marginBottom: 8,
	},

	statLabel: {
		fontSize: 14,
		color: '#64748b',
	},

	controlsContainer: {
		display: 'flex',
		flexDirection: isMobile ? 'column' : 'row',
		gap: isMobile ? 15 : 20,
		marginBottom: 30,
		flexWrap: 'wrap',
	},

	filterContainer: {
		display: 'flex',
		flexDirection: isMobile ? 'column' : 'row',
		gap: 12,
		flex: isMobile ? 'none' : 1,
		width: isMobile ? '100%' : 'auto',
	},

	tableContainer: {
		backgroundColor: 'white',
		borderRadius: 12,
		boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
		overflow: 'auto',
	},

	actionButton: {
		backgroundColor: '#f8fafc',
		color: '#475569',
		padding: isMobile ? '6px 12px' : '8px 16px',
		borderRadius: 6,
		border: '1px solid #e2e8f0',
		fontSize: isMobile ? 12 : 14,
		fontWeight: '500',
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		width: isMobile ? '100%' : 'auto',
		textAlign: 'center',
	},

	// Add new style for the table container
	tableWrapper: {
		width: '100%',
		overflowX: 'auto',
		padding: '10px',
		marginBottom: '20px',
	},

	dateInputContainer: {
		display: 'flex',
		gap: 20,
		marginBottom: 20,
		flexDirection: isMobile ? 'column' : 'row',
	},
	dateField: {
		flex: 1,
	},
	dateLabel: {
		display: 'block',
		marginBottom: 8,
		color: '#475569',
		fontSize: 14,
		fontWeight: '500',
	},
	dateInput: {
		width: '100%',
		padding: '12px 16px',
		border: '2px solid #e2e8f0',
		borderRadius: 8,
		fontSize: 14,
		color: '#334155',
		backgroundColor: '#f8fafc',
		transition: 'all 0.2s ease',
	},
	tagSection: {
		marginBottom: 20,
	},
	tagSectionLabel: {
		display: 'block',
		marginBottom: 10,
		color: '#475569',
		fontSize: 14,
		fontWeight: '500',
	},
	presetTagsContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 8,
		marginBottom: 15,
		padding: 15,
		backgroundColor: '#f8fafc',
		borderRadius: 8,
		border: '1px solid #e2e8f0',
	},
	presetTagButton: {
		backgroundColor: '#fff',
		border: '1px solid #e2e8f0',
		borderRadius: 20,
		padding: '6px 12px',
		fontSize: 14,
		color: '#475569',
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		':hover': {
			backgroundColor: '#f1f5f9',
			borderColor: '#cbd5e1',
		},
	},
	presetTagButtonSelected: {
		backgroundColor: '#2c6e49',
		borderColor: '#2c6e49',
		color: '#fff',
		':hover': {
			backgroundColor: '#235c3d',
		},
	},
	tagList: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 8,
		marginTop: 10,
	},
	tagPill: {
		backgroundColor: '#f0fdf4',
		color: '#2c6e49',
		padding: '6px 12px',
		borderRadius: 20,
		fontSize: 14,
		display: 'flex',
		alignItems: 'center',
		gap: 6,
	},
	tagRemove: {
		background: 'none',
		border: 'none',
		color: '#2c6e49',
		fontSize: 18,
		cursor: 'pointer',
		padding: '0 2px',
		':hover': {
			color: '#ef4444',
		},
	},
	modal: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0,0,0,0.5)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 1000
	},
	modalContent: {
		backgroundColor: 'white',
		padding: '20px',
		borderRadius: '8px',
		maxWidth: '500px',
		width: '90%'
	},
	modalButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		gap: '10px',
		marginTop: '20px'
	}
});

// Utility function to generate institution code
const generateInstitutionCode = (type) => {
	const prefix = type === "University or College" ? "U" : type === "High School" ? "H" : "I";
	return `${prefix}${Math.floor(10000 + Math.random() * 90000)}`;
};

export default Admin;