import React, { useEffect, useRef } from "react";
import { View, Text, ImageBackground, TextInput, TouchableOpacity, Alert, ScrollView, Animated, Keyboard } from "react-native";
import { getAuth, signInAnonymously, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail } from "firebase/auth";
import { getFirestore, doc, setDoc, updateDoc, getDoc } from "firebase/firestore";
import { useNavigation } from "@react-navigation/native";
import { app } from "../firebase";
import { useUser } from "./UserContext";
import { Dimensions } from "react-native";
import Bodoni72Smallcaps from "../assets/fonts/Bodoni 72 Smallcaps Book.ttf";

const fontFamilies = {
	Bodoni72Smallcaps: "Bodoni72Smallcaps",
};

const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width < 1440;
const isLargeDesktop = width >= 1440;
const isDevelopment = process.env.NODE_ENV === "development";



const Popup = ({ type: initialType, onClose, isAdminPage = false }) => {
	const { currentUser, setCurrentUser, setCurrentUserData } = useUser();
	const auth = getAuth(app);
	const db = getFirestore(app);
	const navigation = useNavigation();

	const [type, setType] = React.useState(initialType);
	const [email, setEmail] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [reEnteredPassword, setReEnteredPassword] = React.useState("");
	const [firstName, setFirstName] = React.useState("");
	const [lastName, setLastName] = React.useState("");
	const [loginError, setLoginError] = React.useState(false);
	const fadeAnim = useRef(new Animated.Value(0)).current;
	const scaleAnim = useRef(new Animated.Value(0.9)).current;

	useEffect(() => {
		// Faster entrance animation
		Animated.parallel([
			Animated.timing(fadeAnim, {
				toValue: 1,
				duration: 200,
				useNativeDriver: true,
			}),
			Animated.spring(scaleAnim, {
				toValue: 1,
				friction: 7,
				tension: 50,
				useNativeDriver: true,
			}),
		]).start();
	}, []);

	const handleClose = () => {
		// Much faster exit animation
		Animated.parallel([
			Animated.timing(fadeAnim, {
				toValue: 0,
				duration: 100,
				useNativeDriver: true,
			}),
			Animated.timing(scaleAnim, {
				toValue: 0.95,
				duration: 100,
				useNativeDriver: true,
			}),
		]).start(() => {
			onClose(false);
		});
	};

	const switchType = () => {
		// Animation for switching between login/register
		Animated.sequence([
			Animated.timing(scaleAnim, {
				toValue: 0.95,
				duration: 100,
				useNativeDriver: true,
			}),
			Animated.spring(scaleAnim, {
				toValue: 1,
				friction: 8,
				tension: 40,
				useNativeDriver: true,
			}),
		]).start();
		
		setType(type === "login" ? "register" : "login");
		// Reset form fields
		setEmail("");
		setPassword("");
		setReEnteredPassword("");
		setFirstName("");
		setLastName("");
		setLoginError(false);
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			Keyboard.dismiss();
			if (type === "login") {
				handleSignIn();
			} else if (type === "register" && password === reEnteredPassword) {
				handleSignUp();
			}
		}
	};

	const emailExists = async (email) => {
		const lowerCaseEmail = email.toLowerCase();
		const docRef = doc(db, "users", lowerCaseEmail);
		const docSnap = await getDoc(docRef);
		return docSnap.exists();
	};

	const createUserProfile = async (userId, userData) => {
		const lowerCaseEmail = userId.toLowerCase();
		try {
			await setDoc(doc(db, "users", lowerCaseEmail), userData);
			// console.log("User profile created for", lowerCaseEmail);
			await updateLastLogin(lowerCaseEmail);
		} catch (error) {
			console.error("Error creating user profile for", lowerCaseEmail, ":", error);
		}
	};

	const updateLastLogin = async (userId) => {
		const lowerCaseEmail = userId.toLowerCase();
		try {
			await updateDoc(doc(db, "users", lowerCaseEmail), {
				lastLogin: new Date().toLocaleTimeString("en-US", { hour12: false }),
			});
			// console.log("Last login updated for", lowerCaseEmail);
		} catch (error) {
			console.error("Error updating last login for", lowerCaseEmail, ":", error);
		}
	};

	const generateUniqueUid = async () => {
		let isUnique = false;
		let uid;

		while (!isUnique) {
			// Generate a random 5-digit number and prepend 'A'
			uid =
				"F" +
				Math.floor(Math.random() * 100000)
					.toString()
					.padStart(5, "0");

			// Check Firestore for uniqueness
			const docRef = doc(db, "users", uid);
			const docSnap = await getDoc(docRef);
			isUnique = !docSnap.exists(); // UID is unique if it doesn't exist in Firestore
		}

		return uid;
	};

	const generateUIC = () => {
		return Math.random().toString(36).substr(2, 9); // Simple UIC generation logic
	};

	const handleSignUp = async () => {
		if (password !== reEnteredPassword) {
			alert("Passwords do not match. Please try again.");
			return;
		}
		const lowerCaseEmail = email.toLowerCase();
		var accountType = "Friends & Family";
		var newAccount = true;
		var organization = { name: "", code: "" };
		var profilePictureURL = "";
		var friends = {
			following: {},
			followers: {},
			requests: {}
		};
		var messages = {};

		try {
			const exists = await emailExists(lowerCaseEmail);
			if (exists) {
				alert("Email already in use, please login instead.");
				navigation.navigate("My Tassel - Welcome", { popupType: "Login" });
				return;
			}

			const uniqueUid = await generateUniqueUid(); // Generate a unique UID
			const uic = generateUIC(); // Generate a unique UIC

			const userCredential = await createUserWithEmailAndPassword(auth, lowerCaseEmail, password);
			const user = userCredential.user;

			const userData = {
				uid: uniqueUid, // Use the generated unique UID
				email: lowerCaseEmail,
				firstName,
				lastName,
				newAccount,
				accountType,
				profilePictureURL,
				friends,
				organization,
				messages,
				isFriendsListPrivate: true, // Ensure this is set to true
				uic, // Add the generated UIC
			};

			await createUserProfile(lowerCaseEmail, userData); // This saves the userData to Firestore

			setCurrentUser(user); // Set the auth user
			setCurrentUserData(userData); // Set the Firestore user data
			handleLinkPress("Hub");
			onClose(true); // Close the popup with success
		} catch (error) {
			if (error.code === "auth/email-already-in-use") {
				alert("Email already in use", "The email address is already in use by another account.");
				return;
			} else {
				alert("Signup Error", error.message);
				return;
			}
		}
	};

	const handleSignIn = async () => {
		const lowerCaseEmail = email.toLowerCase();
		try {
			// Reset login error state on each attempt
			setLoginError(false);

			// Fetch user data to check hasConfirmedEmail
			const userDocRef = doc(db, "users", lowerCaseEmail);
			const userDocSnap = await getDoc(userDocRef);

			let loginEmail = lowerCaseEmail;
			// if (userDocSnap.exists() && userDocSnap.data().hasConfirmedEmail === false) {
			// 	loginEmail = userDocSnap.data().email?.toLowerCase() || userDocSnap.data().newEmail?.toLowerCase();
			// }

			const userCredential = await signInWithEmailAndPassword(auth, loginEmail, password);
			const user = userCredential.user;

			if (userDocSnap.exists()) {
				const userData = userDocSnap.data();
				setCurrentUser(user); // Set the auth user
				setCurrentUserData(userData); // Set the Firestore user data
				await updateLastLogin(loginEmail);

				if (isAdminPage && userData?.accountType.toLowerCase() !== "admin") {
					alert("Access Denied", "You do not have permission to access the admin page.");
					// onClose(false); // Close the popup with failure
				} else {
					handleLinkPress(isAdminPage ? "Admin" : "Hub"); // Adjust "AdminPage" to your admin page route
					onClose(true); // Close the popup with success
				}
			} else {
				console.error("No such user data!");
				setLoginError(true);
				// onClose(false); // Close the popup with failure
			}
		} catch (error) {
			console.error("Sign in error:", error.message);
			setLoginError(true); // Set login error state on failure
			// onClose(false); // Close the popup with failure
		}
	};

	const handleAnonymousSignIn = () => {
		signInAnonymously(auth)
			.then((userCredential) => {
				const user = userCredential.user;
				handleLinkPress("Hub");
				onClose(true); // Close the popup with success
			})
			.catch((error) => {
				console.error("Sign in anonymously error:", error.message);
				// onClose(false); // Close the popup with failure
			});
	};

	const handleLinkPress = (pageName) => {
		navigation.navigate(pageName);
	};

	const handlePasswordReset = async () => {
		const lowerCaseEmail = email.toLowerCase();
		if (!lowerCaseEmail) {
			alert("Please enter your email address.");
			return;
		}

		try {
			await sendPasswordResetEmail(auth, lowerCaseEmail);
			alert("A link to reset your password has been sent to your email address.");
		} catch (error) {
			alert("Reset Password Error", error.message);
		}
	};

	React.useEffect(() => {
	  if (isDevelopment) {
	    const testLogin = async () => {
	      try {
	        const testEmails = [
	          "Tjhawk27@gmail.com", // Trevor Hawkins
	          "trevorhawkinsofficial@gmail.com", // Cameron Brink
	          "trevorhawkins@makenoisemedia.io", // Sam Minix
	          "info@dsignable.com" // Tristan
	        ];
	        const password = "Newtjhawk27";
	        const lowerCaseEmail = testEmails[3].toLowerCase(); // Default to Jennifer

	        const userCredential = await signInWithEmailAndPassword(auth, lowerCaseEmail, password);
	        const user = userCredential.user;

	        const userDocRef = doc(db, "users", lowerCaseEmail);
	        const userDocSnap = await getDoc(userDocRef);

	        if (userDocSnap.exists()) {
	          const userData = userDocSnap.data();
	          setCurrentUser(user); // Set the auth user
	          setCurrentUserData(userData); // Set the Firestore user data
	          navigation.navigate("Hub"); // Navigate to Hub
	        } else {
	          console.error("No such user data!");
	        }
	      } catch (error) {
	        console.error("Error logging in with test account", error);
	      }
	    };
	    // testLogin();
	  }
	}, []);

	return (
		<View style={styles.overlayWrapper}>
			<View style={styles.popupWrapper}>
				<Animated.View 
					style={[
						styles.popupContainer,
						{
							opacity: fadeAnim,
							transform: [{ scale: scaleAnim }]
						}
					]}
				>
					<ImageBackground 
						source={require("../assets/Popup Background.png")} 
						style={styles.popup}
						imageStyle={{ borderRadius: isMobile ? 10 : isTablet ? 20 : isSmallDesktop ? 50 : 50,  }}
					>
						<ScrollView 
							contentContainerStyle={styles.scrollContent} 
							showsVerticalScrollIndicator={true}
							bounces={false}
						>
							<Text style={styles.popupTitle}>Welcome to MyTassel!</Text>

							{type === "register" && 
								<Text style={styles.popupSubtitle}>
									Join MyTassel and make your graduation special. Create events, build registries, and connect with loved ones!
								</Text>
							}

							{type === "login" && 
								<Text style={styles.popupSubtitle}>
									Sign in now to reconnect with your loved ones and continue planning out the perfect celebration!
								</Text>
							}

							<TextInput
								style={[
									styles.popupInput,
									loginError && { borderColor: "red", borderWidth: 2 }
								]}
								placeholder="Email"
								placeholderTextColor="#aaa"
								onChangeText={(text) => {
									setEmail(text.toLowerCase());
									setLoginError(false);
								}}
								value={email}
								onKeyPress={handleKeyPress}
							/>

							<TextInput
								style={[
									styles.popupInput,
									loginError && { borderColor: "red", borderWidth: 2 }
								]}
								placeholder="Password"
								placeholderTextColor="#aaa"
								secureTextEntry={true}
								onChangeText={(text) => {
									setPassword(text);
									setLoginError(false);
								}}
								value={password}
								onKeyPress={handleKeyPress}
							/>

							{type === "register" && (
								<>
									<TextInput
										style={styles.popupInput}
										placeholder="Re-enter Password"
										placeholderTextColor="#aaa"
										secureTextEntry={true}
										onChangeText={setReEnteredPassword}
										value={reEnteredPassword}
										onKeyPress={handleKeyPress}
									/>
									<TextInput
										style={styles.popupInput}
										placeholder="First Name"
										placeholderTextColor="#aaa"
										onChangeText={setFirstName}
										value={firstName}
										onKeyPress={handleKeyPress}
									/>
									<TextInput
										style={styles.popupInput}
										placeholder="Last Name"
										placeholderTextColor="#aaa"
										onChangeText={setLastName}
										value={lastName}
										onKeyPress={handleKeyPress}
									/>
								</>
							)}

							{password !== reEnteredPassword && type === "register" && 
								<Text style={styles.errorText}>Passwords do not match</Text>
							}

							<TouchableOpacity
								style={styles.popupButton}
								onPress={type === "login" ? handleSignIn : handleSignUp}
							>
								<Text style={styles.popupButtonText}>
									{type === "login" ? "Sign In" : "Sign Up"}
								</Text>
							</TouchableOpacity>

							<TouchableOpacity onPress={switchType} style={styles.switchButton}>
								<Text style={styles.switchButtonText}>
									{type === "login" ? "Need an account? Register" : "Already have an account? Login"}
								</Text>
							</TouchableOpacity>

							{type === "login" && (
								<>
									<TouchableOpacity onPress={handlePasswordReset} style={styles.forgotPassword}>
										<Text style={styles.forgotPasswordText}>Forgot Password?</Text>
									</TouchableOpacity>

									<TouchableOpacity
										style={styles.bypass}
										onPress={handleAnonymousSignIn}
									>
										<Text style={styles.bypassText}>Continue without signing in</Text>
									</TouchableOpacity>
								</>
							)}
						</ScrollView>

						<TouchableOpacity onPress={handleClose} style={styles.closeButton}>
							<Text style={styles.closeButtonText}>×</Text>
						</TouchableOpacity>
					</ImageBackground>
				</Animated.View>
			</View>
		</View>
	);
};

const styles = {
	overlayWrapper: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		backdropFilter: 'blur(5px)',
		zIndex: 1000,
	},
	popupWrapper: {
		position: "relative",
		width: isMobile ? '95%' : isTablet ? '80%' : isSmallDesktop ? '70%' : '60%',
		maxWidth: 1000,
		maxHeight: '90vh',
		margin: 'auto',
		borderRadius: isMobile ? 10 : isTablet ? 20 : isSmallDesktop ? 50 : 50,
		overflow: 'hidden',
		boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
	},
	popupContainer: {
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(255, 255, 255, 0.05)',
		borderRadius: isMobile ? 10 : isTablet ? 20 : isSmallDesktop ? 50 : 50,
		overflow: 'hidden',
		backdropFilter: 'blur(10px)',
		WebkitBackdropFilter: 'blur(10px)',
		border: '1px solid rgba(255, 255, 255, 0.18)',
		
	},
	popup: {
		width: '100%',
		height: '100%',
		borderRadius: isMobile ? 10 : isTablet ? 20 : isSmallDesktop ? 50 : 50,
		overflow: 'hidden',
	},
	scrollContent: {
		flexGrow: 1,
		justifyContent: 'flex-start',
		alignItems: 'center',
		paddingVertical: isMobile ? 10 : 10,
		paddingBottom: isMobile ? 60 : 80,
		width: '100%',
	},
	popupTitle: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: isMobile ? 30 : isTablet ? 50 : isSmallDesktop ? 80 : 100,
		fontWeight: "normal",
		color: "#fefde1",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 5,
		textShadowColor: "rgba(0, 0, 0, 0.06)",
		width: isMobile ? "90%" : isTablet ? "85%" : isSmallDesktop ? "90%" : 893,
		marginBottom: 20,
		paddingTop: isMobile ? 20 : 40,
		textAlign: "center",
	},
	popupSubtitle: {
		fontFamily: "Orpheus Pro",
		fontSize: isMobile ? 20 : isTablet ? 24 : isSmallDesktop ? 28 : 30,
		fontWeight: "500",
		color: "#fefde2",
		lineHeight: isMobile ? 20 : isTablet ? 30 : isSmallDesktop ? 35 : 35,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.16)",
		width: isMobile ? "75%" : isTablet ? "75%" : isSmallDesktop ? 731 : 731,
		marginBottom: 30,
		textAlign: "center",
		paddingHorizontal: 20,
	},
	popupInput: {
		width: isMobile ? "90%" : isTablet ? 500 : isSmallDesktop ? 600 : 674,
		height: 50,
		backgroundColor: "#fefde2",
		paddingHorizontal: 10,
		paddingVertical: 8,
		marginBottom: 25,
		borderRadius: 50,
	},
	popupButtonText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: isMobile ? 15 : isTablet ? 20 : isSmallDesktop ? 25 : 25,
		fontWeight: "500",
		color: "#fefde2",
		lineHeight: isMobile ? 20 : isTablet ? 25 : isSmallDesktop ? 30 : 35,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	popupButton: {
		backgroundColor: "#4c956c",
		paddingHorizontal: isMobile ? 75 : isTablet ? 100 : isSmallDesktop ? 125 : 150,
		paddingVertical: isMobile ? 10 : isTablet ? 12 : isSmallDesktop ? 15 : 15,
		borderRadius: isMobile ? 15 : isTablet ? 20 : isSmallDesktop ? 25 : 25,
		marginTop: 10,
		marginBottom: 20,
	},
	closeButton: {
		position: "absolute",
		top: isMobile ? 10 : isTablet ? 15 : isSmallDesktop ? 20 : 20,
		left: isMobile ? 20 : isTablet ? 25 : isSmallDesktop ? 30 : 35,
	},
	closeButtonText: {
		fontSize: isMobile ? 20 : isTablet ? 25 : isSmallDesktop ? 30 : 35,
		color: "white",
	},
	bypass: {
		paddingHorizontal: isMobile ? 75 : isTablet ? 100 : isSmallDesktop ? 125 : 150,
		paddingVertical: isMobile ? 10 : isTablet ? 12 : isSmallDesktop ? 15 : 15,
		borderRadius: isMobile ? 15 : isTablet ? 20 : isSmallDesktop ? 25 : 25,
	},
	bypassText: {
		fontFamily: fontFamilies.Bodoni72Smallcaps,
		fontSize: isMobile ? 12: isTablet ? 14 : isSmallDesktop ? 15 : 20,
		fontWeight: "500",
		color: "#fefde2",
		opacity: 0.9,
		lineHeight: isMobile ? 15 : isTablet ? 20 : isSmallDesktop ? 25 : 30,
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.16)",
	},
	errorText: {
		color: "red",
		fontSize: 14,
		marginBottom: 10,
		textAlign: "center",
	},
	switchButton: {
		marginTop: 15,
		marginBottom: 10,
	},
	switchButtonText: {
		color: "#fefde2",
		fontSize: 16,
		textAlign: "center",
		// textDecorationLine: "underline",
	},
	forgotPassword: {
		marginTop: 10,
		marginBottom: 15,
	},
	forgotPasswordText: {
		color: "#fefde2",
		fontSize: 14,
		textAlign: "center",
		textDecorationLine: "underline",
	},
};

export default Popup;
