import React, { useState, useEffect } from "react";
import { View, Text, ScrollView, StyleSheet, Platform, Button, Modal } from "react-native";
import { REACT_APP_AWS_SERVER_URL } from '@env';	
// Import Firebase
import { getAuth } from "firebase/auth";
import { getFirestore, doc, updateDoc } from "firebase/firestore"; 
import SMSPopup from "./SMSPopup";
import { useUser } from "../../Widgets/UserContext";

const Terms = () => {
	// URL to your PDF file - you might need to adjust this
	// For local files, ensure the file is accessible from the public folder in your web project

	let LOCAL_SERVER_URL = "http://localhost:3300";
	let AWS_SERVER_URL = REACT_APP_AWS_SERVER_URL;

	const { currentUser, currentUserData, setCurrentUser, setCurrentUserData } = useUser();

	const isDevelopment = process.env.NODE_ENV === "development";
	const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;

 const pdfFileUrl = `${SERVER_URL}/pdfs/Terms of Service.pdf`;

	const [modalVisible, setModalVisible] = useState(false);
	const [modalMessage, setModalMessage] = useState("");
	const [pdfError, setPdfError] = useState(false);
	const [isNoticeVisible, setIsNoticeVisible] = useState(true);

	const handleOptInOut = async () => {
		if (currentUserData) {
			const db = getFirestore();
			const userRef = doc(db, "users", currentUserData.email);
			await updateDoc(userRef, {
				smsOptIn: !currentUserData?.smsOptIn,
				smsOptInTimestamp: new Date().toISOString(),
				smsOptInMethod: 'terms_page',
				smsOptInVersion: '1.0',
				smsOptInLocation: window.location.href
			});
			setModalMessage("Your SMS preferences have been updated.");
			// Update the user data to trigger a re-render
			setCurrentUserData({ 
				...currentUserData, 
				smsOptIn: !currentUserData.smsOptIn,
				smsOptInTimestamp: new Date().toISOString()
			});
		} else {
			setModalMessage("You must log in first to opt in to SMS.");
		}
		setModalVisible(true);
	};

	useEffect(() => {
		console.log("Opt In/Out Updated", "Opt-in currently set to", currentUserData?.smsOptIn );
	}, [currentUserData, modalVisible]);

	useEffect(() => {
		// Verify PDF URL is accessible
		const checkPdfAccess = async () => {
			try {
				const response = await fetch(pdfFileUrl);
				if (!response.ok) {
					console.error('PDF access error:', response.status);
					setPdfError(true);
				}
			} catch (error) {
				console.error('PDF fetch error:', error);
				setPdfError(true);
			}
		};
		
		checkPdfAccess();
	}, [pdfFileUrl]);

	return (
		<View style={styles.body}>
			{isNoticeVisible && (
				<View style={styles.smsNotice}>
					<Text style={styles.smsNoticeText}>
						SMS and Text Message Communications Notice{"\n\n"}
						By checking the box below and using MyTassel's sharing features, you expressly consent to receive SMS messages from MyTassel. Message frequency varies and depends on your sharing activity. Messages may include:{"\n\n"}
						• Sharing of graduation announcements and event details{"\n"}
						• Registry updates and contribution notifications{"\n"}
						• Important account notifications{"\n"}
						• Service updates and changes{"\n\n"}
						Message and data rates may apply. You can opt out at any time by:{"\n"}
						• Replying STOP to any message{"\n"}
						• Emailing info@mytassel.com{"\n"}
						• Updating your preferences here{"\n\n"}
						For help, reply HELP or contact info@mytassel.com
					</Text>
					<Text style={styles.smsNoticeText}>
						By opting in, you confirm that:{"\n"}
						• You are the owner of this phone number{"\n"}
						• You are 13 years or older{"\n"}
						• You agree to receive automated marketing messages{"\n"}
						• You understand this consent is not required for purchase
					</Text>
					<Button 
						title={currentUserData?.smsOptIn ? "Opt Out of SMS" : "I agree to receive SMS messages"} 
						onPress={handleOptInOut} 
					/>
					<Button title="Minimize" onPress={() => setIsNoticeVisible(false)} />
				</View>
			)}
			{!isNoticeVisible && (
				<Button title="Show SMS Notice" onPress={() => setIsNoticeVisible(true)} />
			)}

			<SMSPopup
				visible={modalVisible}
				message={modalMessage}
				onClose={() => setModalVisible(false)}
			/>
			<ScrollView style={styles.pdfContainer}>
				<View style={styles.pdfWrapper}>
					{Platform.OS === "web" ? (
						pdfError ? (
							<Text style={styles.errorText}>Error loading PDF. Please try again later.</Text>
						) : (
							<iframe 
								src={pdfFileUrl}
								style={styles.pdf}
								frameBorder="0"
								onError={() => setPdfError(true)}
							>
								Your browser does not support iframes.
							</iframe>
						)
					) : (
						<Text style={styles.text}>PDF display is not supported on this platform.</Text>
					)}
				</View>
			</ScrollView>
		</View>
	);
};

// Styles
const styles = StyleSheet.create({
	body: {
		flex: 1,
		alignItems: "center",
		backgroundColor: "#4c956c",
		height: "100vh",
	},
	container: {
		flex: 1,
		padding: 50,
		backgroundColor: "#fefde2",
		width: "95vw",
		borderRadius: 75,
	},
	headerText: {
		fontFamily: "Bodoni72Smallcaps",
		fontWeight: "500",
		fontSize: 40,
		lineHeight: 30,
		textAlign: "center",
		color: "#4c956c",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
		marginBottom: 50,
	},
	pdfContainer: {
		flex: 1,
		width: "90vw",
		borderWidth: 1,
		borderColor: "#fff",
		borderRadius: 8,
		overflow: "hidden",
	},
	pdfWrapper: {
		paddingTop: 20,
	},
	pdf: {
		width: "100%",
		height: "100vh",
		minHeight: 500,
	},
	text: {
		fontFamily: "Bodoni72Smallcaps",
		textAlign: "center",
		margin: 20,
	},
	smsNotice: {
		backgroundColor: "#4c956c",
		padding: 10,
		borderBottomWidth: 1,
		borderBottomColor: "#fff",
		marginBottom: 10,
	},
	smsNoticeText: {
		fontFamily: "Bodoni72Smallcaps",
		fontSize: 16,
		color: "#fff",
		textAlign: "center",
		marginBottom: 10,
	},
	modalView: {
		margin: 20,
		backgroundColor: "white",
		borderRadius: 20,
		padding: 35,
		alignItems: "center",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
	},
	modalText: {
		marginBottom: 15,
		textAlign: "center",
	},
	errorText: {
		color: 'red',
		textAlign: 'center',
		margin: 20,
		fontFamily: "Bodoni72Smallcaps",
	},
});

export default Terms;
