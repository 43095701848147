import { Animated } from 'react-native';

// Button hover animations
export const useButtonAnimation = (scale = 1.05, rise = 5) => {
    const scaleAnim = new Animated.Value(1);
    const translateAnim = new Animated.Value(0);
    const shadowAnim = new Animated.Value(0);

    const animateIn = () => {
        Animated.parallel([
            Animated.spring(scaleAnim, {
                toValue: scale,
                friction: 7,
                tension: 40,
                useNativeDriver: true,
            }),
            Animated.spring(translateAnim, {
                toValue: -rise, // Negative value to move up
                friction: 7,
                tension: 40,
                useNativeDriver: true,
            }),
            Animated.timing(shadowAnim, {
                toValue: 1,
                duration: 200,
                useNativeDriver: true,
            }),
        ]).start();
    };

    const animateOut = () => {
        Animated.parallel([
            Animated.spring(scaleAnim, {
                toValue: 1,
                friction: 7,
                tension: 40,
                useNativeDriver: true,
            }),
            Animated.spring(translateAnim, {
                toValue: 0,
                friction: 7,
                tension: 40,
                useNativeDriver: true,
            }),
            Animated.timing(shadowAnim, {
                toValue: 0,
                duration: 200,
                useNativeDriver: true,
            }),
        ]).start();
    };

    const buttonStyle = {
        transform: [
            { scale: scaleAnim },
            { translateY: translateAnim }
        ],
        shadowOpacity: shadowAnim,
    };

    return { buttonStyle, animateIn, animateOut };
};

// Popup animations
export const usePopupAnimation = () => {
    const fadeAnim = new Animated.Value(0);
    const scaleAnim = new Animated.Value(0.9);

    const animateIn = () => {
        Animated.parallel([
            Animated.timing(fadeAnim, {
                toValue: 1,
                duration: 200,
                useNativeDriver: true,
            }),
            Animated.spring(scaleAnim, {
                toValue: 1,
                friction: 7,
                tension: 50,
                useNativeDriver: true,
            }),
        ]).start();
    };

    const animateOut = (onComplete) => {
        Animated.parallel([
            Animated.timing(fadeAnim, {
                toValue: 0,
                duration: 100,
                useNativeDriver: true,
            }),
            Animated.timing(scaleAnim, {
                toValue: 0.95,
                duration: 100,
                useNativeDriver: true,
            }),
        ]).start(onComplete);
    };

    const popupStyle = {
        opacity: fadeAnim,
        transform: [{ scale: scaleAnim }],
    };

    return { popupStyle, animateIn, animateOut };
};

// Content fade and slide animations
export const useContentAnimation = () => {
    const animations = {
        welcome: {
            fade: new Animated.Value(0),
            slide: new Animated.Value(-50),
        },
        description: {
            fade: new Animated.Value(0),
            slide: new Animated.Value(50),
        },
        buttons: {
            fade: new Animated.Value(0),
            slide: new Animated.Value(100),
        },
        resources: {
            fade: new Animated.Value(0),
            slide: new Animated.Value(200),
        },
    };

    const animateContent = () => {
        const animationSequence = Object.keys(animations).map((key) => (
            Animated.parallel([
                Animated.timing(animations[key].fade, {
                    toValue: 1,
                    duration: 800,
                    useNativeDriver: true,
                }),
                Animated.timing(animations[key].slide, {
                    toValue: 0,
                    duration: 800,
                    useNativeDriver: true,
                }),
            ])
        ));

        Animated.stagger(300, animationSequence).start();
    };

    const getStyle = (key) => ({
        opacity: animations[key].fade,
        transform: [{ translateY: animations[key].slide }],
    });

    return { getStyle, animateContent };
};

// Resource ribbon animations
export const useRibbonAnimation = () => {
    const ribbonPositions = {
        whyMyTassel: new Animated.Value(-400),
        tutorials: new Animated.Value(-400),
        blog: new Animated.Value(-400),
        checklist: new Animated.Value(-400),
        catalogue: new Animated.Value(-400),
        privacy: new Animated.Value(-400),
        terms: new Animated.Value(-400),
    };

    const slideIn = () => {
        const animations = [
            Animated.timing(ribbonPositions.whyMyTassel, {
                toValue: 300,
                duration: 500,
                useNativeDriver: true,
            }),
            Animated.timing(ribbonPositions.tutorials, {
                toValue: 250,
                duration: 500,
                useNativeDriver: true,
            }),
            // ... continue for other positions
        ];

        Animated.parallel(animations).start();
    };

    const slideOut = () => {
        const animations = Object.values(ribbonPositions).map(anim =>
            Animated.timing(anim, {
                toValue: -400,
                duration: 500,
                useNativeDriver: true,
            })
        );

        Animated.parallel(animations).start();
    };

    const getPosition = (key) => ({
        transform: [{ translateY: ribbonPositions[key] }],
    });

    return { getPosition, slideIn, slideOut };
};

// Page transition animations
export const usePageTransition = () => {
    const fadeAnim = new Animated.Value(0);
    const slideAnim = new Animated.Value(50);
    const scaleAnim = new Animated.Value(0.95);

    const animateIn = () => {
        Animated.parallel([
            Animated.timing(fadeAnim, {
                toValue: 1,
                duration: 500,
                useNativeDriver: true,
            }),
            Animated.spring(slideAnim, {
                toValue: 0,
                friction: 8,
                tension: 65,
                useNativeDriver: true,
            }),
            Animated.spring(scaleAnim, {
                toValue: 1,
                friction: 8,
                tension: 65,
                useNativeDriver: true,
            }),
        ]).start();
    };

    const animateOut = (callback) => {
        Animated.parallel([
            Animated.timing(fadeAnim, {
                toValue: 0,
                duration: 300,
                useNativeDriver: true,
            }),
            Animated.timing(slideAnim, {
                toValue: -50,
                duration: 300,
                useNativeDriver: true,
            }),
            Animated.timing(scaleAnim, {
                toValue: 0.95,
                duration: 300,
                useNativeDriver: true,
            }),
        ]).start(callback);
    };

    const pageStyle = {
        opacity: fadeAnim,
        transform: [
            { translateY: slideAnim },
            { scale: scaleAnim }
        ],
    };

    return { pageStyle, animateIn, animateOut };
};

// Card animation for events/registry items
export const useCardAnimation = (index = 0) => {
    const fadeAnim = new Animated.Value(0);
    const slideAnim = new Animated.Value(50);
    const scaleAnim = new Animated.Value(0.9);

    const animateIn = () => {
        Animated.sequence([
            Animated.delay(index * 100), // Stagger based on index
            Animated.parallel([
                Animated.timing(fadeAnim, {
                    toValue: 1,
                    duration: 400,
                    useNativeDriver: true,
                }),
                Animated.spring(slideAnim, {
                    toValue: 0,
                    friction: 8,
                    tension: 65,
                    useNativeDriver: true,
                }),
                Animated.spring(scaleAnim, {
                    toValue: 1,
                    friction: 8,
                    tension: 65,
                    useNativeDriver: true,
                }),
            ])
        ]).start();
    };

    const cardStyle = {
        opacity: fadeAnim,
        transform: [
            { translateY: slideAnim },
            { scale: scaleAnim }
        ],
    };

    return { cardStyle, animateIn };
}; 