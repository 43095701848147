import React, { useState, useEffect } from "react";
import { View, Text, Button, Pressable, Image, TextInput, Modal, StyleSheet, Linking, Dimensions, ImageBackground } from "react-native";
import { getFirestore, doc, updateDoc, setDoc, getDoc, getDocs, collection, query, where, arrayUnion } from "firebase/firestore";
import { useUser } from "../../Widgets/UserContext";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import ECardSelectionModal from "./eCards";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import Checkout from "./Checkout";
import { ConnectedAccountModal, ConnectedAccount } from "../PaymentProcessing/ConnectedAccount";
import defaultGoalImage from "../../assets/exGoal.jpg";
import exampleBackground1 from "../../assets/exBackground1.jpg";
import exampleBackground2 from "../../assets/exBackground2.jpg";
import exampleBackground3 from "../../assets/exBackground3.jpg";
import exampleBackground4 from "../../assets/exBackground4.jpg";
import exampleBackground5 from "../../assets/exBackground5.jpg";
import exampleBackground6 from "../../assets/exBackground6.jpg";
import exampleBackground7 from "../../assets/exBackground7.jpg";
import genericProfilePic from "../../assets/generic.jpg";
import { REACT_APP_STRIPE_PERSH_KEY, REACT_APP_STRIPE_PERSH_TEST_KEY, REACT_APP_AWS_SERVER_URL } from '@env';


const { width, height } = Dimensions.get('window');
const isMobile = width < 768;
const isTablet = width >= 768 && width < 1024;
const isSmallDesktop = width >= 1024 && width <= 1500;
const isLargeDesktop = width > 1500;

const STRIPE_PERSH_KEY = REACT_APP_STRIPE_PERSH_KEY;
const STRIPE_PERSH_TEST_KEY = REACT_APP_STRIPE_PERSH_TEST_KEY;


const defaultBackgrounds = [
	exampleBackground1,
	exampleBackground2,
	exampleBackground3,
	exampleBackground4,
	exampleBackground5,
	exampleBackground6,
	exampleBackground7,
];

const GoalTracker = ({ pageOwnerId, pageOwner, isOwner, isVisitorView = false }) => {
	const db = getFirestore();
	const { currentUser, currentUserData } = useUser();
	const storage = getStorage();
	const [goalData, setGoalData] = useState({
		targetAmount: 0,
		amountContributed: 0,
		description: "",
		title: "",
		goalPictureURL: "",
		goalURL: "",
		accountURLs: [], // Changed from accountURL to accountURLs
		contributions: [],
	});



	const [showEditGoalModal, setShowEditGoalModal] = useState(false);
	const [editGoalData, setEditGoalData] = useState({ ...goalData });
	const [newGoalImage, setNewGoalImage] = useState(null);
	const [accountUrl, setAccountUrl] = useState("");
	const [showContributionModal, setShowContributionModal] = useState(false);
	const [showECardModal, setShowECardModal] = useState(false);
	const [contributionAmount, setContributionAmount] = useState("");
	const [pageOwnerEmail, setPageOwnerEmail] = useState("");
	const [showConnectedAccountModal, setShowConnectedAccountModal] = useState(false);
	const [hasConnectedAccount, sethasConnectedAccount] = useState(pageOwner?.hasConnectedAccount ? true : false)

	const [showPaymentOptionsModal, setShowPaymentOptionsModal] = useState(false);
	const [showPostContributionModal, setShowPostContributionModal] = useState(false);

	const [selectedECard, setSelectedECard] = useState(null);
	const [showCheckoutModal, setShowCheckoutModal] = useState(false);

	const stripePromise = loadStripe(STRIPE_PERSH_KEY);


	let LOCAL_SERVER_URL = "http://localhost:3300";
	let AWS_SERVER_URL = REACT_APP_AWS_SERVER_URL;

	const isDevelopment = process.env.NODE_ENV === "development"; // Adjust this condition based on your setup
	const SERVER_URL = isDevelopment ? LOCAL_SERVER_URL : AWS_SERVER_URL;

	const [backgroundImage, setBackgroundImage] = useState(null);

	// Add state for button context
	const [showFinishSetupButton, setShowFinishSetupButton] = useState(false);

	// Check for cached stripeAccountId and Firestore status
	useEffect(() => {
		if (pageOwner && pageOwner.email) {
			const cachedStripeId = localStorage.getItem(`stripeAccountId_${pageOwner.email}`);
			const hasFirestoreStripeId = pageOwner?.stripeAccountId;

			console.log('GoalTracker Local Storage Debug:', {
				email: pageOwner.email,
				cachedStripeId,
				hasFirestoreStripeId,
				localStorageKey: `stripeAccountId_${pageOwner.email}`,
				allLocalStorage: { ...localStorage },
			});

			if (cachedStripeId && !hasFirestoreStripeId) {
				console.log('Should show Finish Setup button because:', {
					cachedStripeId,
					hasFirestoreStripeId
				});
				setShowFinishSetupButton(true);
			} else {
				setShowFinishSetupButton(false);
			}
		}
	}, [pageOwner]);

	useEffect(() => {
		if (pageOwner) {
			if (pageOwner.gradCardBackgroundImage) {
				setBackgroundImage(pageOwner.gradCardBackgroundImage);
			} else {
				setBackgroundImage(exampleBackground2);
			}
		}
	}, [pageOwner]);

	useEffect(() => {
		loadGoalData();
	}, [pageOwnerId]);

	const loadGoalData = async () => {
		const usersRef = collection(db, "users");
		const q = query(usersRef, where("uid", "==", pageOwnerId));
		const querySnapshot = await getDocs(q);

		if (!querySnapshot.empty) {
			querySnapshot.forEach((docSnap) => {
				const userData = docSnap.data();
				setPageOwnerEmail(userData.email);

				if (userData.pages?.registryPageConfig?.goalTracker) {
					setGoalData(userData.pages.registryPageConfig.goalTracker);
				} else {
					// Create an initial goal document
					const userDocRef = doc(db, "users", docSnap.id);
					setDoc(userDocRef, { pages: { registryPageConfig: { goalTracker: { ...goalData } } } }, { merge: true });
				}
			});
		} else {
			// console.log("No user found with UID:", pageOwnerId);
		}
	};

	const handleEditGoal = () => {
		setEditGoalData({ ...goalData });
		setShowEditGoalModal(true);
	};

	const handleImageChange = (event) => {
		const file = event.target.files[0];
		if (file && file.type.startsWith("image/")) {
			setNewGoalImage(file);
		}
	};

	const uploadImage = async () => {
		if (newGoalImage) {
			const imageRef = ref(storage, `goalImages/${pageOwnerId}/${newGoalImage.name}`);
			await uploadBytes(imageRef, newGoalImage);
			const imageUrl = await getDownloadURL(imageRef);
			return imageUrl;
		}
		return goalData.goalPictureURL;
	};

	const getButtonLabel = (url) => {
		if (url.toLowerCase().includes("venmo")) return "Venmo";
		if (url.toLowerCase().includes("paypal")) return "PayPal";
		if (url.toLowerCase().includes("zelle")) return "Zelle";
		return "Contribute"; // Default label
	};

	const handleSubmitEditGoal = async (resetContributions = false) => {
		const imageUrl = await uploadImage();
		const updatedGoalData = {
			...editGoalData,
			goalPictureURL: imageUrl,
			accountURLs: accountUrl.split(",").map((url) => url.trim()),
			amountContributed: resetContributions ? 0 : goalData.amountContributed,
		};
		// Query to get the user's document reference based on UID
		const usersRef = collection(db, "users");
		const q = query(usersRef, where("uid", "==", pageOwnerId));
		const querySnapshot = await getDocs(q);
		if (!querySnapshot.empty) {
			querySnapshot.forEach(async (docSnapshot) => {
				const userDocRef = doc(db, "users", docSnapshot.id);
				await updateDoc(userDocRef, {
					"pages.registryPageConfig.goalTracker": updatedGoalData,
				});
			});
		}
		setGoalData(updatedGoalData);
		setShowEditGoalModal(false);
	};

	const handleMakeContribution = () => {
		setShowECardModal(true); // Show eCard selection for non-owners
		// setShowPaymentOptionsModal(true); // Show payment options to the user
	};

	const handleECardSelection = (eCardData) => {
		// Assuming eCardData contains the selected e-card information
		setSelectedECard(eCardData); // Save the selected e-card data
		setShowECardModal(false); // Hide the e-card selection modal
		setShowCheckoutModal(true); // Show the checkout modal
	};

	const submitContribution = async () => {
		const newAmount = parseFloat(contributionAmount) || 0;
		const updatedAmount = parseFloat(goalData.amountContributed || 0) + newAmount;

		// Query to get the user's document reference based on UID
		const usersRef = collection(db, "users");
		const q = query(usersRef, where("uid", "==", pageOwnerId));
		const querySnapshot = await getDocs(q);

		if (!querySnapshot.empty) {
			querySnapshot.forEach(async (docSnapshot) => {
				const userDocRef = doc(db, "users", docSnapshot.id);

				// Update only the amountContributed field
				await updateDoc(userDocRef, {
					"pages.registryPageConfig.goalTracker.amountContributed": updatedAmount
				});

				// Add the contribution to the contributions array
				await updateDoc(userDocRef, {
					"pages.registryPageConfig.goalTracker.contributions": arrayUnion({
						amount: newAmount,
						contributorName: currentUserData?.firstName + " " + currentUserData?.lastName,
						contributorId: currentUserData?.uid,
						timestamp: new Date()
					})
				});
			});
		}

		// Update local state
		setGoalData(prevData => ({
			...prevData,
			amountContributed: updatedAmount
		}));

		setShowContributionModal(false);
		setContributionAmount("");
	};

	// Placeholder for initiating Stripe payment processing on the server
	const processStripePayment = async (amount) => {
		try {
			const response = await fetch(`${SERVER_URL}/create-payment-intent`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					amount: amount,
					currency: "usd", // Example currency, adjust as necessary
					pageOwner: pageOwner,
				}),
			});

			if (!response.ok) throw new Error("Network response was not ok");

			const result = await response.json();

			// Handle success or failure based on the server's response
			if (result.success) {
				// console.log("Payment successful:", result);
				// Update UI or state as necessary
			} else {
				console.error("Payment failed:", result);
				// Handle failure, update UI or state as necessary
			}
		} catch (error) {
			console.error("Error processing payment:", error);
			// Handle error, update UI or state as necessary
		}
	};
	// Example usage within a component method, e.g., when a button is clicked
	const handlePaymentSubmission = () => {
		const amountToCharge = parseFloat(contributionAmount); // Ensure this is a valid number
		processStripePayment(amountToCharge);
	};

	const sendContributionEmail = async (contributionDetails) => {
		try {
			const response = await fetch(`${SERVER_URL}/send-contribution-email`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					...contributionDetails,
					pageOwner, // Include this in the request
				}),
			});

			if (response.ok) {
				// console.log("Email sent successfully");
			} else {
				console.error("Failed to send email");
			}
		} catch (error) {
			console.error("Error sending email:", error);
		}
	};

	const PaymentOptionsModal = ({ visible, onClose, accountURLs }) => {
		const handlePaymentOptionSelect = async (url) => {
			// Ensure the URL starts with http:// or https://
			const formattedUrl = url.startsWith("http://") || url.startsWith("https://") ? url : `https://${url}`;

			const supported = await Linking.canOpenURL(formattedUrl);
			if (supported) {
				await Linking.openURL(formattedUrl);
				onClose(); // Close the payment options modal
				setShowPostContributionModal(true); // Show post-contribution modal
			} else {
				console.error("Can't handle URL:", formattedUrl);
			}
		};

		if (!visible) {
			return null;
		}

		return (
			<View style={styles.blurredContainer} visible={visible} onRequestClose={onClose}>
				<View style={styles.modalContent}>
					{accountURLs.map((url, index) => (
						<Pressable key={index} style={styles.buttonGreen} onPress={() => handlePaymentOptionSelect(url)}>
							<Text style={styles.buttonText}>{getButtonLabel(url)}</Text>
						</Pressable>
					))}
					<Pressable style={styles.buttonRed} onPress={onClose}>
						<Text style={styles.buttonText}>Close</Text>
					</Pressable>
				</View>
			</View>
		);
	};

	const PostContributionModal = ({ visible, onClose, onSubmitContribution }) => {
		const [tempContributionAmount, setTempContributionAmount] = useState("");

		return (
			<Modal style={{ ...styles.blurredContainer }} visible={visible} transparent={true} onRequestClose={onClose}>
				<View style={styles.modalContent}>
					<Text style={styles.text}>How much did you contribute?</Text>
					<TextInput style={styles.input} placeholder="Contribution amount" keyboardType="numeric" value={tempContributionAmount} onChangeText={setTempContributionAmount} />
					<Pressable style={styles.buttonGreen} onPress={() => onSubmitContribution(parseFloat(tempContributionAmount) || 0)}>
						<Text style={styles.buttonText}>Submit</Text>
					</Pressable>
					<Pressable style={styles.buttonRed} onPress={onClose}>
						<Text style={styles.buttonText}>Close</Text>
					</Pressable>
				</View>
			</Modal>
		);
	};

	return (
		<ImageBackground
			source={{ uri: backgroundImage }}
			imageStyle={{ ...styles.goalTrackerImage, borderRadius: 25 }}
			style={{ ...styles.container, borderRadius: 25, overflow: 'hidden' }}
		>
			{/* {showECardModal && <View style={styles.blurredContainer} visible={showECardModal} onRequestClose={() => setShowECardModal(false)} />} */}

			{/* <Image
				source={goalData.goalPictureURL ? { uri: goalData.goalPictureURL } : defaultGoalImage}
				style={styles.goalImage}
				resizeMode="cover"
			/> */}
			<Image
				source={goalData.goalPictureURL ? { uri: goalData.goalPictureURL } : { uri: "https://images.unsplash.com/photo-1579621970795-87facc2f976d?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" }}
				style={styles.goalImage}
				resizeMode="cover"
			/>
			<Text style={styles.title}>{goalData.title}</Text>
			<Text style={styles.description}>{goalData.description}</Text>
			<Text style={styles.amount}>
				${goalData.amountContributed} of ${goalData.targetAmount}
			</Text>
			<View style={styles.contributionBar}>
				<View style={{ ...styles.filledBar, width: `${(goalData.amountContributed / goalData.targetAmount) * 100}%` }} />
			</View>
			{pageOwnerId === currentUserData?.uid && !isVisitorView ? (
				<Pressable style={styles.button} onPress={() => setShowEditGoalModal(true)}>
					<Text style={styles.buttonText}>Edit Goal</Text>
				</Pressable>
			) : (
				goalData.targetAmount ? (
					<Pressable style={styles.button} onPress={handleMakeContribution}>
						<Text style={styles.contributionButtonText}>Make a Contribution</Text>
					</Pressable>
				) : (
					<View style={styles.noGoalContainer}>
						<Text style={styles.noGoalText}>This graduate hasn't set a goal yet. Please check back later.</Text>
					</View>
				)
			)}
			{pageOwnerId === currentUserData?.uid && !pageOwner?.stripeAccountId && !isVisitorView &&
				<Pressable style={styles.button} onPress={() => setShowConnectedAccountModal(true)}>
					<Text style={styles.buttonText}>Start Accepting Contributions</Text>
				</Pressable>
			}
			{pageOwnerId === currentUserData?.uid && pageOwner?.stripeAccountId && !isVisitorView &&
				<Pressable 
					style={[styles.button, { backgroundColor: '#4c956c', marginTop: 10 }]} 
					onPress={() => setShowConnectedAccountModal(true)}
				>
					<Text style={styles.buttonText}>Manage Stripe Account</Text>
				</Pressable>
			}

			{/* MODALS */}
			{/* Edit Goal Modal */}
			{showEditGoalModal && (
				<View style={styles.blurredContainer} visible={showEditGoalModal} onRequestClose={() => setShowEditGoalModal(false)}>

					<View style={styles.modalContent}>
						<Text style={styles.inputLabel}>Add a Photo:</Text>
						<input type="file" accept="image/*" onChange={handleImageChange} />
						<TextInput style={styles.input} placeholder="Title" value={editGoalData.title} onChangeText={(text) => setEditGoalData({ ...editGoalData, title: text })} />
						<TextInput style={styles.input} placeholder="Description" value={editGoalData.description} onChangeText={(text) => setEditGoalData({ ...editGoalData, description: text })} />
						{/* <TextInput style={styles.input} placeholder="Goal URL" value={editGoalData.goalURL} onChangeText={(text) => setEditGoalData({ ...editGoalData, goalURL: text })} /> */}
						<TextInput style={styles.input} placeholder="Target Amount" keyboardType="numeric" value={editGoalData.targetAmount.toString()} onChangeText={(text) => setEditGoalData({ ...editGoalData, targetAmount: parseFloat(text) })} />
						<Text style={styles.inputLabel}>Amount Raised: ${goalData.amountContributed}</Text>
						<Pressable style={styles.buttonRed} onPress={() => {
							const confirmReset = window.confirm("Are you sure you want to reset all contributions? This action cannot be undone.");
							if (confirmReset) {
								setEditGoalData({ ...editGoalData, amountContributed: 0 });
								handleSubmitEditGoal(true);
							}
						}}>
							<Text style={styles.buttonText}>Reset Contributions</Text>
						</Pressable>
						<Pressable style={styles.buttonGreen} onPress={() => handleSubmitEditGoal()}>
							<Text style={styles.buttonText}>Save</Text>
						</Pressable>
						<Pressable style={styles.buttonRed} onPress={() => setShowEditGoalModal(false)}>
							<Text style={styles.buttonText}>Close</Text>
						</Pressable>
					</View>

				</View>
			)}
			{/* Contribution Modal */}
			<ECardSelectionModal
				isVisible={showECardModal}
				onSelect={handleECardSelection}
				onClose={() => setShowECardModal(false)}
			/>

			{/* Checkout Modal */}
			{pageOwner &&

				<Elements stripe={stripePromise}>
					<Checkout
						isVisible={showCheckoutModal}
						onClose={() => setShowCheckoutModal(false)}
						onContribute={(contributionAmount) => {
							// console.log("Contribution Amount:", contributionAmount);
							// setSelectedECard(null);
						}}
						selectedECard={selectedECard} pageOwner={pageOwner}
					/>
				</Elements>
			}

			{/* Show Payment Options Modal */}
			<PaymentOptionsModal visible={showPaymentOptionsModal} onClose={() => setShowPaymentOptionsModal(false)} accountURLs={goalData.accountURLs} />

			{/* Show Post Contribution Modal */}
			<PostContributionModal visible={showPostContributionModal} onClose={() => setShowPostContributionModal(false)} onSubmitContribution={submitContribution} />

			{/* Show Connected Account Modal */}
			{isOwner && showConnectedAccountModal &&
				<>
					<ConnectedAccount pageOwner={pageOwner} />
					<ConnectedAccountModal
						visible={showConnectedAccountModal}
						onClose={() => setShowConnectedAccountModal(false)}
						pageOwner={pageOwner}
					/>
				</>
			}

		</ImageBackground>
	);
};

const styles = StyleSheet.create({
	container: {
		padding: 20,
		backgroundColor: "#fefde2",
		borderRadius: 10,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		width: "auto",
		height: "100%",
		maxWidth: isMobile ? 300 : isTablet ? 300 : isSmallDesktop ? 400 : 400,
		maxHeight: isMobile ? 300 : isTablet ? 300 : isSmallDesktop ? 650 : 650,
		minHeight: isMobile ? 300 : isTablet ? 300 : isSmallDesktop ? 650 : 650,
		zIndex: 99,
	},
	goalImage: {
		width: isMobile ? 300 : isTablet ? 300 : isSmallDesktop ? 325 : 350, // Full width of the container
		height: 350, // Fixed height, adjust as needed
		resizeMode: "cover", // Cover the entire area of the image view,
		borderRadius: 25,
	},
	button: {
		backgroundColor: "#4c956c",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 40,
		paddingVertical: 10,
		paddingHorizontal: 20,
		marginVertical: 10,
	},
	buttonText: {
		fontFamily: "Orpheus Pro",
		fontSize: 18,
		textAlign: "center",
		color: "#e4e4e4",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
		marginHorizontal: 25,
	},
	title: {
		fontSize: 24,
		fontWeight: "bold",
		marginBottom: 10,
		marginTop: 20,
		color: "#e4e4e4",
	},
	description: {
		fontSize: 18,
		marginBottom: 10,
		color: "#e4e4e4",
	},
	amount: {
		fontSize: 16,
		marginBottom: 10,
		color: "#e4e4e4",
	},
	contributionBar: {
		height: 20,
		backgroundColor: "#ddd",
		borderRadius: 10,
		marginBottom: 10,
	},
	filledBar: {
		height: "100%",
		backgroundColor: "#4c956c",
		borderRadius: 10,
	},
	contribution: {
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 10,
	},
	profilePic: {
		width: 50,
		height: 50,
		borderRadius: 25,
		marginRight: 10,
	},
	modalContent: {
		padding: 20,
		backgroundColor: "#fefde2",
		borderRadius: 10,
	},
	input: {
		borderWidth: 1,
		borderColor: "#ddd",
		padding: 10,
		fontSize: 18,
		borderRadius: 10,
		marginVertical: 10,
		backgroundColor: "#fff",
	},
	blurredContainer: {
		// Apply a blur effect
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backdropFilter: "blur(10px)", // Apply blur effect
	},
	modalContent: {
		padding: 20,
		backgroundColor: "#fefde2", // Consider using a color that matches your app's theme
		borderRadius: 10,
		shadowColor: "#000",
		shadowOffset: { width: 0, height: 2 },
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		margin: 20, // Ensures modal doesn't stretch to the edges of the screen
		alignItems: "center", // Centers the modal's content
	},
	input: {
		borderWidth: 1,
		borderColor: "#ddd",
		padding: 10,
		fontSize: 18,
		borderRadius: 10,
		marginVertical: 10,
		width: "100%", // Ensures consistent width for all inputs
		backgroundColor: "#fefde2", // Matches the container's background for cohesion
	},
	buttonGreen: {
		backgroundColor: "#4c956c",
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderRadius: 20,
		marginTop: 10,
		width: "80%", // Adjusts button width to match modal's width
		alignItems: "center", // Ensures text is centered within the button
	},
	buttonRed: {
		backgroundColor: "red",
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderRadius: 20,
		marginTop: 10,
		width: "80%", // Adjusts button width to match modal's width
		alignItems: "center", // Ensures text is centered within the button
	},
	inputLabel: {
		fontFamily: "Orpheus Pro",
		fontSize: 16,
		color: "#333", // Ensures the label is visible and clear
		marginVertical: 5, // Adds spacing around the label for clarity
	},
	text: {
		fontFamily: "Orpheus Pro",
		fontSize: 18,
		textAlign: "center",
		color: "#4c956c",
		textShadowOffset: { width: 3, height: 3 },
		textShadowRadius: 6,
		textShadowColor: "rgba(0, 0, 0, 0.09)",
		marginHorizontal: 25,
	},
});

export default GoalTracker;
